import { withFormik } from 'formik';

import ChcInvoicesDownloadInnerForm from "./ChcInvoicesDownloadInnerForm";

import { IChcInvoicesDownloadProps } from '../../../../../app/models/exports/IChcInvoicesDownloadProps';

interface IFormProps extends IChcInvoicesDownloadProps {
    download(props: IChcInvoicesDownloadProps): Promise<void>;
}

const ChcInvoicesDownloadFormSetup = withFormik<IFormProps, IChcInvoicesDownloadProps>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
      return {

        clientId: props?.clientId || null,
 
        invoicePeriodStartDate: props?.invoicePeriodStartDate || null,

      } as IChcInvoicesDownloadProps; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {

             props.download(updatedForm);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(ChcInvoicesDownloadInnerForm);


export default ChcInvoicesDownloadFormSetup;