interface IConfigNames {
    TOKEN_NAME: string;
}

const configNames: IConfigNames = {
    TOKEN_NAME: "jwt"
}

export default configNames;

