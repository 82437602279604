import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";

import ClientActualForm from "./ClientActualForm";
import BreadCrumbs from "../../../nav/BreadCrumbs";
import Loading from "../../../../app/layout/Loading";

import { IDetailsParams } from "../../../../app/models/shared/IDetailsParams";

import { useStore } from "../../../../app/stores/store";

const ClientForm: React.FC<RouteComponentProps<IDetailsParams>> = ({
    match,
    history
  }) => {

    const { clientStore } = useStore();
    
    useEffect(() => {
        if (!match.params.id) {
            clientStore.clearSelectedClient();
            return;
        }

        clientStore.loadClient(match.params.id);
    }, [match.params.id, clientStore]);

    const breadCrumbs = match.params.id
        ? [{ name: "Clients", path: "/clients"},
        { name: `${clientStore.client?.firstName} ${clientStore.client?.lastName}`, path: `/clients/${match.params.id}`}]
        : [{ name:"Clients", path: "/clients"}];

    return <>
        {clientStore.loading && <Loading />}
        <div className="centered-section">
            
            <BreadCrumbs breadCrumbs={breadCrumbs} />
            
            <ClientActualForm
                    key={match.params.id}
                    id={match.params.id}
                    title={clientStore.client?.title || ""}
                    firstName={clientStore.client?.firstName || ""}
                    lastName={clientStore.client?.lastName || ""}
                    clientGroup={clientStore.client?.clientGroup || ""}
                    numberOfWeeksPerInvoice={clientStore.client?.numberOfWeeksPerInvoice}
                    malinkoId={clientStore.client?.malinkoId || undefined}
                    swiftCode={clientStore.client?.swiftCode || ""}
                    temporaryAccountNumber={clientStore.client?.temporaryAccountNumber || undefined}
                    westHampshireChcAccountNumber={clientStore.client?.westHampshireChcAccountNumber || undefined}
                    surreyChcAccountNumber={clientStore.client?.surreyChcAccountNumber || undefined}
                    surreyCountyAccountNumber={clientStore.client?.surreyCountyAccountNumber || undefined}
                    frimleyIcbAccountNumber={clientStore.client?.frimleyIcbAccountNumber || undefined}
                    ealingAccountNumber={clientStore.client?.ealingAccountNumber || undefined}
                    portsmouthCityCouncilAccountNumber={clientStore.client?.portsmouthCityCouncilAccountNumber || undefined}
                    privateAccountNumber={clientStore.client?.privateAccountNumber || undefined}
                    hasBeenArchived={clientStore.client?.hasBeenArchived || false}
                    additionalInformation={clientStore.client?.additionalInformation || ""}
                    commissionedWeeks={clientStore.client?.commissionedWeeks}
                    hasAnAdditionalPrivatePackage={clientStore.client?.hasAnAdditionalPrivatePackage}
                    isSurreyCountyD2AClient={clientStore.client?.isSurreyCountyD2AClient}
                    shouldBeShownOnPepFile={clientStore.client?.shouldBeShownOnPepFile}
                    create={clientStore.createClient}
                    update={clientStore.updateClient}
                    />
                    
        </div>
    </>
}

export default observer(ClientForm);