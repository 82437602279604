import { makeAutoObservable, runInAction } from "mobx";

import { IInvoiceBasic } from "../models/invoices/IInvoiceBasic";
import { IInvoiceDeleteForm } from "../models/invoices/IInvoiceDeleteForm";
import { IInvoiceStatusForm } from "../models/invoices/IInvoiceStatusForm";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import notificationMessages from "../common/constants/enums/notificationMessages";
import { sendNotificationSuccess } from "../common/helpers/notificationsHelper";


interface IInvoiceFilters {
    clientName: string | null;
    clientGroup: string | null;
    invoiceDateFrom: Date | null;
    invoiceDateTo: Date | null;
}

export default class InvoiceStore {

    constructor() {
        makeAutoObservable(this);
    }

    defaultFilters = (): IInvoiceFilters => {
        return { 
            clientName: null, 
            clientGroup: null,
            invoiceDateFrom: null,
            invoiceDateTo: null,
        };
    }

    invoiceRegistry : Map<string, IInvoiceBasic> = new Map();
    loading = true;
    invoicesUpdated = false;
    filters: IInvoiceFilters = this.defaultFilters();

    get invoices () {
        return Array.from(this.invoiceRegistry.values())
        .filter(i => (!this.filters.clientName
                    || (i.clientName.toLowerCase().includes(this.filters.clientName.toLowerCase())))
                && (!this.filters.clientGroup || i.chargeAs === this.filters.clientGroup)
                && (!this.filters.invoiceDateFrom || this.filters.invoiceDateFrom <= new Date(i.invoiceDateIso))
                && (!(this.filters.invoiceDateTo) || new Date(i.invoiceDateIso) <=  this.filters.invoiceDateTo));
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setInvoicesUpdated = (invoicesUpdated: boolean) => {
        runInAction(() => {
            this.invoicesUpdated = invoicesUpdated;
        })
    }

    setClientNameFilter = (clientName: string | null) => {
        runInAction(() => {
            this.filters.clientName = clientName;
        });
    }

    setClientGroupFilter = (clientGroup: string | null) => {
        runInAction(() => {
            this.filters.clientGroup = clientGroup === "All" ? null : clientGroup;
        });
    }

    setInvoiceDateFromFilter = (invoiceDateFrom: Date | null) => {
        runInAction(() => {
            this.filters.invoiceDateFrom = invoiceDateFrom;
        });
    }

    setInvoiceDateToFilter = (invoiceDateTo: Date | null) => {

        runInAction(() => {
            if(!invoiceDateTo) {
                this.filters.invoiceDateTo = invoiceDateTo;
                return;
            }
            
            this.filters.invoiceDateTo = new Date(
                invoiceDateTo.getFullYear(), 
                invoiceDateTo.getMonth(), 
                invoiceDateTo.getDate(),
                23, 
                59, 
                59);
        });
    }

    clearInvoiceFilters = () => {
        runInAction(() => {
            this.filters = this.defaultFilters();
        });
    }

    loadInvoices = async (clientId: string | undefined) => {
        this.setLoading(true);
        this.invoiceRegistry = new Map();
        
        try {
            this.clearInvoiceFilters();

            const invoices = await ApiHelpers.Invoices.list(clientId);

            if(!invoices) return;

            runInAction(() => {
                invoices.forEach(m => this.invoiceRegistry.set(m.id, m));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateInvoice = (onlyOneClientIsNeeded: boolean) => async (invoice: IInvoiceStatusForm) => {
        this.setLoading(true);

        try {
            await ApiHelpers.Invoices.update(invoice);
            const selectedClients = onlyOneClientIsNeeded ? invoice.clientId : undefined;

            await this.loadInvoices(selectedClients);
            
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteInvoice = (onlyOneClientIsNeeded: boolean) => async (invoice: IInvoiceDeleteForm) => {
        this.setLoading(true);

        try {

            await ApiHelpers.Invoices.delete(invoice);
            const selectedClients = onlyOneClientIsNeeded ? invoice.clientId : undefined;

            await this.loadInvoices(selectedClients);
            
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }
}