import React from "react";
import { observer } from "mobx-react-lite";

import GenericModal from "./GenericModal";

interface IProps {
    showDeleteModal: boolean;
    handleCancelDelete: () => void;
    handleConfirmedDelete: () => void;
}

const DeleteModal: React.FC<IProps> = ({ showDeleteModal, handleCancelDelete, handleConfirmedDelete }) => {

    return <>
        <GenericModal
            title="Are you sure you want to delete?"
            mainMessage="This cannot be undone"
            confirmMessage="Yes, delete"
            cancelMessage="No, cancel"
            showModal={showDeleteModal}
            handleConfirmed={handleConfirmedDelete}
            handleCancel={handleCancelDelete}
        />
    </>
}

export default observer(DeleteModal);