import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import Badge from "react-bootstrap/esm/Badge";
import { FaEdit } from 'react-icons/fa';

import { IClientBasic } from "../../../app/models/clients/IClientBasic";

import friendlyFormat from "../../../app/common/helpers/friendlyFormat";
import clientFormat from "../../../app/common/helpers/colorFormat";

import ClientGroupLabel from "../../../app/layout/common-components/clients/ClientGroupLabel";

const ClientRow: React.FC<{ client: IClientBasic }> = ({ client }) => {
    return <tr>
        <td><Link to={`/clients/${client.id}`}>{client.firstName} {client.lastName}</Link></td>
        <td className={clientFormat.getClientGroupColor(client.clientGroup)}>{client.clientGroup}</td>
        <td>
            {client.accountNumbers?.map(n => <div className="small-text">
                <ClientGroupLabel clientGroup={n.clientGroup} suffix={`: ${n.accountNumber}`} />
            </div>)}
            
        </td>
        <td><Badge variant="pill" className="bg-secondary">{friendlyFormat.thisIfTrue(client.hasBeenArchived, "Archived")}</Badge></td>
        <td><Link to={`/clients/edit/${client.id}`}><FaEdit /></Link></td>
    </tr>  
}

export default observer(ClientRow);