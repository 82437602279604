import React from "react";
import { observer } from "mobx-react-lite";

import ClientGroupLabel from "../../../app/layout/common-components/clients/ClientGroupLabel";
import DetailsField from "../../../app/layout/form-fields/DetailsField";
import SectionHeading from "../../../app/layout/common-components/shared/SectionHeading";

import { IClient } from "../../../app/models/clients/IClient";

import friendlyFormat from "../../../app/common/helpers/friendlyFormat";

const MainDetails: React.FC<IClient> = (client) => {

    return <>
        <SectionHeading heading="Basic details" />

        <DetailsField
            label="Title" 
            value={client?.title} 
            hideIfEmpty={true} 
        />

        <DetailsField
            label="First name"
            value={client?.firstName}
        />

        <DetailsField
            label="Last name"
            value={client?.lastName}
        />

        <DetailsField
            label="Client group"
            value={client?.clientGroup}
        >
            <ClientGroupLabel clientGroup={client?.clientGroup || ""} />
        </DetailsField>

        <DetailsField
            label="Number of weeks per invoice"
            value={(client?.numberOfWeeksPerInvoice && `${client?.numberOfWeeksPerInvoice}`) || ""}
        />
            
        <hr />

        <DetailsField
            label="Hampshire swift code (account number)"
            value={client?.swiftCode}
            additionalField={{
                label: "Show on PEP file",
                value: friendlyFormat.yesIfTrue(client?.shouldBeShownOnPepFile)
            }}
        />

        <DetailsField
            label="West Hampshire CHC account number"
            value={client?.westHampshireChcAccountNumber}
        />

        <DetailsField
            label="Surrey CHC account number"
            value={client?.surreyChcAccountNumber}
            additionalField={{ 
                label: "D2A client",
                value: friendlyFormat.yesIfTrue(client?.isSurreyCountyD2AClient) 
            }}
        />

        <DetailsField
            label="Surrey County account number"
            value={client?.surreyCountyAccountNumber}
        />

        <DetailsField
            label="NHS Frimley ICB account number"
            value={client?.frimleyIcbAccountNumber}
        />

        <DetailsField
            label="Ealing Council"
            value={client?.ealingAccountNumber}
        />

        <DetailsField
            label="Portsmouth City Council"
            value={client?.portsmouthCityCouncilAccountNumber}
        />

        <DetailsField
            label="Private client account number"
            value={client?.privateAccountNumber}
        />

        <DetailsField
            label="Pays privately for some calls"
            value={friendlyFormat.yesIfTrue(client?.hasAnAdditionalPrivatePackage)}
        />

        <DetailsField
            label="Temporary account number"
            value={client?.temporaryAccountNumber}
            formattedValue={client?.temporaryAccountNumber && `TEMP_CODE_${client?.temporaryAccountNumber}`}
            hideIfEmpty={true}
        />

        <hr />
        <DetailsField
            label="Archived"
            value={friendlyFormat.yesIfTrue(client?.hasBeenArchived)}
        />

        <DetailsField
            label="Additional information"
            value={client?.additionalInformation}
        />
    </>
}

export default observer(MainDetails);