import { toast } from "react-toastify";

interface INotificationsHelper {
    sendNotificationError: (message: string) => void;
    sendNotificationSuccess: (message: string) => void;
}

export const sendNotificationError = (message: string) => toast.error(message, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
});

export const sendNotificationSuccess = (message: string) => toast.success(message, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
});

const notificationsHelper: INotificationsHelper = {
    sendNotificationError,
    sendNotificationSuccess,
}

export default notificationsHelper;