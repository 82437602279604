import { withFormik } from 'formik';
import { IInvoiceStatusForm } from '../../../../app/models/invoices/IInvoiceStatusForm';

import InvoiceStatusInnerForm from "./InvoiceStatusInnerForm";

interface IFormProps extends IInvoiceStatusForm {
    closeModal(): void;
    update(props: IInvoiceStatusForm): Promise<void>;
}

const InvoiceStatusFormSetup = withFormik<IFormProps, IInvoiceStatusForm>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
      return {

        id: props?.id || null,
        clientId: props?.clientId || null,
        hasBeenPaid: props?.hasBeenPaid ||false,
        hasBeenValidated: props?.hasBeenValidated || false,
        hasBeenDisputed: props?.hasBeenDisputed || false,
        disputeComment: props?.disputeComment || null,

      } as IInvoiceStatusForm; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {

             props.update(updatedForm).then(props.closeModal);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(InvoiceStatusInnerForm);


export default InvoiceStatusFormSetup;