import React from "react";
import { observer } from "mobx-react-lite";

import PepFileDownloadFormSetup from "./PepFileDownloadFormSetup";
import Loading from "../../../../../app/layout/Loading";
import BreadCrumbs from "../../../../nav/BreadCrumbs";

import { useStore } from "../../../../../app/stores/store";

const PepFileDownloadOuterForm = () => {

    const { exportStore } = useStore();

    const breadCrumbs = [{ name:"Hampshire spreadsheets", path: "/exports-hampshire"}];

    return <>
        {exportStore.loading && <Loading />}
        <div className="centered-section">
            
            <BreadCrumbs breadCrumbs={breadCrumbs} />

            <PepFileDownloadFormSetup
                    weekCommencingDate={undefined}
                    includeAdditionalColumns={false}
                    download={exportStore.downloadPepFile}
            />
        </div>
    </>
}

export default observer(PepFileDownloadOuterForm);