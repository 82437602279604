export const yesIfTrue = (condition?: boolean) => {
    return thisIfTrue(condition, "Yes", "No");
}

export const thisIfTrue = (condition?: boolean, trueText?: string, falseText?: string) => {
    return condition ? trueText || "true" : falseText || "";
}

const friendlyFormat = {
    yesIfTrue,
    thisIfTrue
}

export default friendlyFormat;