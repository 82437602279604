import { useState } from "react";
import { observer } from "mobx-react-lite";
import { Form as FForm, FormikProps } from "formik";
import Button from "react-bootstrap/esm/Button";

import { ICarer } from "../../../app/models/carers/ICarer";
import { IDetailsParams } from "../../../app/models/shared/IDetailsParams";

import history from "../../../app/common/history";
import { useStore } from "../../../app/stores/store";

import DeleteModal from "../../modals/DeleteModal";
import FormSubmissionError from "../../../app/layout/form-fields/errors/FormSubmissionError";
import TextInputField from "../../../app/layout/form-fields/TextInputField";


const CarerFormInner : React.FC<IDetailsParams & FormikProps<ICarer>> = (props) => {

    const { carerStore } = useStore();

    const { id, isSubmitting, errors, dirty, isValid } = props;

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setShowDeleteModal(true);
    }

    const handleCancelDelete= () => {
        setShowDeleteModal(false);
    }

    const handleConfirmedDelete = async () => {
        if (!id) return;

        await carerStore.deleteCarer(id)
        
        history.push("/carers");
    }
 
    return (
      <>
         <FForm className="form-small">
 
            <h1>{id ? "Edit carer" : "Add new carer"}</h1>

            <TextInputField name="firstName" label="First name" errors={errors.firstName} required />

            <TextInputField name="lastName" label="Last name" errors={errors.lastName} required />

            <TextInputField name="carerNumber" label="Carer number" errors={errors.carerNumber} required />
 
            <Button 
                disabled={!isValid || !(id || dirty) || isSubmitting} 
                variant="primary" 
                type="submit"
                className="me-3"
            >
                {isSubmitting ? "Saving..." : "Save"}
            </Button>

            <Button onClick={handleDelete} variant="outline-secondary">Delete</Button>

            {!isValid && <FormSubmissionError errorMessage="Review validation errors to save changes" />}
      </FForm>

      <DeleteModal
          showDeleteModal={showDeleteModal}
          handleCancelDelete={handleCancelDelete}
          handleConfirmedDelete={handleConfirmedDelete}
      />
    </>
    );
  };


export default observer(CarerFormInner);