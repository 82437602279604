import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import Table from "react-bootstrap/Table";

import ClientFilters from "./filters/ClientFilters";
import ClientRow from "./ClientRow";
import MainSectionHeading from "../../../app/layout/common-components/headings/MainSectionHeading";
import NoResultsFound from "../../../app/layout/NoResultsFound";
import Loading from "../../../app/layout/Loading";

import { useStore } from "../../../app/stores/store";

const ClientsDashboard = () => {
    const { clientStore } = useStore();

    useEffect(() => {
        clientStore.loadClients();
    }, [clientStore]);

    return <>
        {clientStore.loading && <Loading />}
        <div className="centered-section">

            <MainSectionHeading
                heading="Clients"
                actionText="New"
                actionLink="/clients/create"
            />

            <ClientFilters 
                setClientNameFilter={clientStore.setClientNameFilter}
                setClientAccountNumberFilter={clientStore.setClientAccountNumberFilter}
                setClientGroupFilter={clientStore.setClientGroupFilter}
                setClientArchivedFilter={clientStore.setClientArchivedFilter}
                clearAllFilters={clientStore.clearClientFilters} />

            {!(clientStore.clients.length)
                ? <NoResultsFound />
                : <div>
                    <Table striped hover>
                        <tbody>
                            {clientStore.clients.map(c => <ClientRow key={c.id} client={c} />)}
                        </tbody>
                    </Table>
                </div>}
            
        </div>  
    </>  
}

export default observer(ClientsDashboard);