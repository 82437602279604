import { makeAutoObservable, runInAction } from "mobx";

import { IPageDetails, IPagination } from "../models/responses/pagination";
import { IVisitBasic } from "../models/visits/IVisitBasic";
import { VisitSearchParams } from "../models/search-params/VisitSearchParams";

import ApiHelpers from "../api/ApiHelpers";
import { columnSorting } from "../common/constants/enums/columnSorting";
import errorHandler from "../common/errorHandler";
import { formatDateObject, formatDateObjectEndOfDay } from "../common/helpers/datesHelper";

export default class VisitStore {

    constructor() {
        makeAutoObservable(this);
    }

    defaultSearchParams = (clearSearchParamsOnly: boolean = false): VisitSearchParams => {
        
        if (clearSearchParamsOnly || !this.searchParams) return new VisitSearchParams();

        return this.searchParams;
    }

    visitRegistry : Map<string, IVisitBasic> = new Map();
    loading = false;
    searchParams: VisitSearchParams = this.defaultSearchParams();
    pagination: IPagination | null = null;
    currentPage: IPageDetails | null = null;


    get visits () {
        return Array.from(this.visitRegistry.values());
    }

    setCurrentPageSize = (pageSize: number) => {
        runInAction(() => {
            this.searchParams.pageSize = pageSize;
            this.searchParams.pageNumber = 1;
        });
    }

    setCurrentPage = (pageNumber: number) => {

        runInAction(() => {
            pageNumber = pageNumber < 1
                ? 1
                : this.currentPage && pageNumber > this.currentPage.totalPages 
                    ? this.currentPage.totalPages
                    : pageNumber;

            this.searchParams.pageNumber = pageNumber;
        })
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append("pageNumber", this.searchParams.pageNumber.toString());
        params.append("pageSize", this.searchParams.pageSize.toString());
        
        if (this.searchParams.clientName) {
            params.append("clientName", this.searchParams.clientName);
        }
        
        if (this.searchParams.dateFrom) {
            params.append("dateFrom", formatDateObject(this.searchParams.dateFrom));
        }
        
        if (this.searchParams.dateTo) {
            params.append("dateTo", formatDateObjectEndOfDay(this.searchParams.dateTo));
        }

        if (this.searchParams.sortByClientNameAscending) {
            params.append("sortByClientNameAscending", this.searchParams.sortByClientNameAscending.toString());
        }

        if (this.searchParams.sortByClientNameDescending) {
            params.append("sortByClientNameDescending", this.searchParams.sortByClientNameDescending.toString());
        }

        if (this.searchParams.sortByDateAscending) {
            params.append("sortByDateAscending", this.searchParams.sortByDateAscending.toString());
        }

        if (this.searchParams.sortByDateDescending) {
            params.append("sortByDateDescending", this.searchParams.sortByDateDescending.toString());
        }

        return params;
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setClientNameFilter = (clientName: string | null) => {
        runInAction(() => {
            this.searchParams.clientName = clientName;
            this.searchParams.pageNumber = 1;
        });
    }

    setVisitDateFromFilter = (dateFrom: Date | null) => {
        runInAction(() => {
            this.searchParams.dateFrom = dateFrom;
            this.searchParams.pageNumber = 1;
        });
    }

    setVisitDateToFilter = (dateTo: Date | null) => {
        runInAction(() => {
            this.searchParams.dateTo = dateTo;
            this.searchParams.pageNumber = 1;
        });
    }

    setClientNameColumnSorting = (sortingOption: number) => {
        
        this.clearColumnSorting();

        runInAction(() => {
            
            if (sortingOption === columnSorting.NONE) {
                this.searchParams.sortByClientNameAscending = false;
                this.searchParams.sortByClientNameDescending = false;
            }

            if (sortingOption === columnSorting.ASCENDING) {
                this.searchParams.sortByClientNameAscending = true;
                this.searchParams.sortByClientNameDescending = false;
            }

            if (sortingOption === columnSorting.DESCENDING) {
                this.searchParams.sortByClientNameAscending = false;
                this.searchParams.sortByClientNameDescending = true;
            }
        });
    }

    setStartDateColumnSorting = (sortingOption: number) => {

        this.clearColumnSorting();
        
        runInAction(() => {

            if (sortingOption === columnSorting.NONE) {
                this.searchParams.sortByDateAscending = false;
                this.searchParams.sortByDateDescending = false;
            }

            if (sortingOption === columnSorting.ASCENDING) {
                this.searchParams.sortByDateAscending = true;
                this.searchParams.sortByDateDescending = false;
            }

            if (sortingOption === columnSorting.DESCENDING) {
                this.searchParams.sortByDateAscending = false;
                this.searchParams.sortByDateDescending = true;
            }
        });
    }

    clearColumnSorting = () => {

        this.setCurrentPage(1);

        runInAction(() => {
            this.searchParams.sortByClientNameAscending = false;
            this.searchParams.sortByClientNameDescending = false;

            this.searchParams.sortByDateAscending = false;
            this.searchParams.sortByDateDescending = false;
        })
    }

    clearSearchParams = async (clientId: string | undefined, clearSearchParamsOnly: boolean = false) => {

        runInAction(() => {
            this.searchParams = this.defaultSearchParams(clearSearchParamsOnly);
        });

        if (!clearSearchParamsOnly) return;
        
        await this.loadVisits(clientId);
    }

    loadVisits = async (clientId: string | undefined) => {
        this.setLoading(true);
        this.visitRegistry = new Map();

        try {

            const result = await ApiHelpers.Visits.list(clientId, this.axiosParams);

            if(!(result && result.value)) return;

            runInAction(() => {
                result.value.forEach(m => this.visitRegistry.set(m.id, m));
                this.currentPage = result.pageDetails;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    uploadVisits = async (file: FormData) => {
        this.setLoading(true);
        
        try {
            await ApiHelpers.Visits.upload(file);
            await this.loadVisits(undefined);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }
}