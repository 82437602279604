import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";

import VisitUploadFormSetup from "./VisitUploadFormSetup";
import BreadCrumbs from "../../nav/BreadCrumbs";
import Loading from "../../../app/layout/Loading";

import { useStore } from "../../../app/stores/store";

const VisitUploadOuterForm: React.FC<RouteComponentProps> = ({
    match,
    history
  }) => {

    const { visitStore } = useStore();

    const breadCrumbs = [{ name:"Visits", path: "/visits"}];

    return <>
        {visitStore.loading && <Loading />}
        <div className="centered-section">
            
            <BreadCrumbs breadCrumbs={breadCrumbs} />

            <VisitUploadFormSetup
                    file=""
                    upload={visitStore.uploadVisits}
            />
                    
        </div>
    </>
}

export default observer(VisitUploadOuterForm);