import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { IInvoiceLineProps } from "../../../../../app/models/exports/IInvoiceLineProps";

import { getChcInitialInvoiceLines } from "./form-values/invoiceLinesInitialValues";
import clientGroups from "../../../../../app/common/constants/enums/clientGroups";
import { enumToOptionList } from "../../../../../app/common/constants/enums/helpers/enumHelper";
import { useStore } from "../../../../../app/stores/store";

import ChargeAsFormBasic from "./form-components/ChargeAsFormBasic";
import Loading from "../../../../../app/layout/Loading";
import ManualInvoicesDownloadFormSetup from "./ManualInvoicesDownloadFormSetup";


interface IProps {
    clientId: string | undefined;
}

const ManualInvoicesDownloadOuterForm = ({ clientId }: IProps) => {

    const { exportStore } = useStore();

    const [selectedChargeAs, setSelectedChargeAs] = useState<number | undefined>(undefined);
    const [periodStartDate, setPeriodStartDate] = useState<string | undefined>(undefined);
    const [initialInvoiceLines, setInitialInvoiceLines] = useState<IInvoiceLineProps[] | undefined>(undefined);

    const handleSelection = (
        chargeAs: number | undefined, 
        periodStartDate: string | undefined
    ) => {
        setSelectedChargeAs(chargeAs);
        setPeriodStartDate(periodStartDate);
    }

    useEffect(() => {

        if (Number(selectedChargeAs) === clientGroups.CHC.value && periodStartDate) 
        {
            setInitialInvoiceLines((getChcInitialInvoiceLines(periodStartDate)));
        }

    }, [ selectedChargeAs, periodStartDate]);

    return <>
        {exportStore.loading && <Loading />}
    
        {!selectedChargeAs && <ChargeAsFormBasic
            options={enumToOptionList(clientGroups)}
            handleSelection={handleSelection}
        />}

        {selectedChargeAs && 
            <ManualInvoicesDownloadFormSetup
                invoiceStore={undefined}
                clientId={clientId}
                chargeAs={selectedChargeAs}
                
                isACreditNote={undefined}
                additionalInformation={undefined}
                pONumber={undefined}

                invoiceLines={initialInvoiceLines}
                
                setSelectedChargeAs={setSelectedChargeAs}

                download={exportStore.downloadManualInvoices}
            />}
    </>
}

export default observer(ManualInvoicesDownloadOuterForm);