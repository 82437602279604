import VisitStore from "../../../app/stores/visitStore";


export const handlePageNumberEnter = (
    visitStore: VisitStore,
    clientId: string | undefined,
    pageNumber: string | undefined,
) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") handlePageNumberBlur(visitStore, clientId, pageNumber)();
}



export const handlePageNumberBlur = (
    visitStore: VisitStore,
    clientId: string | undefined,
    pageNumber: string | undefined,
) => () => {

    if (!pageNumber) return;

    visitStore.setCurrentPage(Number(pageNumber));
    visitStore.loadVisits(clientId);
}



export const handlePageNumberFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
}



export const handlePageNumberChange = (
    visitStore: VisitStore,
    setPageNumber: (pageNumber: string) => void,
) => (event: React.ChangeEvent<HTMLInputElement>) => {
    
    if (!visitStore.currentPage) return;

    const pageNumberValue = Number(event.target.value);

    if (isNaN(pageNumberValue)) {
        setPageNumber(visitStore.currentPage?.currentPageNumber.toString());
        return;
    };

    visitStore.setCurrentPage(pageNumberValue);
    setPageNumber(visitStore.searchParams.pageNumber.toString());
}

const gridFooterHandlers = {
    handlePageNumberEnter,
    handlePageNumberBlur,
    handlePageNumberFocus,
    handlePageNumberChange,
};

export default gridFooterHandlers;