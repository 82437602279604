import { withFormik } from "formik";
import * as Yup from "yup";

import errorMessages from "../../../../../../app/common/helpers/errorMessages";
import { IClientBillingAddress } from "../../../../../../app/models/clients/IClientBillingAddress";

import ClientBillingAddressInnerForm from "./ClientBillingAddressInnerForm";

interface IFormProps extends IClientBillingAddress {
    update(props: IClientBillingAddress): Promise<void>;
}

const ClientBillingAddressFormSetup = withFormik<IFormProps, IClientBillingAddress>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
      return {

        id: props?.id || null,
        contactTitle: props?.contactTitle || "",
        contactFirstName: props?.contactFirstName || "",
        contactLastName: props?.contactLastName || "",
        contactEmail: props?.contactEmail || "",
        contactPhone: props?.contactPhone || "",
        billingAddressLine1: props?.billingAddressLine1 || "",
        billingAddressLine2: props?.billingAddressLine2 || "",
        billingAddressTown: props?.billingAddressTown || "",
        billingAddressCounty: props?.billingAddressCounty || "",
        billingAddressPostCode: props?.billingAddressPostCode || "",

      } as IClientBillingAddress; 
    },

    validationSchema: Yup.object().shape({
        contactTitle: Yup.string().max(8, `Title ${errorMessages.validation.TOO_LONG}`),
        contactFirstName: Yup.string()
            .required(`First name ${errorMessages.validation.REQUIRED}`)
            .max(64, `First name ${errorMessages.validation.TOO_LONG}`),
        contactLastName: Yup.string()
            .required(`Last name ${errorMessages.validation.REQUIRED}`)
            .max(64, `Last name ${errorMessages.validation.TOO_LONG}`),
        contactEmail: Yup.string()
            .email(`Email ${errorMessages.validation.EMAIL}`)
            .max(128, `Email ${errorMessages.validation.TOO_LONG}`),
        contactPhone: Yup.string().max(18, `Phone number ${errorMessages.validation.TOO_LONG}`),
        billingAddressLine1: Yup.string()
            .required(`Address line 1 ${errorMessages.validation.REQUIRED}`)
            .max(256, `Address line 1 ${errorMessages.validation.TOO_LONG}`),
        billingAddressLine2: Yup.string()
          .max(128, `Address line 2 ${errorMessages.validation.TOO_LONG}`),
        billingAddressTown: Yup.string()
            .required(`Town ${errorMessages.validation.REQUIRED}`)
            .max(128, `Town ${errorMessages.validation.TOO_LONG}`),
        billingAddressCounty: Yup.string()
              .max(64, `County ${errorMessages.validation.TOO_LONG}`),
        billingAddressPostCode: Yup.string()
          .required(`Post code ${errorMessages.validation.REQUIRED}`)
          .max(16, `Post code ${errorMessages.validation.TOO_LONG}`),
    }),
    
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {

             props.update(updatedForm);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(ClientBillingAddressInnerForm);


export default ClientBillingAddressFormSetup;