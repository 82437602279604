import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import Button from "react-bootstrap/esm/Button";
import DatePicker from "react-datepicker";


interface IProps {
    setNameFilter: (carerName: string) => void;
    setDateFromFilter: (dateFrom: Date | null) => void;
    setDateToFilter: (dateTo: Date | null) => void;
    clearAllFilters: () => void;
}

const RemittanceFilters: React.FC<IProps> = ({ 
    setNameFilter, 
    setDateFromFilter,
    setDateToFilter,
    clearAllFilters,
}) => {

    const [carerNameValue, setCarerNameValue] = useState("");
    const [dateFrom, setDateFrom] = useState<Date | null>(null);
    const [dateTo, setDateTo] = useState<Date | null>(null);

    const handleCarerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCarerNameValue(event.target.value);
        setNameFilter(event.target.value);
    }

    const handleDateFrom = (date: Date) => {
        setDateFrom(date);
        setDateFromFilter(date);
    }

    const handleDateTo = (date: Date) => {
        setDateTo(date);
        setDateToFilter(date);
    }

    const handleClearAllFilters = () => {
        clearAllFilters();
        setCarerNameValue("");
        setDateTo(null);
        setDateFrom(null);
    }

    return (<div className="filters-container">
        <div className="d-flex flex-row mt-3">
            <input
                name="carerNameFilter" 
                placeholder="Filter name" 
                className="form-control me-3 w-25" 
                onChange={handleCarerNameChange}
                value={carerNameValue}
             />
                
            <div className="me-3 w-25">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="en-GB"
                    selected={dateFrom}
                    onChange={handleDateFrom}
                    placeholderText={"Remittance date from"}
                    isClearable
                    className="form-control"
                />
            </div>
            
            <div className="me-3 w-25">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="en-GB"
                    selected={dateTo}
                    onChange={handleDateTo}
                    placeholderText={"Remittance date to"}
                    isClearable
                    className="form-control"
                />
            </div>
        </div>
        <Button variant="link" onClick={handleClearAllFilters}>Clear filters</Button>
    </div>);
}

export default observer(RemittanceFilters);