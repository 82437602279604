import React from "react";

import { FormikProps, Form as FForm, Field } from 'formik';
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";

import { IAddress } from "../../../../app/models/addresses/IAddress";

import CheckboxField from "../../../../app/layout/form-fields/CheckboxField";
import DatePickerField from "../../../../app/layout/form-fields/DatePickerField";
import TextInputField from "../../../../app/layout/form-fields/TextInputField";

const AddressInnerForm : React.FC<FormikProps<IAddress>> = (props) => {

    const { isSubmitting } = props;
 
    return (
         <FForm className="form-small">

            <Form.Group controlId="clientGroup" className="mb-3"> 
                <Form.Label className="details-label">Client group</Form.Label> 
                <Field name="clientGroup" component="select" className="form-control" required>
                    <option value="">Select a group</option>
                    <option value="1">CHC</option>
                    <option value="9">CHC (Children)</option>
                    <option value="2">Hampshire</option>
                    <option value="8">Hampshire (Children)</option>
                    <option value="3">Private</option>
                    <option value="4">London Borough</option>
                    <option value="5">Surrey County</option>
                    <option value="6">Surrey CHC</option>
                    <option value="7">Frimley ICB</option>
                    <option value="10">Ealing Council</option>
                    <option value="11">Portsmouth City Council</option>
                </Field>
            </Form.Group> 

            <TextInputField name="line1" label="Line 1" required />

            <TextInputField name="town" label="Town" required />

            <TextInputField name="county" label="County" />

            <TextInputField name="postCode" label="Post code" required />

            <DatePickerField name="startDate" label="Start date" required />

            <CheckboxField name="isActive" label="Active" />

             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Saving..." : "Save"}
             </Button>
      </FForm> 
    );
  };


export default AddressInnerForm;