import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import Loading from "../../../../../../app/layout/Loading";

import ClientBillingAddressFormSetup from "./ClientBillingAddressFormSetup";

import { useStore } from "../../../../../../app/stores/store";

interface IProps {
    clientId: string;
}

const PrivateClientsInvoicesDownloadOuterForm: React.FC<IProps> = ({ clientId }) => {

    const { clientBillingAddressStore } = useStore();

    useEffect(() => {

        clientBillingAddressStore.loadClientBillingAddress(clientId);

    }, [clientId, clientBillingAddressStore])

    return <>
        {clientBillingAddressStore.loading && <Loading />}
        <div className="centered-section mt-5">

            <ClientBillingAddressFormSetup
                    key={clientBillingAddressStore.clientBillingAddress?.id}
                    id={clientBillingAddressStore.clientBillingAddress?.id}
                    contactTitle={clientBillingAddressStore.clientBillingAddress?.contactTitle}
                    contactFirstName={clientBillingAddressStore.clientBillingAddress?.contactFirstName}
                    contactLastName={clientBillingAddressStore.clientBillingAddress?.contactLastName}
                    contactEmail={clientBillingAddressStore.clientBillingAddress?.contactEmail}
                    contactPhone={clientBillingAddressStore.clientBillingAddress?.contactPhone}
                    billingAddressLine1={clientBillingAddressStore.clientBillingAddress?.billingAddressLine1}
                    billingAddressLine2={clientBillingAddressStore.clientBillingAddress?.billingAddressLine2}
                    billingAddressTown={clientBillingAddressStore.clientBillingAddress?.billingAddressTown}
                    billingAddressCounty={clientBillingAddressStore.clientBillingAddress?.billingAddressCounty}
                    billingAddressPostCode={clientBillingAddressStore.clientBillingAddress?.billingAddressPostCode}
                    update={clientBillingAddressStore.updateClientBillingAddress}
            />
        </div>
    </>
}

export default observer(PrivateClientsInvoicesDownloadOuterForm);