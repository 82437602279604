import { withFormik } from "formik";
import * as Yup from "yup";

import InnerForm from "./InnerForm";

import { IClient } from "../../../../app/models/clients/IClient";

import { formatters } from "../../../../app/common/helpers/errorMessages";
import history from '../../../../app/common/history';
import { sendNotificationSuccess } from "../../../../app/common/helpers/notificationsHelper";

interface IFormProps extends IClient {
    update(client: IClient, callback: () => void): Promise<void>;
    create(client: IClient, callback: () => void): Promise<void>;
}

const ClientActualForm = withFormik<IFormProps, IClient>({

    // Transform outer props into form values

    mapPropsToValues: props => {
      return {
 
         id: props?.id || null,

         title: props?.title || "",
 
         firstName: props?.firstName || "",
 
         lastName: props?.lastName || "",
 
         clientGroup: props?.clientGroup || "",
 
         numberOfWeeksPerInvoice: props?.numberOfWeeksPerInvoice || undefined,

         malinkoId: props?.malinkoId || null,
 
         swiftCode: props?.swiftCode || "",

         temporaryAccountNumber: props?.temporaryAccountNumber || undefined,
 
         westHampshireChcAccountNumber: props?.westHampshireChcAccountNumber || undefined,
 
         surreyChcAccountNumber: props?.surreyChcAccountNumber || undefined,
 
         surreyCountyAccountNumber: props?.surreyCountyAccountNumber || undefined,

         frimleyIcbAccountNumber: props?.frimleyIcbAccountNumber || undefined,

         ealingAccountNumber: props?.ealingAccountNumber || undefined,

         portsmouthCityCouncilAccountNumber: props?.portsmouthCityCouncilAccountNumber || undefined,

         privateAccountNumber: props?.privateAccountNumber || undefined,
 
         hasBeenArchived: props?.hasBeenArchived || false,
 
         additionalInformation: props?.additionalInformation || "",

         hasAnAdditionalPrivatePackage: props?.hasAnAdditionalPrivatePackage || false,

         isSurreyCountyD2AClient: props?.isSurreyCountyD2AClient || false,

         shouldBeShownOnPepFile: props?.shouldBeShownOnPepFile || false,

      } as IClient; 
    },

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
        title: Yup.string().max(8, formatters.tooLong("Title")),
        firstName: Yup.string()
            .required(formatters.required("First name"))
            .max(128, formatters.tooLong("First name")),
        lastName: Yup.string()
            .required(formatters.required("Last name"))
            .max(128, formatters.tooLong("First name")),
        clientGroup: Yup.string().required(formatters.required("Client group")),

        numberOfWeeksPerInvoice: Yup.number()
                .required(formatters.required("Number of weeks"))
                .min(1, "Must be between 1 and 4 weeks")
                .max(4, "Must be between 1 and 4 weeks"),

         swiftCode: Yup.string().max(16, formatters.tooLong("Account number")),

         temporaryAccountNumber: Yup.string().max(6, formatters.tooLong("Temporary account number")),
 
         westHampshireChcAccountNumber: Yup.string().max(16, formatters.tooLong("Account number")),
 
         surreyChcAccountNumber: Yup.string().max(16, formatters.tooLong("Account number")),
 
         surreyCountyAccountNumber: Yup.string().max(16, formatters.tooLong("Account number")),

         frimleyIcbAccountNumber: Yup.string().max(16, formatters.tooLong("Account number")),

         ealingAccountNumber: Yup.string().max(16, formatters.tooLong("Account number")),

         portsmouthCityCouncilAccountNumber: Yup.string().max(16, formatters.tooLong("Account number")),
 
         privateAccountNumber: Yup.string().max(32, formatters.tooLong("Account number")),
 
         additionalInformation: Yup.string().max(256, formatters.tooLong("Additional information")),
    }),
 
    handleSubmit: (updatedClient, { setSubmitting, props }) => {
        
         try {
             const apiCall = updatedClient.id
                 ? props.update
                 : props.create;

            const callback = () => {
                sendNotificationSuccess("Changes saved");
                history.push("/clients");
            }
 
             apiCall(updatedClient, callback);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(InnerForm);


export default ClientActualForm;