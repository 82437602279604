import React from "react";
import { observer } from "mobx-react-lite";

import { Field, FormikErrors } from 'formik';

import { ICarePackage } from "../../../../app/models/care-packages/ICarePackage";

import CheckboxField from "../../../../app/layout/form-fields/CheckboxField";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import DatePickerField from "../../../../app/layout/form-fields/DatePickerField";
import FieldValidationError from "../../../../app/layout/form-fields/errors/FieldValidationError";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import SectionHeadingFormGroup from "../../../../app/layout/common-components/shared/SectionHeadingFormGroup";
import TextInputField from "../../../../app/layout/form-fields/TextInputField";


interface IProps {
    errors: FormikErrors<ICarePackage>;
}

const PackageDetails: React.FC<IProps> = ({ errors }) => {

    return <>
        <h5>Details</h5>
        <Container fluid className="extra-wide">
            <Row>
                <Col className="border m-3 p-5 m-3 p-3">
                    
                    <SectionHeadingFormGroup heading="Basic details" />

                    <Form.Group controlId="chargeAs" className="mb-3"> 
                        <Form.Label className="details-label">Charge as</Form.Label> 
                        <Field name="chargeAs" component="select" className="form-control">
                            <option value="">Select a group</option>
                            <option value="1">CHC</option>
                            <option value="9">CHC (Children)</option>
                            <option value="2">Hampshire</option>
                            <option value="8">Hampshire (Children)</option>
                            <option value="3">Private</option>
                            <option value="4">London Borough</option>
                            <option value="5">Surrey County</option>
                            <option value="6">Surrey CHC</option>
                            <option value="7">Frimley ICB</option>
                            <option value="10">Ealing Council</option>
                            <option value="11">Portsmouth City Council</option>
                        </Field>
                        <FieldValidationError fieldName="chargeAs" errorMessage={errors.chargeAs} />
                    </Form.Group> 

                    <Form.Group controlId="overallDescription" className="mb-3"> 
                        <Form.Label className="details-label">Description</Form.Label> 
                        <div className="text-secondary"><small>e.g. 31.5 hours per week @ agreed weekly rate of £941.50</small></div>
                        <div className="text-secondary"><small>e.g. 31.5 hours and 3 waking nights per week @ agreed weekly rate of £1,481.50</small></div>
                        <div className="text-secondary"><small>e.g. live in care and 15.75 hours per week @ agreed weekly rate of £1,784.75</small></div>
                        <Field name="overallDescription" component="textarea" className="form-control" />
                        <FieldValidationError fieldName="overallDescription" errorMessage={errors.overallDescription} />
                    </Form.Group> 
                    <Form.Group controlId="expectedWeeklyCost" className="mb-3"> 
                        <Form.Label className="details-label">Expected weekly cost</Form.Label> 
                        <Field type="number" name="expectedWeeklyCost" className="form-control" />
                        <FieldValidationError fieldName="expectedWeeklyCost" errorMessage={errors.expectedWeeklyCost} />
                    </Form.Group> 
                    <Form.Group controlId="numberOfCommissionedHours" className="mb-3"> 
                        <Form.Label className="details-label">Commissioned hours</Form.Label> 
                        <Field type="number" name="numberOfCommissionedHours" className="form-control" />
                        <FieldValidationError fieldName="numberOfCommissionedHours" errorMessage={errors.numberOfCommissionedHours} />
                    </Form.Group> 
                </Col>
                <Col className="border m-3 p-5 m-3 p-3">
                    <SectionHeadingFormGroup heading="Active period" />

                    <CheckboxField name="isActive" label="Active" />

                    <DatePickerField name="startTime" label="Start date" placeholder="e.g. 21/03/2021" />
                    <FieldValidationError fieldName="startTime" errorMessage={errors.startTime} />

                    <Form.Group controlId="startVisit" className="mb-3"> 
                        <Form.Label className="details-label">First visit</Form.Label> 
                        <Field name="startVisit" component="select" className="form-control">
                            <option value="">Select a group</option>
                            <option value="4">Morning</option>
                            <option value="3">Lunchtime</option>
                            <option value="2">Tea</option>
                            <option value="1">Bed</option>
                        </Field>
                        <FieldValidationError fieldName="startVisit" errorMessage={errors.startVisit} />
                    </Form.Group> 
                    
                    <DatePickerField name="endTime" label="End date" placeholder="e.g. 21/03/2021" />
                    <FieldValidationError fieldName="endTime" errorMessage={errors.endTime} />

                    <Form.Group controlId="endVisit" className="mb-3"> 
                        <Form.Label className="details-label">Last visit</Form.Label> 
                        <Field name="endVisit" component="select" className="form-control">
                            <option value="">Select a group</option>
                            <option value="4">Morning</option>
                            <option value="3">Lunchtime</option>
                            <option value="2">Tea</option>
                            <option value="1">Bed</option>
                        </Field>
                    </Form.Group> 
                </Col>
            </Row>
            <Row>
                <Col className="border m-3 p-5 m-3 p-3">
                    <SectionHeadingFormGroup heading="Live in care" />
                    <CheckboxField name="includesLiveInCare" label="Live in care" />
                    <TextInputField type="number" name="liveInCarerWeeklyCost" label="Live in care weekly cost" />
                </Col>
                <Col className="border m-3 p-5 m-3 p-3">
                    <SectionHeadingFormGroup heading="Alternating week patterns" />

                    <CheckboxField name="followsAlternatingWeekPattern" label="Alternating week pattern" />

                    <TextInputField type="number" name="alternatingWeekNumber" label="Alternating week number" />
                </Col>
            </Row>
            <Row>
                <Col className="border m-3 p-5 m-3 p-3">
                    <SectionHeadingFormGroup heading="Bank holidays" />

                    <CheckboxField name="usesBankHolidayRates" label="Use bank holiday rates" />
                </Col>
                <Col className="m-3 p-5 m-3 p-3">
                </Col>
            </Row>
        </Container>
    </>
}

export default observer(PackageDetails);