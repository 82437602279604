import React from "react";

import { observer } from "mobx-react-lite";


interface IProps {
    text: string;
    classes: string;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    onClick?: () => void;
}

const CustomButton: React.FC<IProps> = ({ 
    text, 
    classes, 
    type, 
    disabled, 
    onClick 
}) => {
    return (
        <button 
            type={type || "button"} 
            className={`btn ${classes}`} 
            disabled={disabled || false} 
            onClick={onClick}
        >
            {text}
        </button>
    );
}

export default observer(CustomButton);