import React from "react";

import { FormikProps, Form as FForm } from 'formik';

import { IInvoiceStatusForm } from "../../../../app/models/invoices/IInvoiceStatusForm";

import Button from "react-bootstrap/esm/Button";

import CheckboxField from "../../../../app/layout/form-fields/CheckboxField";
import TextInputField from "../../../../app/layout/form-fields/TextInputField";


const InvoiceStatusDownloadInnerForm : React.FC<FormikProps<IInvoiceStatusForm>> = (props) => {

    const { isSubmitting } = props;
 
    return (
         <FForm className="form-small">

            <CheckboxField name="hasBeenPaid" label="Paid" />

            <CheckboxField name="hasBeenValidated" label="Validated" />

            <CheckboxField name="hasBeenDisputed" label="Disputed" />

            <TextInputField name="disputeComment" label="Dispute comment" />

             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Saving..." : "Save"}
             </Button>
      </FForm> 
    );
  };


export default InvoiceStatusDownloadInnerForm;