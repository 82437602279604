import React from "react";

import { FormikProps, Form as FForm } from 'formik';

import Button from "react-bootstrap/esm/Button";

import SectionHeading from "../../../../../../app/layout/common-components/shared/SectionHeading";
import TextInputField from "../../../../../../app/layout/form-fields/TextInputField";
import { IClientBillingAddress } from "../../../../../../app/models/clients/IClientBillingAddress";

const PrivateClientsInvoicesDownloadInnerForm : React.FC<FormikProps<IClientBillingAddress>> = (props) => {

    const { isSubmitting, errors } = props;
 
    return (
         <FForm className="form-small">
 
            <SectionHeading heading="Invoice contact billing address" />

            <TextInputField name="contactTitle" label="Title" errors={errors.contactTitle} />

            <TextInputField name="contactFirstName" label="First name" errors={errors.contactFirstName} />

            <TextInputField name="contactLastName" label="Last name" errors={errors.contactLastName} />

            <TextInputField name="contactEmail" label="Email" errors={errors.contactEmail} />

            <TextInputField name="contactPhone" label="Phone number" errors={errors.contactPhone} />

            <TextInputField name="billingAddressLine1" label="Address line 1" errors={errors.billingAddressLine1} />

            <TextInputField name="billingAddressLine2" label="Address line 2" errors={errors.billingAddressLine2} />

            <TextInputField name="billingAddressTown" label="Town" errors={errors.billingAddressTown} />

            <TextInputField name="billingAddressCounty" label="County" errors={errors.billingAddressCounty} />

            <TextInputField name="billingAddressPostCode" label="Post code" errors={errors.billingAddressPostCode} />


             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Saving..." : "Save"}
             </Button>
      </FForm> 
    );
  };


export default PrivateClientsInvoicesDownloadInnerForm;