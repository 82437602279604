import { withFormik } from 'formik';

import SurreyChcInvoicesDownloadInnerForm from "./SurreyChcInvoicesDownloadInnerForm";

import { ISurreyChcInvoicesDownloadProps } from '../../../../../app/models/exports/ISurreyChcInvoicesDownloadProps';

interface IFormProps extends ISurreyChcInvoicesDownloadProps {
    download(props: ISurreyChcInvoicesDownloadProps): Promise<void>;
}

const SurreyChcInvoicesDownloadFormSetup = withFormik<IFormProps, ISurreyChcInvoicesDownloadProps>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
      return {
        clientId: props?.clientId || null,

        weekCommencingDate: props?.weekCommencingDate || null,

      } as ISurreyChcInvoicesDownloadProps; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {

             props.download(updatedForm);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(SurreyChcInvoicesDownloadInnerForm);


export default SurreyChcInvoicesDownloadFormSetup;