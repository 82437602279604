import React from "react";
import { observer } from "mobx-react-lite";

import Accordion from "react-bootstrap/esm/Accordion";
import Badge from "react-bootstrap/esm/Badge";
import Card from "react-bootstrap/esm/Card";

import Loading from "../../../../../app/layout/Loading";

import { ICarePackage } from "../../../../../app/models/care-packages/ICarePackage";

import ClientGroupLabel from "../../../../../app/layout/common-components/clients/ClientGroupLabel";
import PackageFormSetup from "./PackageFormSetup";

import ClientStore from "../../../../../app/stores/clientStore";
import CarePackageStore from "../../../../../app/stores/carePackageStore";

interface IProps {
    carePackage: ICarePackage;
    clientStore: ClientStore;
    carePackageStore: CarePackageStore;
}

const PackageForm: React.FC<IProps> = ({ carePackage, clientStore, carePackageStore }) => {

    return <>
        {carePackageStore.loading && <Loading />}
        <Card>
                <Accordion.Toggle as={Card.Header} eventKey={carePackage?.id || ""}>
                    <div>
                        <h5><ClientGroupLabel clientGroup={carePackage.chargeAsText} suffix="Package" /></h5>
                    </div>
                    <div>
                        {carePackage?.isActive
                            ? <Badge pill className="bg-primary">Active</Badge>
                            : <Badge pill className="bg-secondary">Out of date</Badge>} <small> - started {carePackage?.startTimeText}</small>
                    </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={carePackage?.id || ""}>
                <Card.Body>
                    <div className="centered-section">

                        <PackageFormSetup
                                key={carePackage.id}
                                id={carePackage.id}
                                clientId={carePackage.clientId}

                                clientStore={clientStore}
                                carePackageStore={carePackageStore}

                                chargeAs={carePackage?.chargeAs || ""}
                                chargeAsText={carePackage?.chargeAsText || ""}
                                usesBankHolidayRates={carePackage?.usesBankHolidayRates || false}
                                overallDescription={carePackage?.overallDescription || ""}
                                expectedWeeklyCost={carePackage?.expectedWeeklyCost || 0}
                                numberOfCommissionedHours={carePackage?.numberOfCommissionedHours || 0}
                                isActive={carePackage?.isActive || false}
                                startTime={carePackage?.startTime || undefined}
                                startTimeText={carePackage?.startTimeText || undefined}
                                startVisit={carePackage?.startVisit || ""}
                                endTime={carePackage?.endTime || undefined}
                                endVisit={carePackage?.endVisit || ""}

                                configuredCommissionedHours={carePackage?.configuredCommissionedHours || 0}
                                configuredTotalCost={carePackage?.configuredTotalCost || 0}
                                
                                includesLiveInCare={carePackage?.includesLiveInCare || false}
                                liveInCarerWeeklyCost={carePackage?.liveInCarerWeeklyCost || 0}
                                followsAlternatingWeekPattern={carePackage?.followsAlternatingWeekPattern || false}
                                alternatingWeekNumber={carePackage?.alternatingWeekNumber || 0}
                                morningDuration={carePackage?.morningDuration || ""}
                                lunchtimeDuration={carePackage?.lunchtimeDuration || ""}
                                teaDuration={carePackage?.teaDuration || ""}
                                bedDuration={carePackage?.bedDuration || ""}
                                weekday30minutes={carePackage?.weekday30minutes || 0}
                                weekday45minutes={carePackage?.weekday45minutes || 0}
                                weekday1hour={carePackage?.weekday1hour || 0}
                                weekday90minutes={carePackage?.weekday90minutes || 0}
                                weekend30minutes={carePackage?.weekend30minutes || 0}
                                weekend45minutes={carePackage?.weekend45minutes || 0}
                                weekend1hour={carePackage?.weekend1hour || 0}
                                weekend90minutes={carePackage?.weekend90minutes || 0}
                                bankHoliday30minutes={carePackage?.bankHoliday30minutes || 0}
                                bankHoliday45minutes={carePackage?.bankHoliday45minutes || 0}
                                bankHoliday1hour={carePackage?.bankHoliday1hour || 0}
                                bankHoliday90minutes={carePackage?.bankHoliday90minutes || 0}
                                sitInWeekday={carePackage?.sitInWeekday || 0}
                                nightWeekday={carePackage?.nightWeekday || 0}
                                sitInWeekend={carePackage?.sitInWeekend || 0}
                                nightWeekend={carePackage?.nightWeekend || 0}
                                sitInBankHoliday={carePackage?.sitInBankHoliday || 0}
                                nightBankHoliday={carePackage?.nightBankHoliday || 0}
                                
                                mondayMorningCarers={carePackage?.mondayMorningCarers || 0}
                                mondayLunchCarers={carePackage?.mondayLunchCarers || 0}
                                mondayTeaCarers={carePackage?.mondayTeaCarers || 0}
                                mondayBedCarers={carePackage?.mondayBedCarers || 0} 
                                tuesdayMorningCarers={carePackage?.tuesdayMorningCarers || 0}
                                tuesdayLunchCarers={carePackage?.tuesdayLunchCarers || 0}
                                tuesdayTeaCarers={carePackage?.tuesdayTeaCarers || 0}
                                tuesdayBedCarers={carePackage?.tuesdayBedCarers || 0}
                                wednesdayMorningCarers={carePackage?.wednesdayMorningCarers || 0}
                                wednesdayLunchCarers={carePackage?.wednesdayLunchCarers || 0}
                                wednesdayTeaCarers={carePackage?.wednesdayTeaCarers || 0}
                                wednesdayBedCarers={carePackage?.wednesdayBedCarers || 0}
                                thursdayMorningCarers={carePackage?.thursdayMorningCarers || 0}
                                thursdayLunchCarers={carePackage?.thursdayLunchCarers || 0}
                                thursdayTeaCarers={carePackage?.thursdayTeaCarers || 0}
                                thursdayBedCarers={carePackage?.thursdayBedCarers || 0}
                                fridayMorningCarers={carePackage?.fridayMorningCarers || 0}
                                fridayLunchCarers={carePackage?.fridayLunchCarers || 0}
                                fridayTeaCarers={carePackage?.fridayTeaCarers || 0}
                                fridayBedCarers={carePackage?.fridayBedCarers || 0}
                                saturdayMorningCarers={carePackage?.saturdayMorningCarers || 0}
                                saturdayLunchCarers={carePackage?.saturdayLunchCarers || 0}
                                saturdayTeaCarers={carePackage?.saturdayTeaCarers || 0}
                                saturdayBedCarers={carePackage?.saturdayBedCarers || 0}
                                sundayMorningCarers={carePackage?.sundayMorningCarers || 0}
                                sundayLunchCarers={carePackage?.sundayLunchCarers || 0}
                                sundayTeaCarers={carePackage?.sundayTeaCarers || 0}
                                sundayBedCarers={carePackage?.sundayBedCarers || 0}

                                mondayNightDuration={carePackage?.mondayNightDuration || 0}
                                mondaySitInDuration={carePackage?.mondaySitInDuration || 0}
                                tuesdayNightDuration={carePackage?.tuesdayNightDuration || 0}
                                tuesdaySitInDuration={carePackage?.tuesdaySitInDuration || 0}
                                wednesdayNightDuration={carePackage?.wednesdayNightDuration || 0}
                                wednesdaySitInDuration={carePackage?.wednesdaySitInDuration || 0}
                                thursdayNightDuration={carePackage?.thursdayNightDuration || 0}
                                thursdaySitInDuration={carePackage?.thursdaySitInDuration || 0}
                                fridayNightDuration={carePackage?.fridayNightDuration || 0}
                                fridaySitInDuration={carePackage?.fridaySitInDuration || 0}
                                saturdayNightDuration={carePackage?.saturdayNightDuration || 0}
                                saturdaySitInDuration={carePackage?.saturdaySitInDuration || 0}
                                sundayNightDuration={carePackage?.sundayNightDuration || 0}
                                sundaySitInDuration={carePackage?.sundaySitInDuration || 0}

                                update={carePackageStore.updateCarePackage}
                                />
                                
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    </>
}

export default observer(PackageForm);