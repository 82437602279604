import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { FaEdit } from "react-icons/fa";

import { IAddress } from "../../../../app/models/addresses/IAddress";

import friendlyFormat from "../../../../app/common/helpers/friendlyFormat";

const AddressRow : React.FC<{ address: IAddress }> = ({ address }) => {
    return <tr>
        <td><Link to={`/admin/addresses/${address.id}`}>{address.clientGroupText}</Link></td>
        <td><Link to={`/admin/addresses/${address.id}`}>{address.line1}</Link></td>
        <td><Link to={`/admin/addresses/${address.id}`}>{address.postCode}</Link></td>
        <td>{friendlyFormat.thisIfTrue(address.isActive, "Active", "-")}</td>
        <td><Link to={`/admin/addresses/edit/${address.id}`}><FaEdit /></Link></td>
    </tr>;
}

export default observer(AddressRow);