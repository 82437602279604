import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";


interface IProps {
    heading: string;
    actionText: string;
    actionLink: string;
}

const MainSectionHeading = ({ heading, actionText, actionLink }: IProps) => {
    return (<div className="d-flex flex-row">
        <h1>{heading}</h1>
        <div className="ms-5">
            <Button variant="outline-primary" as={Link} to={actionLink}>{actionText}</Button>
        </div>
    </div>);
}

export default observer(MainSectionHeading);