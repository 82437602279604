import React from "react";

import { FormikProps, Form as FForm, Field } from 'formik';

import Button from "react-bootstrap/esm/Button";
import TextInputField from "../../../../../app/layout/form-fields/TextInputField";

import DatePickerField from "../../../../../app/layout/form-fields/DatePickerField";

import { ISurreyCountyInvoicesDownloadProps } from "../../../../../app/models/exports/ISurreyCountyInvoicesDownloadProps";

const SurreyCountyInvoicesDownloadInnerForm : React.FC<FormikProps<ISurreyCountyInvoicesDownloadProps>> = (props) => {

    const { isSubmitting } = props;
 
    return (
         <FForm className="form-small">

            <DatePickerField name="invoicePeriodStartDate" label="Invoice period start date" placeholder="dd/mm/yyyy" />

            <DatePickerField name="invoicePeriodEndDate" label="Invoice period end date" placeholder="dd/mm/yyyy" />

            <TextInputField name="invoiceTotal" label="Invoice total (£)" placeholder="e.g. 1234.56" />

            <Field name="clientId" type="hidden" className="form-control" />

             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Downloading..." : "Download"}
             </Button>
      </FForm> 
    );
  };


export default SurreyCountyInvoicesDownloadInnerForm;