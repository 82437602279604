import { DateTime } from "luxon";

import { IInvoiceLineProps } from "../../../../../../app/models/exports/IInvoiceLineProps";

export const getChcInitialInvoiceLines = (periodStartDateText: string): IInvoiceLineProps[] => {

    const periodStartDate = new Date(periodStartDateText);

    const weekNumbers = [1, 2, 3, 4];
    const daysInAWeek = 7;
    const firstWeekNumber = 1;

    return weekNumbers.map(weekNumber => {
        
        const startDate = weekNumber === firstWeekNumber ? DateTime.fromJSDate(periodStartDate).toJSDate()
            : DateTime.fromJSDate(periodStartDate).startOf("week").plus({ days: (weekNumber - 1) * daysInAWeek }).toJSDate();

        const endDate = DateTime.fromJSDate(startDate).endOf("week").toJSDate();
        
        return {
            position: undefined,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            description: undefined,
            unitPrice: undefined,
            amount: undefined,
        } as IInvoiceLineProps;
    });
};

const invoiceLinesInitialValues = {
    getChcInitialInvoiceLines,
};

export default invoiceLinesInitialValues;