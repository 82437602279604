import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { FaEdit } from 'react-icons/fa';

import { ICarer } from "../../../app/models/carers/ICarer";


const CarerRow: React.FC<{ carer: ICarer }> = ({ carer }) => {
    return <tr>
        <td><Link to={`/carers/${carer.id}`}>{carer.firstName} {carer.lastName}</Link></td>
        <td>{carer.carerNumber}</td>
        <td><Link to={`/carers/edit/${carer.id}`}><FaEdit /></Link></td>
    </tr>  
}

export default observer(CarerRow);