import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { FormikProps, Form as FForm, Field, FieldArray } from 'formik';

import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import Table from "react-bootstrap/esm/Table";

import { IRemittanceDownloadProps } from "../../../app/models/exports/IRemittanceDownloadProps";

import { useStore } from "../../../app/stores/store";

import DatePickerField from "../../../app/layout/form-fields/DatePickerField";
import TextInputField from "../../../app/layout/form-fields/TextInputField";


const RemittanceAdviceFormInner : React.FC<FormikProps<IRemittanceDownloadProps>> = (props) => {

    const { isSubmitting, values } = props;

    const { carerStore } = useStore();
 
    useEffect(() => {
      
        carerStore.loadCarers();

    }, [carerStore]);

    return (
         <FForm className="form-small">
            <Form.Group controlId="carerId" className="mb-3"> 
                <Form.Label className="details-label">Carer</Form.Label> 
                <Field name="carerId" component="select" className="form-control" required={true}>
                    <option value="">Select a carer</option>
                    {carerStore.carers?.map(c => <option key={c.id} value={c.id}>{c.firstName} {c.lastName}</option>)}
                </Field>
            </Form.Group>

            <div className="extra-wide border m-3 p-5">
            <Table bordered hover>
                <thead>
                  <tr>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Amount paid</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>

                  <FieldArray

                    name="remittanceLines"

                    render={arrayHelpers => (

                      <>

                        {values.remittanceLines && values.remittanceLines.length > 0 ? (

                          values.remittanceLines.map((remittanceLine, index) => (

                              <tr key={index}>
                              <td><DatePickerField name={`remittanceLines.${index}.startDate`}  label="" placeholder="dd/mm/yyyy" /></td>
                              <td><DatePickerField name={`remittanceLines.${index}.endDate`}  label="" placeholder="dd/mm/yyyy" /></td>
                              <td><TextInputField name={`remittanceLines.${index}.amountPaid`}  label="" placeholder="e.g. 1234.56" /></td>
                              
                              <td className="d-flex flex-row">
                                  <Button

                                    className="me-3"
                                
                                    variant="outline-secondary"

                                    onClick={() => arrayHelpers.remove(index)}
                                  >

                                    -

                                  </Button>

                                  <Button

                                    variant="outline-secondary"

                                    onClick={() => arrayHelpers.insert(index + 1, '')} 

                                  >

                                    +

                                  </Button>
                              </td>
                              </tr>

                          ))

                        ) : (
                          <tr>
                            <td colSpan={8}>
                                
                              <Button variant="outline-secondary" onClick={() => arrayHelpers.push('')}>
                                Add a remittance line
                              </Button>
                            </td>
                          </tr>

                        )}

                      </>

                    )}

                  />

                </tbody>
            </Table>
            </div>

            <Field name="clientId" type="hidden" className="form-control" />

             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Downloading..." : "Download"}
             </Button>
      </FForm> 
    );
  };


export default observer(RemittanceAdviceFormInner);