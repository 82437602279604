import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import Button from "react-bootstrap/esm/Button";


interface IProps {
    setCarerNameFilter: (carerName: string) => void;
    clearAllFilters: () => void;
}

const CarerFilters: React.FC<IProps> = ({ 
    setCarerNameFilter, 
    clearAllFilters,
}) => {

    const [carerNameValue, setCarerNameValue] = useState("");

    const handleCarerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCarerNameValue(event.target.value);
        setCarerNameFilter(event.target.value);
    }

    const handleClearAllFilters = () => {
        clearAllFilters();
        setCarerNameValue("");
    }

    return (<div className="filters-container">
        <div className="d-flex flex-row mt-3">
            <input 
                name="carerNameFilter" 
                placeholder="Filter by carer name" 
                className="form-control me-3 w-25" 
                onChange={handleCarerNameChange} 
                value={carerNameValue} 
            />
        </div>
        <Button variant="link" onClick={handleClearAllFilters}>Clear filters</Button>
    </div>);
}

export default observer(CarerFilters);