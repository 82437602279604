import React from "react";
import { observer } from "mobx-react-lite";


interface IProps {
    heading: string;
}

const SectionHeading: React.FC<IProps> = ({ heading }) => {
    return (<>
        <h3 className="mt-5 mb-4">{heading}</h3>
    </>);
}

export default observer(SectionHeading);