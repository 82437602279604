import { PagingParams } from "../responses/pagination";

export class VisitSearchParams extends PagingParams {
    clientName: string | null;
    dateFrom: Date | null;
    dateTo: Date | null;

    sortByClientNameAscending: boolean = false;
    sortByClientNameDescending: boolean = false;

    sortByDateAscending: boolean = false;
    sortByDateDescending: boolean = false;

    sortByClientGroupAscending: boolean = false;
    sortByClientGroupDescending: boolean = false;

    constructor(
        pageNumber: number | null = null,
        pageSize: number | null = null,
        clientName: string | null = null, 
        dateFrom: Date | null = null, 
        dateTo: Date | null = null,
    ) {
        super();
        this.clientName = clientName;
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        
        this.pageSize = pageSize || this.pageSize;
        this.pageNumber = pageNumber || this.pageNumber;
    }
}