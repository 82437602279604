import React, { ReactElement } from "react";
import { observer } from "mobx-react-lite";


interface IProps {
    label: string;
    value?: string | number;
    formattedValue?: string;
    additionalField?: IProps;
    children?: ReactElement; 
    hideIfEmpty?: boolean;
}

const DetailsField: React.FC<IProps> = ({
    label,
    value,
    formattedValue,
    additionalField,
    children,
    hideIfEmpty,
}) => {
    return (<>
        {!(hideIfEmpty && !value) && <>
            <div className={`mt-4 ${!value && "text-secondary"}`}>
                <div className={`details-label`}>{label}</div>
                <div>{children || formattedValue || value || "-"}</div>    
            </div>
            {value && additionalField && <div className="mt-4">
                <div className="details-label">{additionalField.label}</div>
                <div>{children || additionalField.formattedValue || additionalField.value}</div>
            </div>}
        </>}
    </>);
}

export default observer(DetailsField);