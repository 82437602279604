import { makeAutoObservable, runInAction } from "mobx";

import { IClientBasic } from "../models/clients/IClientBasic";
import { IInvoiceLatestBasic } from "../models/invoices/IInvoiceLatestBasic";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";

export default class AuditStore {

    constructor() {
        makeAutoObservable(this);
    }

    clientRegistry : Map<string, IClientBasic> = new Map();
    latestInvoiceRegistry : Map<string, IInvoiceLatestBasic> = new Map();

    loading = false;

    get clients () {
        return Array.from(this.clientRegistry.values());
    }

    get latestInvoices () {
        return Array.from(this.latestInvoiceRegistry.values());
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    uploadClients = async (file: FormData) => {
        this.setLoading(true);
        
        try {
            const clients = await ApiHelpers.AuditsClients.upload(file);

            if(!clients) return;

            runInAction(() => {
                clients.forEach(c => this.clientRegistry.set(`${c.firstName}${c.lastName}${c.accountNumber}`, c));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadLatestInvoices = async () => {
        this.setLoading(true);
        
        try {
            const latestInvoices = await ApiHelpers.InvoicesLatest.list();

            if(!latestInvoices) return;
            
            runInAction(() => {
                latestInvoices.forEach(i => this.latestInvoiceRegistry.set(i.clientId, i));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }
}