interface IWeekNumbers {
    NONE: number;
    ONE: number;
    TWO: number;
    THREE: number;
    FOUR: number;
}

const weekNumbers: IWeekNumbers = {
    NONE: -1, 
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
}

export default weekNumbers;

