import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, RouteComponentProps } from "react-router-dom";

import Button from "react-bootstrap/esm/Button";

import { IDetailsParams } from "../../../../app/models/shared/IDetailsParams";

import { yesIfTrue } from "../../../../app/common/helpers/friendlyFormat";
import { useStore } from "../../../../app/stores/store";

import BreadCrumbs from "../../../nav/BreadCrumbs";
import DeleteModal from "../../../modals/DeleteModal";

const AddressDetails : React.FC<RouteComponentProps<IDetailsParams>> = ({
    match,
    history,
}) => {

    const { adminAddressStore } = useStore();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setShowDeleteModal(true);
    }

    const handleCancelDelete= () => {
        setShowDeleteModal(false);
    }

    const handleConfirmedDelete = async () => {
        await adminAddressStore.deleteAddress(match.params.id!)
        
        history.push("/admin/addresses");
    }

    useEffect(() => {
        
        adminAddressStore.loadAddress(match.params.id!);

    }, [adminAddressStore, match.params.id])

    return (
    <div className="centered-section">
        <BreadCrumbs 
            breadCrumbs={[{ name:"Admin", path: "/admin"}, { name: "Billing addresses", path: "/admin/addresses" }]}
        />
        
        <h1>{adminAddressStore.address?.clientGroupText} billing address</h1>

        <Button variant="outline-primary" as={Link} to={`/admin/addresses/edit/${match.params.id}`}>Edit</Button>
        <Button onClick={handleDelete} variant="outline-secondary">Delete</Button>

        <div className="mt-4">
            <div className="details-label">Client group</div>
            <div>{adminAddressStore.address?.clientGroupText}</div>    
        </div>

        <div className="mt-4">
            <div className="details-label">Start date</div>
            <div>{adminAddressStore.address?.startDateText}</div>    
        </div>

        <div className="mt-4">
            <div className="details-label">Active</div>
            <div>{yesIfTrue(adminAddressStore.address?.isActive)}</div>    
        </div>

        <hr />

        <div className="mt-4">
            <div className="details-label">Address</div>
    
            <div>{adminAddressStore.address?.line1}</div>    

            {adminAddressStore.address?.line2 && <div>{adminAddressStore.address?.line2}</div>}

            <div>{adminAddressStore.address?.town}</div>    

            {adminAddressStore.address?.county && <div>{adminAddressStore.address?.county}</div>}

            <br />

            <div>{adminAddressStore.address?.postCode}</div>    
        </div>

        <DeleteModal
              showDeleteModal={showDeleteModal}
              handleCancelDelete={handleCancelDelete}
              handleConfirmedDelete={handleConfirmedDelete}></DeleteModal>
    </div>);
}

export default observer(AddressDetails);