import { makeAutoObservable, runInAction } from "mobx";
import { AxiosError } from "axios";

import { ICarer } from "../models/carers/ICarer";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import { sendNotificationSuccess } from "../common/helpers/notificationsHelper";
import notificationMessages from "../common/constants/enums/notificationMessages";

interface ICarerFilters {
    carerName: string | null;
}

export default class CarerStore {

    constructor() {
        makeAutoObservable(this);
    }

    defaultFilters = () : ICarerFilters => {
        return { 
            carerName: null,
        };
    }

    carerRegistry : Map<string, ICarer> = new Map();
    carer : ICarer | null = null;
    filters: ICarerFilters = this.defaultFilters();
    loading = true;
    get carers () {
        return Array.from(this.carerRegistry.values())
            .filter(c => (!this.filters.carerName
                            || (c.firstName?.toLowerCase().includes(this.filters.carerName.toLowerCase()) 
                                || c.lastName?.toLowerCase().includes(this.filters.carerName.toLowerCase()))));
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setCarerNameFilter = (carerName: string | null) => {
        runInAction(() => {
            this.filters.carerName = carerName;
        });
    }

    clearCarerFilters = () => {
        runInAction(() => {
            this.filters = this.defaultFilters();
        });
    }

    loadCarers = async () => {
        this.setLoading(true);

        try {
            this.clearCarers();
            
            const carers = await ApiHelpers.Carers.list();
            
            this.clearSelectedCarer();

            runInAction(() => {
                carers.forEach(c => this.carerRegistry.set(c.id!, c));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadCarer = async (id: string) => {
        this.setLoading(true);

        try {
            const carer = await ApiHelpers.Carers.details(id);

            runInAction(() => {
                this.carer = carer;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    createCarer = async (
        carer: ICarer,
        callback: () => void
    ) => {
        this.setLoading(true);

        try {
            await ApiHelpers.Carers.create(carer);
            await this.loadCarers();
            callback();
        } catch (error) {
            errorHandler.handleError(error as AxiosError);
        } finally {
            this.setLoading(false);
        }
    }

    updateCarer = async (
        carer: ICarer,
        callback: () => void
    ) => {
        this.setLoading(true);

        try {
            await ApiHelpers.Carers.update(carer);
            await this.loadCarers();
            callback();
        } catch (error) {
            errorHandler.handleError(error as AxiosError);
        } finally {
            this.setLoading(false);
        }
    }

    deleteCarer = async (id: string) => {
        this.setLoading(true);

        try {
            await ApiHelpers.Carers.delete(id);
            await this.loadCarers();
            this.carerRegistry.delete(id);
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    clearSelectedCarer = () => {
        this.carer = null;
    }

    clearCarers = () => {
        this.carerRegistry.clear();
        this.clearCarerFilters();
    }
}