import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import Badge from "react-bootstrap/esm/Badge";
import Table from "react-bootstrap/esm/Table";
import { FaDownload, FaTrashAlt } from "react-icons/fa";

import { IRemittanceBasic } from "../../../app/models/remittances/IRemittanceBasic";

import { useStore } from "../../../app/stores/store";

import DeleteRemittanceFormSetup from "../forms/delete-remittance/DeleteRemittanceFormSetup";
import DetailsField from "../../../app/layout/form-fields/DetailsField";
import Modal from "react-bootstrap/esm/Modal";
import NoResultsFound from "../../../app/layout/NoResultsFound";
import RemittanceFilters from "./RemittanceFilters";


const RemittanceAdviceList = () => {

    const { remittanceStore, exportStore } = useStore();

    const [selectedRemittance, setSelectedRemittance] = useState<IRemittanceBasic | undefined>(undefined)

    const [showDeleteRemittanceModal, setShowDeleteRemittanceModal] = useState(false);
    
    const handleCancelDeleteRemittanceModal= () => {
        setShowDeleteRemittanceModal(false);
    }

    useEffect(() => {    
        
        remittanceStore.loadRemittances();

    }, [remittanceStore]);

    return (<>

        
       <RemittanceFilters 
            setNameFilter={remittanceStore.setCarerNameFilter}
            setDateFromFilter={remittanceStore.setRemittanceDateFromFilter}
            setDateToFilter={remittanceStore.setRemittanceDateToFilter} 
            clearAllFilters={remittanceStore.clearRemittanceFilters}
        />
                
        {!(remittanceStore.remittances && remittanceStore.remittances.length) ? <NoResultsFound />
        : <div className="m-3">
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Remittance number</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Period</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {remittanceStore.remittances.map(r => (<tr key={r.id}>
                        <td>
                            <Badge pill className="bg-warning" >REMITTANCE</Badge>
                            <span> {r.remittanceNumber}</span>
                            <span
                                className="text-primary btn" 
                                onClick={() => exportStore.downloadGeneratedRemittances(r.id, r.fileName)}
                            >
                                <FaDownload />
                            </span>
                        </td>
                        <td>{r.date}</td>
                        <td>{r.carer}</td>
                        <td>{r.periodStart} to {r.periodEnd}</td>
                        <td>{r.totalPaid}</td>
                        <td>
                            <span 
                                className="text-secondary btn" 
                                onClick={() => { setSelectedRemittance(r); setShowDeleteRemittanceModal(true) }}
                            >
                                <FaTrashAlt />
                            </span>
                        </td>
                    </tr>))}
                </tbody>
            </Table>

            <Modal show={showDeleteRemittanceModal} onHide={handleCancelDeleteRemittanceModal}>
                <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to delete?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mt-3 mb-5">
                        <p className="mb-5">Deleting this remittance cannot be undone</p>
                        
                        <DetailsField label="Carer" value={selectedRemittance?.carer} />
                        
                        <DetailsField label="Remittance ID" value={selectedRemittance?.remittanceNumber} />
                    </div>

                    {selectedRemittance && <DeleteRemittanceFormSetup
                        id={selectedRemittance.id}
                        reason={undefined}
                        closeModal={() => setShowDeleteRemittanceModal(false)}
                        delete={remittanceStore.deleteRemittance} />}
                </Modal.Body>
            </Modal>
        </div>} 
    </>);
}

export default observer(RemittanceAdviceList);