import { makeAutoObservable, runInAction } from "mobx";

import { IBasicDetails } from "../models/shared/IBasicDetails";
import { ICarePackage } from "../models/care-packages/ICarePackage";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import notificationMessages from "../common/constants/enums/notificationMessages";
import { sendNotificationSuccess } from "../common/helpers/notificationsHelper";

import ClientStore from "./clientStore";

export default class CarePackageStore {

    constructor() {
        makeAutoObservable(this);
    }

    carePackageRegistry : Map<string, IBasicDetails> = new Map();
    carePackage : ICarePackage | null = null;
    loading = true;
    
    get carePackages () {
        return Array.from(this.carePackageRegistry.values());
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    loadCarePackages = async (clientId: string) => {
        this.setLoading(true);

        try {
            const carePackages = await ApiHelpers.CarePackages.list(clientId);
            
            this.clearSelectedCarePackage();

            if(!carePackages) return;

            runInAction(() => {
                carePackages.forEach(m => this.carePackageRegistry.set(m.id, m));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadCarePackage = async (id: string) => {
        this.setLoading(true);

        try {
            const carePackage = await ApiHelpers.CarePackages.details(id);

            runInAction(() => {
                carePackage.startTime = new Date(carePackage.startTime);
                carePackage.endTime = new Date(carePackage.endTime);
                
                this.carePackage = carePackage;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    createCarePackage = async (clientId: string, clientStore: ClientStore) => {
        this.setLoading(true);

        try {
            if (!clientId) throw Error("Client ID is missing");

            await ApiHelpers.CarePackages.create(clientId);
            await this.loadCarePackages(clientId);
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            clientStore.loadClient(clientId);
            this.setLoading(false);
        }
    }

    updateCarePackage = async (carePackage: ICarePackage, clientStore: ClientStore) => {
        this.setLoading(true);

        try {
            if (!carePackage.clientId) throw Error("Client ID is missing");

            carePackage.startTime = carePackage.startTime ? this.formatDate(carePackage.startTime) : carePackage.startTime;
            carePackage.endTime = carePackage.endTime ? this.formatDate(carePackage.endTime) : carePackage.endTime;

            await ApiHelpers.CarePackages.update(carePackage);
            await this.loadCarePackages(carePackage.clientId);

            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            await clientStore.loadClient(carePackage.clientId!);
            this.setLoading(false);
        }
    }

    deleteCarePackage = async (id: string, clientId: string, clientStore: ClientStore) => {
        this.setLoading(true);

        try {
            await ApiHelpers.CarePackages.delete(id);

            this.carePackageRegistry.delete(id);
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            clientStore.loadClient(clientId);
            this.setLoading(false);
        }
    }

    clearSelectedCarePackage = () => {
        this.carePackage = null;
    }

    formatDate = (dateAsString: string) => {
        const date = new Date(dateAsString);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

        return formattedDate;
    }
}