import { withFormik } from 'formik';

import ClientAuditUploadInnerForm from "./ClientAuditUploadInnerForm";

import IFileUpload from '../../../app/models/shared/IFileUpload';

interface IFormProps extends IFileUpload {
    upload(file: any): Promise<void>;   
 }

const ClientAuditUploadFormSetup = withFormik<IFormProps, IFileUpload>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
      return {
 
         file: props?.file || null,

      } as IFileUpload; 
    },
 
    handleSubmit: (updatedFileUpload, { setSubmitting, props }) => {
        
         try {
            let data = new FormData();
            data.append("file", updatedFileUpload.file);

            props.upload(data);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(ClientAuditUploadInnerForm);


export default ClientAuditUploadFormSetup;