import React from "react";
import { observer } from "mobx-react-lite";

import { FormikProps, Form as FForm, Field } from 'formik';
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";

import { IClient } from "../../../../app/models/clients/IClient";
import { IDetailsParams } from "../../../../app/models/shared/IDetailsParams";

import CheckboxField from "../../../../app/layout/form-fields/CheckboxField";
import FieldValidationError from "../../../../app/layout/form-fields/errors/FieldValidationError";
import FormSubmissionError from "../../../../app/layout/form-fields/errors/FormSubmissionError";
import TextInputField from "../../../../app/layout/form-fields/TextInputField";

const InnerForm : React.FC<IDetailsParams & FormikProps<IClient>> = (props) => {

    const { id, isSubmitting, errors, dirty, isValid } = props;
 
    return (
         <FForm className="form-small">
 
            <h1>{id ? "Edit client" : "Add new client"}</h1>

            <TextInputField name="title" label="Title" errors={errors.title} />

            <TextInputField name="firstName" label="First name" errors={errors.firstName} />

            <TextInputField name="lastName" label="Last name" errors={errors.lastName} />

            <Form.Group controlId="clientGroup" className="mb-3"> 
                <Form.Label className="details-label">Client group</Form.Label> 
                <Field name="clientGroup" component="select" className="form-control">
                    <option value="" disabled>Select a group</option>
                    <option value="CHC">CHC</option>
                    <option value="CHCChildren">CHC (Children)</option>
                    <option value="Hampshire">Hampshire</option>
                    <option value="HampshireChildren">Hampshire (Children)</option>
                    <option value="Private">Private</option>
                    <option value="LondonBorough">London Borough</option>
                    <option value="SurreyCounty">Surrey County</option>
                    <option value="SurreyCHC">Surrey CHC</option>
                    <option value="FrimleyICB">Frimley ICB</option>
                    <option value="Ealing">Ealing Council</option>
                    <option value="PortsmouthCityCouncil">Portsmouth City Council</option>
                </Field>
                <FieldValidationError fieldName="clientGroup" errorMessage={errors.clientGroup} />
            </Form.Group> 

            <TextInputField 
                name="numberOfWeeksPerInvoice" 
                label="Number of weeks per invoice" 
                type="number" 
                errors={errors.numberOfWeeksPerInvoice} 
            />

            <hr />


            <TextInputField name="swiftCode" label="Hampshire Swift code (account number)" noSpace={true} errors={errors.swiftCode} />

            <CheckboxField name="shouldBeShownOnPepFile" label="Show on PEP file" />

            <TextInputField name="westHampshireChcAccountNumber" label="West Hampshire CHC account number" errors={errors.westHampshireChcAccountNumber} />

            <TextInputField name="surreyChcAccountNumber" label="Surrey CHC account number" noSpace={true} errors={errors.surreyChcAccountNumber} />

            <CheckboxField name="isSurreyCountyD2AClient" label="D2A client" />

            <TextInputField name="surreyCountyAccountNumber" label="Surrey County account number" errors={errors.surreyCountyAccountNumber} />

            <TextInputField name="frimleyIcbAccountNumber" label="Frimley ICB account number" errors={errors.frimleyIcbAccountNumber} />

            <TextInputField name="ealingAccountNumber" label="Ealing Council account number" errors={errors.ealingAccountNumber} />

            <TextInputField name="portsmouthCityCouncilAccountNumber" label="Portsmouth City Council account number" errors={errors.portsmouthCityCouncilAccountNumber} />

            <TextInputField name="privateAccountNumber" label="Private client account number" noSpace={true} errors={errors.privateAccountNumber} />
            
            <CheckboxField name="hasAnAdditionalPrivatePackage" label="Pays privately for some calls" />
            
            <TextInputField name="temporaryAccountNumber" label="Temporary account number" errors={errors.temporaryAccountNumber} />

            <hr /> 
            
            <CheckboxField name="hasBeenArchived" label="Archived" />
            
            <TextInputField name="additionalInformation" label="Additional information" errors={errors.additionalInformation} />
 
            <Button disabled={!isValid || !(id || dirty) || isSubmitting} variant="primary" type="submit">
                {isSubmitting ? "Saving..." : "Save"}
            </Button>

            {!isValid && <FormSubmissionError errorMessage="Review validation errors to save changes" />}
      </FForm>
 
    );
  };


export default observer(InnerForm);