interface IVisitStatuses {
    NONE: string;
    STARTED: string;
    COMPLETED: string;
    MISSED: string;
    UNASSIGNED: string;
    CANCELLED: string;
    UPCOMING: string;
}

const visitStatuses: IVisitStatuses = {
    NONE: "None", 
    STARTED: "Started", 
    COMPLETED: "Completed", 
    MISSED: "Missed", 
    UNASSIGNED: "Unassigned", 
    CANCELLED: "Cancelled", 
    UPCOMING: "Upcoming", 
}

export default visitStatuses;

