import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import { ErrorMessage, Formik, Form as FForm, Field } from "formik";
import * as Yup from "yup";

import Alert from "react-bootstrap/esm/Alert";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";

import { useStore } from "../../app/stores/store";

import FieldValidationError from "../../app/layout/form-fields/errors/FieldValidationError";
import FormSubmissionError from "../../app/layout/form-fields/errors/FormSubmissionError";

import logo from "../../assets/logo.png";

const LoginForm = () => {

    const [didLoad, setDidLoad] = useState(false);

    const { userStore } = useStore();

    const location = useLocation();

    let validateAllBtnRef:any = null;
  
    useEffect(() => {

        setTimeout(() => {

            if(validateAllBtnRef)
            {
                validateAllBtnRef.click();
            }
        }, 250);

        if(!didLoad) {
            userStore.clearErrorMessage();
            setDidLoad(true);
        }
    }, [
        userStore, 
        location,
        validateAllBtnRef,
        didLoad,
        setDidLoad,
    ]);

    return (
        <Formik
            initialValues={{ email: "", password: "", error: null }}
            onSubmit={values => userStore.login(values)}
            validationSchema={Yup.object({
                email: Yup.string().required("Email is a required field").email("Email must be a valid email"),
                password: Yup.string().required("Password is a required field")
            })}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty, validateForm }) => (
                <FForm onSubmit={handleSubmit} autoComplete="off">
                    <img src={logo} alt="Vine Social Care Agency" className="mb-5" />
                    <h1 className="mt-5">Log in</h1>
                    <p>Use your account for Vine Social Care Invoices</p>
                    <Form.Group controlId="Email" className="mb-3"> 
                        <Form.Label className="details-label">Email</Form.Label> 
                        <Field type="email" name="email" className="form-control" />
                        <FieldValidationError fieldName="email" errorMessage={errors.email} /> 
                    </Form.Group> 
                    <Form.Group controlId="Password" className="mb-3"> 
                        <Form.Label className="details-label">Password</Form.Label> 
                        <Field type="password" name="password" className="form-control" />
                        <FieldValidationError fieldName="password" errorMessage={errors.password} />
                    </Form.Group> 

                    <ErrorMessage
                        name="error"
                        render={() => <Alert variant="danger">{errors.error}</Alert>}
                    />

                    <button style={{display:"none"}}
                        type="button"
                        ref={input => {
                            // assigns a reference so we can trigger it later
                            validateAllBtnRef = input;
                        }}
                        onClick={() => validateForm().then(() => false)}
                    >
                        Validate All
                    </button>
                    
                    <Button disabled={!isValid || !dirty || isSubmitting} variant="primary" type="submit">
                        {isSubmitting ? "Logging in..." : "Log in"}
                    </Button>
                    
                    <Button disabled={isSubmitting} variant="link" as={Link} to="/create-account" >
                        Create account
                    </Button>

                    {userStore.errorMessage && <FormSubmissionError errorMessage={userStore.errorMessage} />}
                </FForm>
            )}
        </Formik>
    )
}

export default observer(LoginForm);