import React from "react";
import { observer } from "mobx-react-lite";

import Loading from "../../../../../app/layout/Loading";
import BreadCrumbs from "../../../../nav/BreadCrumbs";

import { useStore } from "../../../../../app/stores/store";
import ChcInvoicesDownloadFormSetup from "./ChcInvoicesDownloadFormSetup";

const ChcInvoicesDownloadOuterForm = () => {

    const { exportStore } = useStore();

    const breadCrumbs = [{ name:"CHC spreadsheets", path: "/exports-chc"}];

    return <>
        {exportStore.loading && <Loading />}
        <div className="centered-section">
            
            <BreadCrumbs breadCrumbs={breadCrumbs} />
 
            <h1>Download CHC invoices</h1>

            <ChcInvoicesDownloadFormSetup
                    invoiceStore={undefined}    
                    clientId={undefined}
                    invoicePeriodStartDate={undefined}
                    download={exportStore.downloadChcInvoices}
            />
        </div>
    </>
}

export default observer(ChcInvoicesDownloadOuterForm);