interface IErrorMessages {
    validation: IFormValidationMessages,
    formatters: IFormValidationMessageFormatters,
}

interface IFormValidationMessages {
    REQUIRED: string,
    EMAIL: string,
    PHONE: string,
    TOO_SHORT: string,
    TOO_LONG: string,
    NON_NEGATIVE: string,
}

interface IFormValidationMessageFormatters {
    required: (fieldLabel: string) => string;
    email: (fieldLabel: string) => string;
    phone: (fieldLabel: string) => string;
    tooShort: (fieldLabel: string) => string;
    tooLong: (fieldLabel: string) => string;
    nonNegative: (fieldLabel: string) => string;
}

export const validation: IFormValidationMessages = {
    REQUIRED: "is a required field",
    EMAIL: "must be a valid email",
    PHONE: "must be a valid phone number",
    TOO_SHORT: "is too short",
    TOO_LONG: "is too long",
    NON_NEGATIVE: "cannot be negative",
} 

export const formatters: IFormValidationMessageFormatters = {
    required: (fieldLabel: string) => `${fieldLabel} ${validation.REQUIRED}`,
    email: (fieldLabel: string) =>  `${fieldLabel} ${validation.EMAIL}`,
    phone: (fieldLabel: string) =>  `${fieldLabel} ${validation.PHONE}`,
    tooShort: (fieldLabel: string) =>  `${fieldLabel} ${validation.TOO_SHORT}`,
    tooLong: (fieldLabel: string) =>  `${fieldLabel} ${validation.TOO_LONG}`,
    nonNegative: (fieldLabel: string) => `${fieldLabel} ${validation.NON_NEGATIVE}`,
};

const errorMessages: IErrorMessages = {
    validation,
    formatters,
}

export default errorMessages;