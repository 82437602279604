import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Table from "react-bootstrap/esm/Table";

import { useStore } from "../../../app/stores/store";

import CarerFilters from "./filters/CarerFilters";
import CarerRow from "./CarerRow";
import Loading from "../../../app/layout/Loading";
import MainSectionHeading from "../../../app/layout/common-components/headings/MainSectionHeading";
import NoResultsFound from "../../../app/layout/NoResultsFound";


const CarerDashboard = () => {
    const { carerStore } = useStore();

    useEffect(() => {
        carerStore.loadCarers();
    }, [carerStore]);

    return <>
        {carerStore.loading && <Loading />}
        <div className="centered-section">

            <MainSectionHeading
                heading="Carers"
                actionText="New"
                actionLink="/carers/create"
            />

            <CarerFilters 
                setCarerNameFilter={carerStore.setCarerNameFilter}
                clearAllFilters={carerStore.clearCarerFilters}
            />

            {!(carerStore.carers.length)
                ? <NoResultsFound />
                : <div>
                    <Table striped hover>
                        <tbody>
                            {carerStore.carers.map(c => <CarerRow key={c.id} carer={c} />)}
                        </tbody>
                    </Table>
                </div>}
            
        </div>  
    </>  
}

export default observer(CarerDashboard);