import React from "react";

import { FormikProps, Form as FForm } from 'formik';
import Button from "react-bootstrap/esm/Button";

import FileUploadField from "../../../app/layout/form-fields/FileUploadField";

import IFileUpload from "../../../app/models/shared/IFileUpload";

const ClientAuditUploadInnerForm : React.FC<FormikProps<IFileUpload>> = (props) => {

    const { isSubmitting } = props;
 
    return (
         <FForm className="form-small mt-3">
 
             <h1>Find new/missing clients</h1>

              <FileUploadField name="file" label="Nursebuddy clients spreadsheet" accept=".xls,.xlsx" />
              <div><small className="text-danger"><strong>WARNING</strong> Convert file to Excel spreadsheet to upload</small></div>
             <Button disabled={isSubmitting} variant="outline-primary" type="submit">
                 {isSubmitting ? "Uploading..." : "Upload clients"}
             </Button>
      </FForm>
 
    );
  };


export default ClientAuditUploadInnerForm;