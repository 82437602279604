import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import GridFooter from "../../app/layout/common-components/grid/GridFooter";
import Loading from "../../app/layout/Loading";
import NoResultsFound from "../../app/layout/NoResultsFound";
import VisitFilters from "./filters/VisitFilters";
import VisitListTable from "./VisitListTable";

import { useStore } from "../../app/stores/store";
import { handlePageSizeChange } from "./handlers/pageSizeHandlers";
import { handleLoadNextPage, handleLoadPreviousPage } from "./handlers/pageNumberButtonHandlers";
import { handlePageNumberBlur, handlePageNumberChange, handlePageNumberEnter, handlePageNumberFocus } from "./handlers/pageNumberHandlers";
import { setClientNameColumnSorting, setStartDateColumnSorting } from "./handlers/sortingHandlers";


interface IProps {
    clientId: string | undefined;
}

const VisitList : React.FC<IProps> = ({ clientId }) => {

    const { visitStore } = useStore();

    const [sortDescendingClientName, setSortDescendingClientName] = useState(false);
    const [sortingAppliedClientName, setSortingAppliedClientName] = useState(false);

    const [sortDescendingStartDate, setSortDescendingStartDate] = useState(false);
    const [sortingAppliedStartDate, setSortingAppliedStartDate] = useState(false);

    const [pageNumber, setPageNumber] = useState<string | undefined>(undefined);
    const [pageSize, setPageSize] = useState(25);

    useEffect(() => {    
    
        if (!pageNumber) { 
            visitStore.clearSearchParams(clientId, true)
                .then(() =>
                    setPageNumber(visitStore.currentPage?.currentPageNumber.toString()));
        } else {
            setPageNumber(visitStore.searchParams.pageNumber.toString());
        }

        return () => {
            visitStore.clearSearchParams(clientId, false);
        }
    }, [
            visitStore, 
            clientId,
            pageNumber,
            visitStore.searchParams.pageNumber,
        ]);

    return <> 
        {visitStore.loading && <Loading />}

        <VisitFilters
            setClientNameFilter={visitStore.setClientNameFilter}
            setDateFromFilter={visitStore.setVisitDateFromFilter}    
            setDateToFilter={visitStore.setVisitDateToFilter}
            applyFilters={() => visitStore.loadVisits(clientId)}
            clearAllFilters={() => visitStore.clearSearchParams(clientId, true)}
        />

        {!(visitStore.visits && visitStore.visits.length) ? <NoResultsFound />
        : <div className="m-3">
                
                <VisitListTable
                    visits={visitStore.visits}
                    sortingAppliedClientName={sortingAppliedClientName}
                    sortDescendingClientName={sortDescendingClientName}
                    setClientNameColumnSorting={sortingOptions => setClientNameColumnSorting(
                        visitStore,
                        clientId,
                        sortingOptions,
                        setSortingAppliedClientName,
                        setSortingAppliedStartDate,
                        setSortDescendingClientName,
                    )}
                    sortingAppliedStartDate={sortingAppliedStartDate}
                    sortDescendingStartDate={sortDescendingStartDate}
                    setStartDateColumnSorting={sortingOptions => setStartDateColumnSorting(
                        visitStore,
                        clientId,
                        sortingOptions,
                        setSortingAppliedClientName,
                        setSortingAppliedStartDate,
                        setSortDescendingStartDate,
                    )}
                />

                <GridFooter
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    currentPageSize={visitStore.currentPage?.currentPageSize}
                    totalPages={visitStore.currentPage?.totalPages}
                    totalItems={visitStore.currentPage?.totalItems}
                    handlePageSizeChange={handlePageSizeChange(visitStore, clientId, setPageSize, setPageNumber)}
                    handleLoadPreviousPage={handleLoadPreviousPage(visitStore, clientId, setPageNumber)}
                    handlePageNumberChange={handlePageNumberChange(visitStore, setPageNumber)}
                    handlePageNumberFocus={handlePageNumberFocus}
                    handlePageNumberBlur={handlePageNumberBlur(visitStore, clientId, pageNumber)}
                    handlePageNumberEnter={handlePageNumberEnter(visitStore, clientId, pageNumber)}
                    handleLoadNextPage={handleLoadNextPage(visitStore, clientId, setPageNumber)}
                />
            </div>}
    </>
}

export default observer(VisitList);