import { makeAutoObservable, runInAction } from "mobx";

import { IAddress } from "../models/addresses/IAddress";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import { formatDate } from "../common/helpers/datesHelper";
import notificationMessages from "../common/constants/enums/notificationMessages";
import { sendNotificationSuccess } from "../common/helpers/notificationsHelper";

export default class AdminAddressStore {

    constructor() {
        makeAutoObservable(this);
    }

    addressRegistry : Map<string, IAddress> = new Map();
    address : IAddress | null = null;
    activeAddressesOnly = true;
    loading = true;

    get addresses () {
        return Array.from(this.addressRegistry.values()).filter(a => !this.activeAddressesOnly || a.isActive);
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    loadAddresses = async () => {
        this.setLoading(true);

        try {
            this.clearAddresses();
            
            const addresses = await ApiHelpers.AdminAddresses.list();
            
            this.clearSelectedAddress();

            runInAction(() => {
                addresses.forEach(a => this.addressRegistry.set(a.id, a));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadAddress = async (id: string) => {
        this.setLoading(true);

        try {
            const address = await ApiHelpers.AdminAddresses.details(id);

            runInAction(() => {
                this.address = address;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    createAddress = async (address: IAddress) => {
        this.setLoading(true);

        try {

            address.startDate = formatDate(address.startDate);

            await ApiHelpers.AdminAddresses.create(address);
            await this.loadAddresses();
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateAddress = async (address: IAddress) => {
        this.setLoading(true);

        try {
            address.startDate = formatDate(address.startDate);
            
            await ApiHelpers.AdminAddresses.update(address);
            await this.loadAddresses();
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteAddress = async (id: string) => {
        this.setLoading(true);

        try {
            await ApiHelpers.AdminAddresses.delete(id);
            await this.loadAddresses();
            this.addressRegistry.delete(id);
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    clearSelectedAddress = () => {
        this.address = null;
    }

    clearAddresses = () => {
        this.addressRegistry.clear();
    }

    setActiveAddressesOnly = (activeAddressesOnly: boolean) => {
        runInAction(() => {
            this.activeAddressesOnly = activeAddressesOnly;
        })
    }
}