import { observer } from "mobx-react-lite";


interface IProps {
    errorMessage: string;
}

const FormSubmissionError: React.FC<IProps> = ({ errorMessage }) => {
    return (<>
        <div className="mt-3 validation-error-label">{errorMessage || "Invalid"}</div>
    </>);
}

export default observer(FormSubmissionError);