import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/esm/Button";

interface IProps {
    setClientNameFilter: (clientName: string) => void;
    setClientGroupFilter: (clientGroup: string) => void;
    setInvoiceDateFromFilter: (invoiceDateFrom: Date | null) => void;
    setInvoiceDateToFilter: (invoiceDateTo: Date | null) => void;
    clearAllFilters: () => void;
}

const InvoiceFilters: React.FC<IProps> = ({ 
    setClientNameFilter, 
    setClientGroupFilter,
    setInvoiceDateFromFilter,
    setInvoiceDateToFilter,
    clearAllFilters,
 }) => {

    const [clientNameValue, setClientNameValue] = useState("");
    const [clientGroupValue, setClientGroupValue] = useState("");
    const [invoiceDateFrom, setInvoiceDateFrom] = useState<Date | null>(null);
    const [invoiceDateTo, setInvoiceDateTo] = useState<Date | null>(null);

    const handleClientNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientNameValue(event.target.value);
        setClientNameFilter(event.target.value);
    }

    const handleClientGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setClientGroupValue(event.target.value);
        setClientGroupFilter(event.target.value);
    }

    const handleInvoiceDateFrom = (date: Date) => {
        setInvoiceDateFrom(date);
        setInvoiceDateFromFilter(date);
    }

    const handleInvoiceDateTo = (date: Date) => {
        setInvoiceDateTo(date);
        setInvoiceDateToFilter(date);
    }

    const handleClearAllFilters = () => {
        clearAllFilters();
        setClientNameValue("");
        setClientGroupValue("");
        setInvoiceDateTo(null);
        setInvoiceDateFrom(null);
    }

    return (<div className="filters-container">
        <div className="d-flex flex-row mt-3">
            <input
                name="clientNameFilter" 
                placeholder="Filter clients" 
                className="form-control me-3 w-25" 
                onChange={handleClientNameChange}
                value={clientNameValue}
             />
            
            <select
                name="clientGroupFilter"
                className="form-control me-3 w-25"
                onChange={handleClientGroupChange}
                value={clientGroupValue}
            >
                <option value="">Filter by invoice group</option>
                <option value="All">Show all groups</option>
                <option value="CHC">CHC</option>
                <option value="CHCChildren">CHC (Children)</option>
                <option value="Hampshire">Hampshire</option>
                <option value="HampshireChildren">Hampshire (Children)</option>
                <option value="Private">Private</option>
                <option value="LondonBorough">London Borough</option>
                <option value="SurreyCounty">Surrey County</option>
                <option value="SurreyCHC">Surrey CHC</option>
                <option value="FrimleyICB">Frimley ICB</option>
                <option value="Ealing">Ealing Council</option>
                <option value="PortsmouthCityCouncil">Portsmouth City Council</option>
            </select>
                
            <div className="me-3 w-25">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="en-GB"
                    selected={invoiceDateFrom}
                    onChange={handleInvoiceDateFrom}
                    placeholderText={"Invoice date from"}
                    isClearable
                    className="form-control"
                />
            </div>
            
            <div className="me-3 w-25">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="en-GB"
                    selected={invoiceDateTo}
                    onChange={handleInvoiceDateTo}
                    placeholderText={"Invoice date to"}
                    isClearable
                    className="form-control"
                />
            </div>
        </div>
        <Button variant="link" onClick={handleClearAllFilters}>Clear filters</Button>
    </div>);
}

export default observer(InvoiceFilters);