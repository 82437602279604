import { makeAutoObservable, reaction } from "mobx";

import configNames from "../common/constants/configNames";

export default class CommonStore {

    token: string | null = window.localStorage.getItem(configNames.TOKEN_NAME);
    appHasLoaded = false;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem(configNames.TOKEN_NAME, token);
                } else {
                    window.localStorage.removeItem(configNames.TOKEN_NAME);
                }
            }
        );
    }

    setToken = (token: string | null) => {
        this.token = token;
    }

    setAppHasLoaded = () => {
        this.appHasLoaded = true;
    }
}