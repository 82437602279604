interface IClientGroupColors {
    UNSPECIFIED: string,
    CHC: string,
    CHC_CHILDREN: string,
    HAMPSHIRE: string,
    HAMPSHIRE_CHILDREN: string,
    PRIVATE: string,
    LONDON_BOROUGH: string,
    SURREY_COUNTY: string,
    SURREY_CHC: string,
    FRIMLEY_ICB: string,
    EALING: string,
    PORTSMOUTH_CITY_COUNCIL: string,
}

const clientGroupColors: IClientGroupColors = {
    UNSPECIFIED: "",
    CHC: "text-primary",
    CHC_CHILDREN: "text-info",
    HAMPSHIRE: "text-secondary",
    HAMPSHIRE_CHILDREN: "text-danger",
    PRIVATE: "text-success",
    LONDON_BOROUGH: "text-danger",
    SURREY_COUNTY: "text-warning",
    SURREY_CHC: "text-info",
    FRIMLEY_ICB: "text-warning",
    EALING: "text-secondary",
    PORTSMOUTH_CITY_COUNCIL: "text-primary",
}

export default clientGroupColors;

