import React from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/esm/Button";

const ExportsChc = () => {
    return <div className="centered-section">
        <h1>CHC spreadsheets</h1>

        {/* <Button variant="outline-primary" as={Link} to="/exports-chc/invoices" className="m-3">Download invoices</Button> */}
        <Button variant="outline-primary" as={Link} to="/exports-chc/updates" className="m-3">Download updates (dom care spreadsheets)</Button>
    </div>
}

export default ExportsChc;