import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import { IDetailsParams } from "../../../../app/models/shared/IDetailsParams";

import { useStore } from "../../../../app/stores/store";

import AddressFormSetup from "./AddressFormSetup";
import BreadCrumbs from "../../../nav/BreadCrumbs";
import Loading from "../../../../app/layout/Loading";


const AddressOuterForm: React.FC<RouteComponentProps<IDetailsParams>> = ({ 
    match,
    history,
 }) => {

    const { adminAddressStore } = useStore();

    const breadCrumbs = match.params.id
        ? [{ name: "Admin", path: "/admin"}, { name: "Billing addresses", path: "/admin/addresses" },
            { name: `${adminAddressStore.address?.clientGroupText} billing address`, path: `/admin/addresses/${match.params.id}`}]
        : [{ name: "Admin", path: "/admin"}, { name: "Billing addresses", path: "/admin/addresses" }];

    useEffect(() => {

        if (!match.params.id) return;

        adminAddressStore.loadAddress(match.params.id);

    }, [match.params.id, adminAddressStore])

    return <>
        {adminAddressStore.loading && <Loading />}
        <div className="centered-section">

            <BreadCrumbs breadCrumbs={breadCrumbs} />

            <h1>{adminAddressStore.address?.id ? adminAddressStore.address?.clientGroupText : "New"} billing address</h1>
            
            <AddressFormSetup
                    key={adminAddressStore.address?.id}
                    address={adminAddressStore.address}
                    create={adminAddressStore.createAddress}
                    update={adminAddressStore.updateAddress}
            />
        </div>
    </>
}

export default observer(AddressOuterForm);