export interface IPagination {
    currentPageNumber: number;
    pageSize: number;
    totalItems: number,
    totalPages: number,
}


export interface IPageDetails {
    currentPageNumber: number;
    currentPageSize: number;
    pageSize: number;
    totalItems: number;
    totalPages: number;
}


export class PaginationResult<T> {
    data: T;
    pagination: IPagination;

    constructor(data: T, pagination: IPagination) {
        this.data = data;
        this.pagination = pagination;
    }
}


export class PagingParams {
    pageNumber = 1;
    pageSize = 25;
}