import React from "react";

import { FormikProps, Form as FForm, Field } from 'formik';
import Button from "react-bootstrap/esm/Button";

import DatePickerField from "../../../../../app/layout/form-fields/DatePickerField";

import { IChcInvoicesDownloadProps } from "../../../../../app/models/exports/IChcInvoicesDownloadProps";

const ChcInvoicesDownloadInnerForm : React.FC<FormikProps<IChcInvoicesDownloadProps>> = (props) => {

    const { isSubmitting } = props;
 
    return (
         <FForm className="form-small">

            <DatePickerField name="invoicePeriodStartDate" label="Invoice period start date" placeholder="dd/mm/yyyy" />

            <Field name="clientId" type="hidden" className="form-control" />
            
            <Button disabled={isSubmitting} variant="primary" type="submit">
                {isSubmitting ? "Downloading..." : "Download"}
            </Button>
      </FForm>
 
    );
  };


export default ChcInvoicesDownloadInnerForm;