import { Field, useField } from "formik";
import { observer } from "mobx-react-lite";

import { ISelectOption } from "../../models/shared/ISelectOption";

import FieldValidationError from "./errors/FieldValidationError";


interface IProps {
    label?: string;
    name: string;
    required?: boolean;
    placeholderOption?: string;
    options: ISelectOption[],
    errorMessage?: string;
}

const SelectField = (props: IProps) => {

  const [field] = useField(props.name);

  return (
      <div className="form-group">
          {props.label && <label className="details-label">{props.label}</label>} 
          <Field component="select" {...field} {...props} className="form-control" >
              <option value="">{props.placeholderOption || "Select an option"}</option>
              {props.options && props.options.map(o => <option key={o.value} {...o}>{o.text}</option>)}
          </Field>
          <FieldValidationError fieldName={props.name} errorMessage={props.errorMessage} />
      </div>
  );
};

export default observer(SelectField);