import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import Table from "react-bootstrap/esm/Table";
import Badge from "react-bootstrap/esm/Badge";
import Modal from "react-bootstrap/esm/Modal";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/esm/Tooltip";

import { FaDownload, FaEdit, FaTrashAlt } from "react-icons/fa";

import DeleteInvoiceFormSetup from "./forms/DeleteInvoiceFormSetup";
import DetailsField from "../../../app/layout/form-fields/DetailsField";
import InvoiceFilters from "../dashboard/filters/InvoiceFilters";
import Loading from "../../../app/layout/Loading";
import NoResultsFound from "../../../app/layout/NoResultsFound";

import { useStore } from "../../../app/stores/store";
import colorFormat from "../../../app/common/helpers/colorFormat";
import defaultColors from "../../../app/common/constants/enums/defaultColors";
import InvoiceStatusFormSetup from "./forms/InvoiceStatusFormSetup";
import { IInvoiceBasic } from "../../../app/models/invoices/IInvoiceBasic";

interface IProps {
    clientId: string | undefined;
}

const InvoiceList : React.FC<IProps> = ({ clientId }) => {

    const { invoiceStore, exportStore } = useStore();

    const [showInvoiceStatusModal, setShowInvoiceStatusModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState<IInvoiceBasic | undefined>(undefined)
    
    const handleCancelInvoiceStatusModal= () => {
        setShowInvoiceStatusModal(false);
    }

    const [showDeleteInvoiceModal, setShowDeleteInvoiceModal] = useState(false);
    
    const handleCancelDeleteInvoiceModal= () => {
        setShowDeleteInvoiceModal(false);
    }

    useEffect(() => {    
        invoiceStore.loadInvoices(clientId);
    }, [invoiceStore, clientId, exportStore.latestInvoice?.id]);

    return <> 
        {invoiceStore.loading && <Loading />}

        <InvoiceFilters 
                setClientNameFilter={invoiceStore.setClientNameFilter}
                setClientGroupFilter={invoiceStore.setClientGroupFilter}
                setInvoiceDateFromFilter={invoiceStore.setInvoiceDateFromFilter}
                setInvoiceDateToFilter={invoiceStore.setInvoiceDateToFilter} 
                clearAllFilters={invoiceStore.clearInvoiceFilters}
        />
                
        {!(invoiceStore.invoices && invoiceStore.invoices.length) ? <NoResultsFound />
        : <div className="m-3">
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Invoice number</th>
                        <th>Invoice date</th>
                        <th>Client</th>
                        <th>Charged as</th>
                        <th>Invoice period</th>
                        <th>Total</th>
                        <th>Paid</th>
                        <th>Validated</th>
                        <th>Disputed</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {invoiceStore.invoices.map(i => (<tr key={i.id}>
                        <td>
                            {i.isACreditNote ? <Badge pill className="bg-danger" >CREDIT NOTE</Badge>
                                : <Badge pill className="bg-primary" >INVOICE</Badge>}
                            <span> {i.invoiceNumber}</span>
                            <span className="text-primary btn" onClick={() => exportStore.downloadGeneralInvoices(i.id, i.fileName)}><FaDownload /></span>
                        </td>
                        <td>{i.invoiceDate}</td>
                        <td>{i.clientName}</td>
                        <td className={colorFormat.getClientGroupColor(i.chargeAs)}>{i.chargeAs}</td>
                        <td>{i.invoicePeriodStart} to {i.invoicePeriodEnd}</td>
                        <td>{i.invoiceTotal}</td>
                        <td>{i.hasBeenPaid ? <span className={defaultColors.GREEN}>Paid</span> : <span className={defaultColors.RED}>-</span>}</td>
                        <td>{i.hasBeenValidated ? <span className={defaultColors.GREEN}>Validated</span> : <span className={defaultColors.RED}></span>}</td>
                        <td>
                            {i.hasBeenDisputed && 
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id={`${i.id}-tooltip`}>{i.disputeComment || "No reason entered"}</Tooltip>}>
                              <Badge pill className="bg-danger" title={i.disputeComment}>DISPUTED</Badge>
                            </OverlayTrigger>}
                        </td>
                        <td>
                            <span className="text-primary btn" onClick={() => { setSelectedInvoice(i); setShowInvoiceStatusModal(true); }}><FaEdit /></span>
                            <span className="text-secondary btn" onClick={() => { setSelectedInvoice(i); setShowDeleteInvoiceModal(true) }}><FaTrashAlt /></span>
                        </td>
                    </tr>))}
                </tbody>
            </Table>
        </div>}

<Modal show={showInvoiceStatusModal} onHide={handleCancelInvoiceStatusModal}>
    <Modal.Header closeButton>
    <Modal.Title>Update invoice status</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="mb-5">
            <p>Client: {selectedInvoice?.clientName}</p>
            <p>Invoice: {selectedInvoice?.invoiceNumber}</p>
        </div>
        {selectedInvoice && <InvoiceStatusFormSetup
            id={selectedInvoice.id}
            clientId={selectedInvoice.clientId}
            hasBeenPaid={selectedInvoice.hasBeenPaid}
            hasBeenValidated={selectedInvoice.hasBeenValidated}
            hasBeenDisputed={selectedInvoice.hasBeenDisputed}
            disputeComment={selectedInvoice.disputeComment}
            closeModal={() => setShowInvoiceStatusModal(false)}
            update={invoiceStore.updateInvoice(!!clientId)} />}
    </Modal.Body>
</Modal>

<Modal show={showDeleteInvoiceModal} onHide={handleCancelDeleteInvoiceModal}>
    <Modal.Header closeButton>
    <Modal.Title>Are you sure you want to delete?</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div className="mt-3 mb-5">
            <p className="mb-5">Deleting this invoice cannot be undone</p>
            
            <DetailsField label="Client" value={selectedInvoice?.clientName} />
            
            <DetailsField label="Invoice" value={selectedInvoice?.invoiceNumber} />
        </div>

        {selectedInvoice && <DeleteInvoiceFormSetup
            id={selectedInvoice.id}
            clientId={selectedInvoice.clientId}
            reason={undefined}
            closeModal={() => setShowDeleteInvoiceModal(false)}
            delete={invoiceStore.deleteInvoice(!!clientId)} />}
    </Modal.Body>
</Modal>
    </>
}

export default observer(InvoiceList);