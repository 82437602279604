import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../../../../app/stores/store";
import clientGroups from "../../../../../app/common/constants/enums/clientGroups";
import friendlyFormat from "../../../../../app/common/helpers/friendlyFormat";

import { FormikProps, Form as FForm, Field } from 'formik';
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";

import DatePickerField from "../../../../../app/layout/form-fields/DatePickerField";
import Loading from "../../../../../app/layout/Loading";
import TextInputField from "../../../../../app/layout/form-fields/TextInputField";

import { IChcUpdatesDownloadProps } from "../../../../../app/models/exports/IChcUpdatesDownloadProps";

const ChcUpdatesDownloadInnerForm : React.FC<FormikProps<IChcUpdatesDownloadProps>> = (props) => {

    const { isSubmitting } = props;

    const { clientStore } = useStore();

    useEffect(() => {
        clientStore.loadClients(clientGroups.CHC.name);
    }, [clientStore])
 
    return (
         <FForm className="form-small">
            {clientStore.loading && <Loading />}

            <DatePickerField name="weekCommencingDate" label="Week commencing date" placeholder="Date should be a Monday" />

            <TextInputField name="weekNumber" label="Week number (must be 1, 2, 3 or 4)" />

            <Form.Group controlId="clientIds" className="mb-3"> 
                <Form.Label className="details-label">Clients</Form.Label> 
                <Field name="clientIds" component="select" multiple={true} className="form-control long-multiple-select" required={true}>
                    <option value="">Select a client</option>
                    {clientStore.clients && clientStore.clients.map(c => <option key={c.id} value={c.id}>{c.firstName} {c.lastName} {friendlyFormat.thisIfTrue(c.hasBeenArchived, " - Archived")}</option>)}
                </Field>
            </Form.Group>
            
            <Field name="clientId" type="hidden" className="form-control" />

             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Downloading..." : "Download"}
             </Button>
      </FForm>
 
    );
  };


export default observer(ChcUpdatesDownloadInnerForm);