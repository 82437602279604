import React from "react";
import { Link } from "react-router-dom";
import { IBreadCrumb } from "../../app/models/shared/IBreadCrumb";

const BreadCrumbs: React.FC<{ breadCrumbs: IBreadCrumb[] }> = ({ breadCrumbs }) => {
    breadCrumbs = breadCrumbs || [];

    const elements = breadCrumbs.map(b => <Link key={b.path} to={b.path}>{b.name}</Link>);

    return <div>
        <span><Link to="/">Home</Link></span>
        {elements.map(e => <span key={e.key}>{" > "}{e}</span>)}
    </div>  
}

export default BreadCrumbs;