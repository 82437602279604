import { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { useStore } from "../../app/stores/store";

const LogoutForm = () => {
    const { userStore } = useStore();
    
    useEffect(() => {
        userStore.logout();
    }, [userStore]);

    return (
        <div>Goodbye! Thank you for using Vine Social Care Invoices!</div>
    )
}

export default observer(LogoutForm);