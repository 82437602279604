import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/esm/Button";
import Table from "react-bootstrap/esm/Table";
import ToggleButton from "react-bootstrap/esm/ToggleButton";

import { useStore } from "../../../../app/stores/store";

import AddressRow from "./AddressRow";
import Loading from "../../../../app/layout/Loading";
import NoResultsFound from "../../../../app/layout/NoResultsFound";

const AddressDashboard = () => {
    const { adminAddressStore } = useStore();   

    useEffect(() => {
        adminAddressStore.loadAddresses();
    }, [adminAddressStore]);

    return <>
        {adminAddressStore.loading && <Loading />}
        <div className="centered-section">
            <div className="d-flex flex-row">
            <h1>Invoice billing addresses</h1>
                <div className="ms-5">
                <Button variant="outline-primary" as={Link} to="/admin/addresses/create">New</Button>
                </div>
            </div>

            <div className="filters-container">
                <div className="d-flex flex-row mt-3">
                    <ToggleButton
                        id="toggle-check"
                        type="checkbox"
                        variant="light"
                        checked={adminAddressStore.activeAddressesOnly}
                        value="1"
                        onChange={(e) => adminAddressStore.setActiveAddressesOnly(e.currentTarget.checked)}
                    >
                        <span className="ms-1">Show active addresses only</span>
                    </ToggleButton>
                </div>
            </div>

            {!(adminAddressStore.addresses.length)
                ? <NoResultsFound />
                : <Table striped hover>
                        <tbody>
                            {adminAddressStore.addresses.map(a => <AddressRow address={a} />)}
                        </tbody>
                    </Table>}
            
        </div>  
    </>  
}

export default observer(AddressDashboard);