import { withFormik } from 'formik';

import { IAddress } from '../../../../app/models/addresses/IAddress';

import history from '../../../../app/common/history';

import AddressInnerForm from './AddressInnerForm';

interface IFormProps {
    address: IAddress | null;
    create(props: IAddress): Promise<void>;
    update(props: IAddress): Promise<void>;
}

const AddressFormSetup = withFormik<IFormProps, IAddress>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
      return {

        id: props?.address?.id || null,
        clientGroup: props?.address?.clientGroup || null,
        line1: props?.address?.line1 || null,
        line2: props?.address?.line2 || null,
        town: props?.address?.town || null,
        county: props?.address?.county || null,
        postCode: props?.address?.postCode || null,
        startDate: props?.address?.startDate || null,
        isActive: props?.address?.isActive || false,

      } as IAddress; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {
            
              const apiCall = props?.address?.id
                  ? props.update
                  : props.create;

              apiCall(updatedForm)
                  .then(() => history.push("/admin/addresses"));;
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(AddressInnerForm);


export default AddressFormSetup;