import React from "react";

import { FormikProps, Form as FForm } from 'formik';
import Button from "react-bootstrap/esm/Button";

import FileUploadField from "../../../app/layout/form-fields/FileUploadField";

import IFileUpload from "../../../app/models/shared/IFileUpload";

const InnerForm : React.FC<FormikProps<IFileUpload>> = (props) => {

    const { isSubmitting } = props;
 
    return (
         <FForm className="form-small">
 
             <h1>Upload visits</h1>

              <FileUploadField name="file" label="Nursebuddy visits spreadsheet" />

             <Button disabled={isSubmitting} variant="outline-primary" type="submit">
                 {isSubmitting ? "Uploading..." : "Upload visits"}
             </Button>
      </FForm>
 
    );
  };


export default InnerForm;