import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/esm/Button";

interface IProps {
    setClientNameFilter: (clientName: string) => void;
    setDateFromFilter: (dateFrom: Date | null) => void;
    setDateToFilter: (dateTo: Date | null) => void;
    applyFilters: () => void;
    clearAllFilters: () => void;
}

const VisitFilters: React.FC<IProps> = ({ 
    setClientNameFilter, 
    setDateFromFilter,
    setDateToFilter,
    applyFilters,
    clearAllFilters,
 }) => {

    const [clientName, setClientName] = useState("");
    const [dateFrom, setDateFrom] = useState<Date | null>(null);
    const [dateTo, setDateTo] = useState<Date | null>(null);

    const handleClientNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientName(event.target.value);
        setClientNameFilter(event.target.value);
    }

    const handleClientNameEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") applyFilters();
    }

    const handleDateFrom = (date: Date) => {
        setDateFrom(date);
        setDateFromFilter(date);
        applyFilters();
    }

    const handleDateTo = (date: Date) => {
        setDateTo(date);
        setDateToFilter(date);
        applyFilters();
    }

    const handleClearAllFilters = () => {
        clearAllFilters();
        setClientName("");
        setDateFrom(null);
        setDateTo(null);
    }

    return (<div className="filters-container">
        <div className="d-flex flex-row mt-3 mb-3">
            <input 
                name="clientNameFilter" 
                placeholder="Filter clients" 
                className="form-control me-3 w-25" 
                onChange={handleClientNameChange}
                onBlur={applyFilters}
                onKeyUp={handleClientNameEnter}
                value={clientName} 
            />
                
            <div className="me-3 w-25">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="en-GB"
                    selected={dateFrom}
                    onChange={handleDateFrom}
                    placeholderText={"Date from"}
                    isClearable
                    className="form-control"
                />
            </div>
            
            <div className="me-3 w-25">
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    locale="en-GB"
                    selected={dateTo}
                    onChange={handleDateTo}
                    placeholderText={"Date to"}
                    isClearable
                    className="form-control"
                />
            </div>
        </div>

        <Button variant="link" onClick={handleClearAllFilters}>Clear filters</Button>
    </div>);
}

export default observer(VisitFilters);