interface IColumnSorting {
    NONE: number;
    ASCENDING: number;
    DESCENDING: number;
}

export const columnSorting: IColumnSorting = {
    NONE: 0,
    ASCENDING: 1,
    DESCENDING: 2,    
};