import React from "react";
import { observer } from "mobx-react-lite";

import { Dropdown } from "react-bootstrap";

import { useStore } from "../../app/stores/store"

const NavBarCurrentAccount = () => {
    const { userStore } = useStore();

    return <div className="user-controls d-flex order-md-3 ml-auto">

                <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                        <span>{userStore.user?.firstName || ""}</span>  
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="/logout">Log out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
}

export default observer(NavBarCurrentAccount);