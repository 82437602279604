import clientGroupColors from "../constants/enums/clientGroupColors";
import clientGroups from "../constants/enums/clientGroups";
import defaultColors from "../constants/enums/defaultColors";
import weekNumbers from "../constants/enums/weekNumbers";

const getClientGroupColor = (clientGroup: string) => {
    return clientGroup === clientGroups.CHC.name ? clientGroupColors.CHC
    : clientGroup === clientGroups.CHC_CHILDREN.name ? clientGroupColors.CHC_CHILDREN
    : clientGroup === clientGroups.HAMPSHIRE.name ? clientGroupColors.HAMPSHIRE
    : clientGroup === clientGroups.HAMPSHIRE_CHILDREN.name ? clientGroupColors.HAMPSHIRE_CHILDREN
    : clientGroup === clientGroups.PRIVATE.name ? clientGroupColors.PRIVATE
    : clientGroup === clientGroups.LONDON_BOROUGH.name ? clientGroupColors.LONDON_BOROUGH
    : clientGroup === clientGroups.SURREY_COUNTY.name ? clientGroupColors.SURREY_COUNTY
    : clientGroup === clientGroups.SURREY_CHC.name ? clientGroupColors.SURREY_CHC
    : clientGroup === clientGroups.FRIMLEY_ICB.name ? clientGroupColors.FRIMLEY_ICB
    : clientGroup === clientGroups.EALING.name ? clientGroupColors.EALING
    : clientGroup === clientGroups.PORTSMOUTH_CITY_COUNCIL.name ? clientGroupColors.PORTSMOUTH_CITY_COUNCIL
    : "";
}

const getWeekNumberColor = (weekNumber: number) => {
    return weekNumber === weekNumbers.ONE ? defaultColors.BLUE
    : weekNumber === weekNumbers.TWO ? defaultColors.YELLOW
    : weekNumber === weekNumbers.THREE ? defaultColors.AQUAMARINE
    : weekNumber === weekNumbers.FOUR ? defaultColors.GREEN
    : "";
}

const colorFormat = {
    getClientGroupColor,
    getWeekNumberColor,
}

export default colorFormat;