import { withFormik } from 'formik';

import PepFileDownloadInnerForm from "./PepFileDownloadInnerForm";

import { IPepFileDownloadProps } from '../../../../../app/models/exports/IPepFileDownloadProps';

interface IFormProps extends IPepFileDownloadProps {
    download(props: IPepFileDownloadProps): Promise<void>;
}

const PepFileDownloadFormSetup = withFormik<IFormProps, IPepFileDownloadProps>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
      return {
 
         weekCommencingDate: props?.weekCommencingDate || null,

         includeAdditionalColumns: !!props?.includeAdditionalColumns,

      } as IPepFileDownloadProps; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {

             props.download(updatedForm);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(PepFileDownloadInnerForm);


export default PepFileDownloadFormSetup;