import React from "react";
import { observer } from "mobx-react-lite";

import { FormikProps, Form as FForm, Field, FieldArray } from 'formik';

import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import Table from "react-bootstrap/esm/Table";

import { IManualInvoiceDownloadProps } from "../../../../../app/models/exports/IManualInvoiceDownloadProps";

import CheckboxField from "../../../../../app/layout/form-fields/CheckboxField";
import CustomButton from "../../../../../app/layout/common-components/buttons/CustomButton";
import DatePickerField from "../../../../../app/layout/form-fields/DatePickerField";
import TextInputField from "../../../../../app/layout/form-fields/TextInputField";


const ManualInvoicesDownloadInnerForm : React.FC<FormikProps<IManualInvoiceDownloadProps>> = (props) => {

    const { isSubmitting, values } = props;
 
    return (
         <FForm className="form-small">

            <Form.Group controlId="chargeAs" className="mb-3"> 
                <Form.Label className="details-label">Charge as</Form.Label> 
                <Field
                    name="chargeAs" 
                    component="select" 
                    className="form-control" 
                    required
                    disabled
                >
                    <option value="">Select a group</option>
                    <option value="1">CHC</option>
                    <option value="9">CHC (Children)</option>
                    <option value="2">Hampshire</option>
                    <option value="8">Hampshire (Children)</option>
                    <option value="3">Private</option>
                    <option value="4">London Borough</option>
                    <option value="5">Surrey County</option>
                    <option value="6">Surrey CHC</option>
                    <option value="7">Frimley ICB</option>
                    <option value="10">Ealing Council</option>
                    <option value="11">Portsmouth City Council</option>
                </Field>
            </Form.Group>

            <CustomButton 
                type="button" 
                text="Edit Charge As" 
                classes="btn-outline-secondary" 
                onClick={() => values.setSelectedChargeAs(undefined)} 
            />

            <CheckboxField name="isACreditNote" label="Credit note" />

            <TextInputField name="pONumber" label="P.O. Number" />

            <TextInputField name="additionalInformation" label="Additional information" />

            <div className="extra-wide border m-3 p-5">
            <Table bordered hover>
                <thead>
                  <tr>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Description</th>
                    <th>Unit price</th>
                    <th>Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>

                  <FieldArray

                    name="invoiceLines"

                    render={arrayHelpers => (

                      <>

                        {values.invoiceLines && values.invoiceLines.length > 0 ? (

                          values.invoiceLines.map((invoiceLine, index) => (


                              <tr key={index}>
                              <td><DatePickerField name={`invoiceLines.${index}.startDate`}  label="" placeholder="dd/mm/yyyy" required /></td>
                              <td><DatePickerField name={`invoiceLines.${index}.endDate`}  label="" placeholder="dd/mm/yyyy" required /></td>
                              <td><TextInputField name={`invoiceLines.${index}.description`}  label="" placeholder="e.g. 41 hours per week @ agreed weekly rate of £900." required /></td>
                              <td><TextInputField name={`invoiceLines.${index}.unitPrice`}  label="" placeholder="e.g. 1234.56" required /></td>
                              <td><TextInputField name={`invoiceLines.${index}.amount`}  label="" placeholder="e.g. 1234.56" required /></td>
                              
                              <td className="d-flex flex-row">
                                  <Button

                                    className="me-3"
                                
                                    variant="outline-secondary"

                                    onClick={() => arrayHelpers.remove(index)}
                                  >

                                    -

                                  </Button>

                                  <Button

                                    variant="outline-secondary"

                                    onClick={() => arrayHelpers.insert(index + 1, '')} 

                                  >

                                    +

                                  </Button>
                              </td>
                              </tr>

                          ))

                        ) : (
                          <tr>
                            <td colSpan={8}>
                                
                              <Button variant="outline-secondary" onClick={() => arrayHelpers.push('')}>
                                Add an invoice line
                              </Button>
                            </td>
                          </tr>
                          

                        )}

                      </>

                    )}

                  />

                </tbody>
            </Table>
            </div>


            <Field name="clientId" type="hidden" className="form-control" />

             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Downloading..." : "Download"}
             </Button>
      </FForm> 
    );
  };


export default observer(ManualInvoicesDownloadInnerForm);