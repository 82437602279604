import { observer } from "mobx-react-lite";
import Table from "react-bootstrap/esm/Table";
import ColumnHeading from "../../app/layout/common-components/grid/ColumnHeading";
import visitStatuses from "../../app/common/constants/enums/visitStatus";
import { IVisitBasic } from "../../app/models/visits/IVisitBasic";


interface IProps {
    visits: IVisitBasic[];
    sortingAppliedClientName: boolean;
    sortDescendingClientName: boolean;
    setClientNameColumnSorting: (sortingOptions: number) => void;
    sortingAppliedStartDate: boolean;
    sortDescendingStartDate: boolean;
    setStartDateColumnSorting: (sortingOptions: number) => void;
}

const VisitListTable = ({
    visits,
    sortingAppliedClientName,
    sortDescendingClientName,
    setClientNameColumnSorting,
    sortingAppliedStartDate,
    sortDescendingStartDate,
    setStartDateColumnSorting,
}: IProps) => {
    return (<Table bordered hover>
        <thead>
            <tr>
                <th>
                    <ColumnHeading
                        text="Client" 
                        includeSorting={true}
                        sortingApplied={sortingAppliedClientName}
                        sortDescending={sortDescendingClientName}
                        setColumnSorting={setClientNameColumnSorting}
                    />
                </th>
                <th>
                    <ColumnHeading
                        text="Date"
                        includeSorting={true}
                        sortingApplied={sortingAppliedStartDate}
                        sortDescending={sortDescendingStartDate}
                        setColumnSorting={setStartDateColumnSorting}
                    />
                </th>
                <th><ColumnHeading text="Time" /></th>
                <th><ColumnHeading text="Duration" /></th>
                <th><ColumnHeading text="Carers" /></th>
                <th><ColumnHeading text="VisitType" /></th>
                <th><ColumnHeading text="Status" /></th>
            </tr>
        </thead>
        <tbody>
            {visits.map(v => (<tr key={v.id} className={v.status === visitStatuses.COMPLETED || v.status === visitStatuses.STARTED ?  "text-success" : "text-danger"}>
                <td>{v.client}</td>
                <td>{v.date}</td>
                <td>{v.time}</td>
                <td>{v.duration}</td>
                <td>{v.carers}</td>
                <td>{v.visitType}</td>
                <td>{v.status}</td>
            </tr>))}
        </tbody>
    </Table>);
}

export default observer(VisitListTable);