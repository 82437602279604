import React from "react";

import { FormikProps, Form as FForm } from 'formik';
import Button from "react-bootstrap/esm/Button";

import DatePickerField from "../../../../../app/layout/form-fields/DatePickerField";
import CheckboxField from "../../../../../app/layout/form-fields/CheckboxField";

import { IPepFileDownloadProps } from "../../../../../app/models/exports/IPepFileDownloadProps";

const PepFileDownloadInnerForm : React.FC<FormikProps<IPepFileDownloadProps>> = (props) => {

    const { isSubmitting } = props;
 
    return (
         <FForm className="form-small">
 
            <h1>Download PEP file</h1>

            <DatePickerField name="weekCommencingDate" label="Week commencing date" placeholder="Date should be a Monday" />

            <CheckboxField name="includeAdditionalColumns" label="Include review columns" />

             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Downloading..." : "Download"}
             </Button>
      </FForm>
 
    );
  };


export default PepFileDownloadInnerForm;