import React from "react";
import { observer } from "mobx-react-lite";

import { Field } from 'formik';

import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'

const PackageValidation: React.FC = () => {

    return <>
        <h5>Package validation</h5>

        <div className="extra-wide border ms-3 me-3 mt-3 mb-5 p-5">
            <Table bordered hover>
                <thead>
                    <tr>
                    <th>Type</th>
                    <th>Expected</th>
                    <th>Actual</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Total cost
                        </td>
                        <td>
                            <Form.Group controlId="expectedWeeklyCostCopy"> 
                                <Field type="number" disabled name="expectedWeeklyCost" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="configuredTotalCost"> 
                                <Field type="number" disabled name="configuredTotalCost" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Commissioned hours
                        </td>
                        <td>
                            <Form.Group controlId="numberOfCommissionedHoursCopy"> 
                                <Field type="number" disabled name="numberOfCommissionedHours" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="configuredCommissionedHours"> 
                                <Field type="number" disabled name="configuredCommissionedHours" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    </>
}

export default observer(PackageValidation);