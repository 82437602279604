import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { useStore } from "../../../app/stores/store";

import RemittanceAdviceFormSetup from "./RemittanceAdviceFormSetup";


const RemittanceAdviceFormOuter = () => {

    const { carerStore, exportStore } = useStore();

    useEffect(() => {
      
        carerStore.loadCarers();

    }, [carerStore]);

    return (<>
        <h1 className="mb-5">New remittance advice</h1>

        <RemittanceAdviceFormSetup
            carerId={undefined}
            remittanceLines={undefined}
            download={exportStore.downloadRemittances}
        />
             
    </>);
}

export default observer(RemittanceAdviceFormOuter);