import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import Button from "react-bootstrap/esm/Button";


interface IProps {
    setClientNameFilter: (clientName: string) => void;
    setClientAccountNumberFilter: (accountNumber: string) => void;
    setClientGroupFilter: (clientGroup: string) => void;
    setClientArchivedFilter: (includeArchived: boolean) => void;
    clearAllFilters: () => void;
}

const ClientFilters: React.FC<IProps> = ({ 
    setClientNameFilter, 
    setClientAccountNumberFilter,
    setClientGroupFilter,
    setClientArchivedFilter,
    clearAllFilters,
 }) => {

    const [clientNameValue, setClientNameValue] = useState("");
    const [accountNumberValue, setAccountNumberValue] = useState("");
    const [clientGroupValue, setClientGroupValue] = useState("");
    const [clientArchivedValue, setClientArchivedValue] = useState(false)

    const handleClientNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientNameValue(event.target.value);
        setClientNameFilter(event.target.value);
    }

    const handleClientAccountNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAccountNumberValue(event.target.value);
        setClientAccountNumberFilter(event.target.value);
    }

    const handleClientGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setClientGroupValue(event.target.value);
        setClientGroupFilter(event.target.value);
    }

    const handleClientArchivedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setClientArchivedValue(event.target.checked);
        setClientArchivedFilter(event.target.checked)
    }

    const handleClearAllFilters = () => {
        clearAllFilters();
        setClientNameValue("");
        setAccountNumberValue("");
        setClientGroupValue("");
        setClientArchivedValue(false);
    }

    return (<div className="filters-container">
        <div className="d-flex flex-row mt-3">
            <input 
                name="clientNameFilter" 
                placeholder="Filter by client name" 
                className="form-control me-3 w-25" 
                onChange={handleClientNameChange} 
                value={clientNameValue} 
            />
            
            <input
                name="clientAccountNumberFilter" 
                placeholder="Filter by account number" 
                className="form-control me-3 w-25" 
                onChange={handleClientAccountNumberChange}
                value={accountNumberValue} 
            />
            
            <select
                name="clientGroupFilter"
                className="form-control me-3 w-25"
                onChange={handleClientGroupChange}
                value={clientGroupValue}
            >
                <option value="">Filter by group</option>
                <option value="All">Show all groups</option>
                <option value="CHC">CHC</option>
                <option value="CHCChildren">CHC (Children)</option>
                <option value="Hampshire">Hampshire</option>
                <option value="HampshireChildren">Hampshire (Children)</option>
                <option value="Private">Private</option>
                <option value="LondonBorough">London Borough</option>
                <option value="SurreyCounty">Surrey County</option>
                <option value="SurreyCHC">Surrey CHC</option>
                <option value="FrimleyICB">Frimley ICB</option>
                <option value="Ealing">Ealing Council</option>
                <option value="PortsmouthCityCouncil">Portsmouth City Council</option>
            </select>

            <div className="form-check w-25 pt-1">
                <input 
                    name="clientArchivedFilter" 
                    className="form-check-input" 
                    type="checkbox" 
                    onChange={handleClientArchivedChange}
                    checked={clientArchivedValue} 
                />
                <label className="form-check-label">
                    Show archived
                </label>
            </div>
        </div>
        <Button variant="link" onClick={handleClearAllFilters}>Clear filters</Button>
    </div>);
}

export default observer(ClientFilters);