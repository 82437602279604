import { columnSorting } from "../../../app/common/constants/enums/columnSorting";
import VisitStore from "../../../app/stores/visitStore";


export const setClientNameColumnSorting = (
    visitStore: VisitStore,
    clientId: string | undefined,
    sortingOptions: number,
    setSortingAppliedClientName: (sortingApplied: boolean) =>  void,
    setSortingAppliedStartDate: (sortingApplied: boolean) =>  void,
    setSortDescendingClientName: (sortDescending: boolean) =>  void,
) => {
    setSortingAppliedClientName(true);
    setSortingAppliedStartDate(false);
    setSortDescendingClientName(sortingOptions === columnSorting.DESCENDING);
    visitStore.setClientNameColumnSorting(sortingOptions);
    visitStore.loadVisits(clientId);
}


export const setStartDateColumnSorting = (
    visitStore: VisitStore,
    clientId: string | undefined,
    sortingOptions: number,
    setSortingAppliedClientName: (sortingApplied: boolean) =>  void,
    setSortingAppliedStartDate: (sortingApplied: boolean) =>  void,
    setSortDescendingStartDate: (sortDescending: boolean) =>  void,
) => {
    setSortingAppliedStartDate(true);
    setSortingAppliedClientName(false);
    setSortDescendingStartDate(sortingOptions === columnSorting.DESCENDING);
    visitStore.setStartDateColumnSorting(sortingOptions);
    visitStore.loadVisits(clientId);
}


const sortingHandlers = {
    setClientNameColumnSorting,
    setStartDateColumnSorting,
};

export default sortingHandlers;