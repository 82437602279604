import { makeAutoObservable, runInAction } from "mobx";

import { IRemittanceBasic } from "../models/remittances/IRemittanceBasic";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import notificationMessages from "../common/constants/enums/notificationMessages";
import { sendNotificationSuccess } from "../common/helpers/notificationsHelper";


interface IRemittanceFilters {
    carerName: string | null;
    remittanceDateFrom: Date | null;
    remittanceDateTo: Date | null;
}

export default class RemittanceStore {

    constructor() {
        makeAutoObservable(this);
    }

    defaultFilters = (): IRemittanceFilters => {
        return { 
            carerName: null, 
            remittanceDateFrom: null,
            remittanceDateTo: null,
        };
    }

    remittanceRegistry : Map<string, IRemittanceBasic> = new Map();
    loading = true;
    filters: IRemittanceFilters = this.defaultFilters();

    get remittances () {
        return Array.from(this.remittanceRegistry.values())
        .filter(r => (!this.filters.carerName
                    || (r.carer.toLowerCase().includes(this.filters.carerName.toLowerCase())))
                && (!this.filters.remittanceDateFrom || this.filters.remittanceDateFrom <= new Date(r.dateIso))
                && (!(this.filters.remittanceDateTo) || new Date(r.dateIso) <=  this.filters.remittanceDateTo));
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setCarerNameFilter = (carerName: string | null) => {
        runInAction(() => {
            this.filters.carerName = carerName;
        });
    }

    setRemittanceDateFromFilter = (remittanceDateFrom: Date | null) => {
        runInAction(() => {
            this.filters.remittanceDateFrom = remittanceDateFrom;
        });
    }

    setRemittanceDateToFilter = (remittanceDateTo: Date | null) => {

        runInAction(() => {
            if(!remittanceDateTo) {
                this.filters.remittanceDateTo = remittanceDateTo;
                return;
            }
            
            this.filters.remittanceDateTo = new Date(
                remittanceDateTo.getFullYear(), 
                remittanceDateTo.getMonth(), 
                remittanceDateTo.getDate(),
                23, 
                59, 
                59);
        });
    }

    clearRemittanceFilters = () => {
        runInAction(() => {
            this.filters = this.defaultFilters();
        });
    }

    loadRemittances = async () => {
        this.setLoading(true);
        this.remittanceRegistry = new Map();
        
        try {
            this.clearRemittanceFilters();

            const remittances = await ApiHelpers.Remittances.list();

            if(!remittances) return;

            runInAction(() => {
                remittances.forEach(m => this.remittanceRegistry.set(m.id, m));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    deleteRemittance = async (id: string, reason: string | undefined) => {
        this.setLoading(true);

        try {

            await ApiHelpers.Remittances.delete(id, reason);

            await this.loadRemittances();
            
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }
}