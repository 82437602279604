import VisitStore from "../../../app/stores/visitStore";



export const handlePageSizeChange = (
    visitStore: VisitStore,
    clientId: string | undefined,
    setPageSize: (pageSize: number) => void,
    setPageNumber: (pageNumber: string) => void,
) => (event: React.ChangeEvent<HTMLSelectElement>) => {

    const pageSizeValue = Number(event.target.value);

    if (isNaN(pageSizeValue)) {
        setPageSize(visitStore.searchParams.pageSize);
        return;
    };

    visitStore.setCurrentPageSize(pageSizeValue);
    setPageSize(visitStore.searchParams.pageSize);
    setPageNumber(visitStore.searchParams.pageNumber.toString());

    visitStore.loadVisits(clientId);
}



const gridFooterHandlers = {
    handlePageSizeChange,
};



export default gridFooterHandlers;