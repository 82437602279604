import IEnumDetails from "./helpers/IEnumDetails";

interface IClientGroups {
    CHC: IEnumDetails,
    HAMPSHIRE: IEnumDetails,
    PRIVATE: IEnumDetails,
    LONDON_BOROUGH: IEnumDetails,
    SURREY_COUNTY: IEnumDetails,
    SURREY_CHC: IEnumDetails,
    FRIMLEY_ICB: IEnumDetails,
    HAMPSHIRE_CHILDREN: IEnumDetails,
    CHC_CHILDREN: IEnumDetails,
    EALING: IEnumDetails,
    PORTSMOUTH_CITY_COUNCIL: IEnumDetails,
}

const clientGroups: IClientGroups = {
    CHC: { value: 1, name: "CHC", label: "CHC" },
    CHC_CHILDREN: { value: 9, name: "CHCChildren", label: "CHC (Children)" },
    HAMPSHIRE: { value: 2, name: "Hampshire", label: "Hampshire" },
    HAMPSHIRE_CHILDREN: { value: 8, name: "HampshireChildren", label: "Hampshire (Children)" },
    PRIVATE: { value: 3, name: "Private", label: "Private" },
    LONDON_BOROUGH: { value: 4, name: "LondonBorough", label: "London Borough" },
    SURREY_COUNTY: { value: 5, name: "SurreyCounty", label: "Surrey County" },
    SURREY_CHC: { value: 6, name: "SurreyCHC", label: "Surrey CHC" },
    FRIMLEY_ICB: { value: 7, name: "FrimleyICB", label: "Frimley ICB" },
    EALING: { value: 10, name: "Ealing", label: "Ealing Council" },
    PORTSMOUTH_CITY_COUNCIL: { value: 11, name: "PortsmouthCityCouncil", label: "Portsmouth City Council" },
}

export default clientGroups;

