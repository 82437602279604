import { withFormik } from 'formik';

import SurreyCountyInvoicesDownloadInnerForm from "./SurreyCountyInvoicesDownloadInnerForm";

import { ISurreyCountyInvoicesDownloadProps } from '../../../../../app/models/exports/ISurreyCountyInvoicesDownloadProps';

interface IFormProps extends ISurreyCountyInvoicesDownloadProps {
    download(props: ISurreyCountyInvoicesDownloadProps): Promise<void>;
}

const SurreyCountyInvoicesDownloadFormSetup = withFormik<IFormProps, ISurreyCountyInvoicesDownloadProps>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
        return {

            clientId: props?.clientId || null,
 
            invoicePeriodStartDate: props?.invoicePeriodStartDate || null,
 
            invoicePeriodEndDate: props?.invoicePeriodEndDate || null,

            invoiceTotal: props?.invoiceTotal || null,

      } as ISurreyCountyInvoicesDownloadProps; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {

             props.download(updatedForm);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(SurreyCountyInvoicesDownloadInnerForm);


export default SurreyCountyInvoicesDownloadFormSetup;