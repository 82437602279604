import { withFormik } from 'formik';

import PrivateClientsInvoicesDownloadInnerForm from "./PrivateClientsInvoicesDownloadInnerForm";

import { IPrivateClientInvoicesDownloadProps } from '../../../../../app/models/exports/IPrivateClientInvoicesDownloadProps';

interface IFormProps extends IPrivateClientInvoicesDownloadProps {
    download(props: IPrivateClientInvoicesDownloadProps): Promise<void>;
}

const PrivateClientsInvoicesDownloadFormSetup = withFormik<IFormProps, IPrivateClientInvoicesDownloadProps>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
        return {

            clientId: props?.clientId || null,
 
            weekCommencingDate: props?.weekCommencingDate || null,

            numberOfWeeks: props?.numberOfWeeks || 1

      } as IPrivateClientInvoicesDownloadProps; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {

             props.download(updatedForm);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(PrivateClientsInvoicesDownloadInnerForm);


export default PrivateClientsInvoicesDownloadFormSetup;