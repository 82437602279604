import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

import { IDetailsParams } from "../../../app/models/shared/IDetailsParams";

import { useStore } from "../../../app/stores/store";

import BreadCrumbs from "../../nav/BreadCrumbs";
import CarerFormSetup from "./CarerFormSetup";
import Loading from "../../../app/layout/Loading";


const CarerFormOuter: React.FC<RouteComponentProps<IDetailsParams>> = ({
    match,
    history
  }) => {
    const { carerStore } = useStore();
    
    useEffect(() => {
        if (!match.params.id) {
            carerStore.clearSelectedCarer();
            return;
        }

        carerStore.loadCarer(match.params.id);
    }, [match.params.id, carerStore]);

    const breadCrumbs = [{ name:"Carers", path: "/carers"}];

    return <>
        {carerStore.loading && <Loading />}
        <div className="centered-section">
            
            <BreadCrumbs breadCrumbs={breadCrumbs} />
            
            <CarerFormSetup
                key={match.params.id}
                id={match.params.id}
                firstName={carerStore.carer?.firstName || ""}
                lastName={carerStore.carer?.lastName || ""}
                carerNumber={carerStore.carer?.carerNumber || ""}
                create={carerStore.createCarer}
                update={carerStore.updateCarer}
            />
                    
        </div>
    </>;
}

export default observer(CarerFormOuter);