import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/esm/Button";
import VisitList from "./VisitList";

const Visits = () => {
    return <>
        <div className="centered-section">
            <div className="d-flex flex-row">
                <h1>Visits</h1>
                
                <div className="ms-5">
                    <Button variant="outline-primary" as={Link} to="/visits/create">Upload</Button>
                </div>
            </div>

            <VisitList clientId={undefined} />
        </div>  
    </>  
}

export default observer(Visits);