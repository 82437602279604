import { makeAutoObservable, runInAction } from "mobx";

import { IClientBillingAddress } from "../models/clients/IClientBillingAddress";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import notificationMessages from "../common/constants/enums/notificationMessages";
import { sendNotificationSuccess } from "../common/helpers/notificationsHelper";

export default class ClientBillingAddressStore {

    constructor() {
        makeAutoObservable(this);
    }

    clientBillingAddress : IClientBillingAddress | null = null;
    loading = true;

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    loadClientBillingAddress = async (id: string) => {
        this.setLoading(true);

        try {
            const clientBillingAddress = await ApiHelpers.ClientBillingAddresses.details(id);

            runInAction(() => {
                this.clientBillingAddress = clientBillingAddress;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateClientBillingAddress = async (billingAddress: IClientBillingAddress) => {
        this.setLoading(true);

        try {
            await ApiHelpers.ClientBillingAddresses.update(billingAddress);
            await this.loadClientBillingAddress(billingAddress.id!);
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }
}