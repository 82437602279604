import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";

import BreadCrumbs from "../../nav/BreadCrumbs";
import Loading from "../../../app/layout/Loading";

import ClientAuditUploadFormSetup from "./ClientAuditUploadFormSetup";

import { useStore } from "../../../app/stores/store";
import NoResultsFound from "../../../app/layout/NoResultsFound";
import { Table } from "react-bootstrap";

const ClientAuditUploadOuterForm: React.FC<RouteComponentProps> = ({
    match,
    history
  }) => {

    const { auditStore } = useStore();

    const breadCrumbs = [{ name:"Audits", path: "/audits"}];

    return <>
        {auditStore.loading && <Loading />}
        <div className="centered-section">
            
            <BreadCrumbs breadCrumbs={breadCrumbs} />

            <ClientAuditUploadFormSetup
                    file=""
                    upload={auditStore.uploadClients}
            />

            {!(auditStore.clients && auditStore.clients.length) ? <NoResultsFound />
                : <div className="m-3">
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Health care Id</th>
                            </tr>
                        </thead>
                        <tbody>
                            {auditStore.clients.map(c => (<tr key={`${c.firstName}${c.lastName}${c.accountNumber}`}>
                                <td>{c.firstName}</td>
                                <td>{c.lastName}</td>
                                <td>{c.accountNumber}</td>
                            </tr>))}
                        </tbody>
                    </Table>
                </div>}
        </div>
    </>
}

export default observer(ClientAuditUploadOuterForm);