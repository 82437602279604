import React, { useState } from "react";

import { FormikProps, Form as FForm } from 'formik';
import Button from "react-bootstrap/esm/Button";

import PackageDetails from "../PackageDetails";
import PackageRates from "../PackageRates";
import PackageVisits from "../PackageVisits";
import PackageValidation from "../PackageValidation";

import { ICarePackage } from "../../../../../app/models/care-packages/ICarePackage";
import { IDetailsParams } from "../../../../../app/models/shared/IDetailsParams";

import DeleteModal from "../../../../modals/DeleteModal";
import ClientStore from "../../../../../app/stores/clientStore";
import CarePackageStore from "../../../../../app/stores/carePackageStore";

interface IProps {
    clientStore: ClientStore;
    carePackageStore: CarePackageStore;
}

const PackageInnerForm : React.FC<IDetailsParams & IProps & FormikProps<ICarePackage>> = (props) => {

    const { id, isSubmitting, clientStore, carePackageStore, errors } = props;
 
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setShowDeleteModal(true);
    }
    
    const handleCancelDelete= () => {
        setShowDeleteModal(false);
    }

    const handleConfirmedDelete = async () => {
      
        if (!id) return;
        
        setShowDeleteModal(false);

        await carePackageStore.deleteCarePackage(id, clientStore.client!.id!, clientStore);
    }

    return (
         <FForm className="form-small">
             
            <PackageDetails errors={errors} />
            
            <PackageRates />

            <PackageVisits />

            <PackageValidation />
 
            <Button disabled={isSubmitting} variant="primary" type="submit" className="m-3">
                {isSubmitting ? "Saving..." : "Save"}
            </Button>
            
            <Button onClick={handleDelete} disabled={isSubmitting} variant="outline-secondary" className="m-3">
                {isSubmitting ? "Deleting..." : "Delete"}
            </Button>

            <DeleteModal
              showDeleteModal={showDeleteModal}
              handleCancelDelete={handleCancelDelete}
              handleConfirmedDelete={handleConfirmedDelete}></DeleteModal>
      </FForm>
 
    );
  };


export default PackageInnerForm;