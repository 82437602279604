import { withFormik } from "formik";
import * as Yup from "yup";

import { ICarer } from "../../../app/models/carers/ICarer";

import { formatters } from "../../../app/common/helpers/errorMessages";
import history from "../../../app/common/history";
import { sendNotificationSuccess } from "../../../app/common/helpers/notificationsHelper";

import CarerFormInner from "./CarerFormInner";


interface IFormProps extends ICarer {
    update(carer: ICarer, callback: () => void): Promise<void>;
    create(carer: ICarer, callback: () => void): Promise<void>;
}

const CarerFormSetup = withFormik<IFormProps, ICarer>({

    // Transform outer props into form values

    mapPropsToValues: props => {
      return {
 
         id: props?.id || null,
 
         firstName: props?.firstName || "",
 
         lastName: props?.lastName || "",

         carerNumber: props?.carerNumber || "",

      } as ICarer; 
    },

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
        firstName: Yup.string()
            .required(formatters.required("First name"))
            .max(128, formatters.tooLong("First name")),
        lastName: Yup.string()
            .required(formatters.required("Last name"))
            .max(128, formatters.tooLong("Last name")),
        carerNumber: Yup.string()
            .required(formatters.required("Carer number"))
            .max(16, formatters.tooLong("Carer number")),
    }),
 
    handleSubmit: (updatedCarer, { setSubmitting, props }) => {
        
         try {
             const apiCall = updatedCarer.id
                 ? props.update
                 : props.create;

            const callback = () => {
                sendNotificationSuccess("Changes saved");
                history.push("/carers");
            }
 
             apiCall(updatedCarer, callback);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(CarerFormInner);


export default CarerFormSetup;