import { makeAutoObservable, runInAction } from "mobx";
import { AxiosError } from "axios";

import { IUser } from "../models/users/IUser";
import { IUserLoginFormValues } from "../models/users/IUserLoginFormValues";

import configNames from "../common/constants/configNames";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import history from "../common/history";
import { store } from "./store";
import { IUserRegistrationFormValues } from "../models/users/IUserRegistrationFormValues";

export default class UserStore {
    user: IUser | null = null;
    refreshTokenTimeout: any;
    errorMessage: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn () {
        return !!this.user;
    }
    
    setErrorMessage = (errorMessage: string | null) => {
        runInAction(() => {
            this.errorMessage = errorMessage;
        });
    }

    clearErrorMessage = () => {
        this.setErrorMessage(null);
    }

    login = async (credentials: IUserLoginFormValues) => {
        this.clearErrorMessage();
        
        try {
            const user = await ApiHelpers.Account.login(credentials);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);
            history.push("/");
        } catch(error) {
            const e =  error as AxiosError;
            this.setErrorMessage(e.response?.data.message);
            
            errorHandler.handleError(error);
        }
    }

    logout = () => {
        this.stopRefreshTokenTimer();
        store.commonStore.setToken(null);
        window.localStorage.removeItem(configNames.TOKEN_NAME);
        this.user = null;
        history.push("/");
    }

    getUser = async () => {
        try {
            const user = await ApiHelpers.Account.currentUser();
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);
        } catch(error) {
            errorHandler.handleError(error);
        }
    }

    register = async (newUser: IUserRegistrationFormValues) => {
        this.clearErrorMessage();

        try {
            const user = await ApiHelpers.Account.register(newUser);
            store.commonStore.setToken(user.token);
            runInAction(() => this.user = user);
            this.startRefreshTokenTimer(user);
            history.push("/");
        } catch(error) {
            const e =  error as AxiosError;
            this.setErrorMessage(e.response?.data.message);
        }
    }

    refreshToken = async () => {
            this.stopRefreshTokenTimer();

            try {
                const user = await ApiHelpers.Account.refreshToken();
                runInAction(() => {
                    this.user= user;
                })
                store.commonStore.setToken(user.token);
                this.startRefreshTokenTimer(user);
            } catch (error) {
                errorHandler.handleError(error);
            }
    }

    private startRefreshTokenTimer(user: IUser) {
        const jwtToken = JSON.parse(atob(user.token.split(".")[1]));
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (30 * 1000);
        this.refreshTokenTimeout = setTimeout(this.refreshToken, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}