import React from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/esm/Button";

const Audits = () => {
    return <>
        <div className="centered-section">
            <h1>Audits</h1>

            <Button variant="outline-primary" as={Link} to="/audits/clients">Find new/missing clients</Button>
            <br />
            <Button variant="outline-primary" as={Link} to="/audits/latest-invoices">Find last invoice</Button>
        </div>  
    </>  
}

export default Audits;