import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { observer } from "mobx-react-lite";

import { ToastContainer } from 'react-toastify';

import { useStore } from "../stores/store";

import AddressDashboard from '../../features/admin/addresses/dashboard/AddressDashboard';
import AddressDetails from '../../features/admin/addresses/details/AddressDetails';
import AddressOuterForm from '../../features/admin/addresses/forms/AddressOuterForm';
import Audits from '../../features/audits/Audits';
import CarerDashboard from '../../features/carers/dashboard/CarerDashboard';
import CarerFormOuter from '../../features/carers/forms/CarerFormOuter';
import ChcInvoicesDownloadOuterForm from '../../features/invoices/chc/forms/invoices/ChcInvoicesDownloadOuterForm';
import ChcUpdatesDownloadOuterForm from '../../features/invoices/chc/forms/weekly-updates/ChcUpdatesDownloadOuterForm';
import ClientAuditUploadOuterForm from '../../features/audits/forms/ClientAuditUploadOuterForm';
import ClientDashboard from '../../features/clients/dashboard/ClientDashboard';
import ClientDetails from '../../features/clients/details/ClientDetails';
import ClientForm from '../../features/clients/forms/client-form/ClientForm';
import ExportsHampshire from '../../features/invoices/hampshire/ExportsHampshire';
import ExportsChc from '../../features/invoices/chc/ExportsChc';
import ExportsPrivateClients from '../../features/invoices/private-clients/ExportsPrivateClients';
import ExportsSurreyChc from '../../features/invoices/surrey-chc/ExportsSurreyChc';
import HomePage from '../../features/home/HomePage';
import InvoiceDashboard from '../../features/invoices/dashboard/InvoiceDashboard';
import LatestInvoies from '../../features/audits/LatestInvoices';
import Loading from './Loading';
import LoginForm from '../../features/users/LoginForm';
import LogoutForm from '../../features/users/LogoutForm';
import NavBar from '../../features/nav/NavBar';
import NotFound from './NotFound';
import PepFileDownloadOuterForm from '../../features/invoices/hampshire/forms/pep-file-form/PepFileDownloadOuterForm';
import PrivateClientsInvoicesDownloadOuterForm from '../../features/invoices/private-clients/forms/invoices/PrivateClientsInvoicesDownloadOuterForm';
import RegistrationForm from '../../features/users/RegistrationForm';
import RemittanceAdviceDashboard from '../../features/remittances/dashboard/RemittanceAdviceDashboard';
import RemittanceAdviceFormOuter from '../../features/remittances/forms/RemittanceAdviceFormOuter';
import SurreyChcInvoicesDownloadOuterForm from '../../features/invoices/surrey-chc/forms/invoices/SurreyChcInvoicesDownloadOuterForm';
import Visits from '../../features/visits/Visits';
import VisitUploadOuterForm from '../../features/visits/forms/VisitUploadOuterForm';

function App() {
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppHasLoaded());
    } else {
      commonStore.setAppHasLoaded();
    }
  }, [commonStore, userStore]);

  if (!commonStore.appHasLoaded) return <Loading />;

  if (!userStore.isLoggedIn) return(
    <div className="App">
      <div className="app-content not-logged-in">
        <Route exact path='/' component={LoginForm} />
        <Route
          path={'/(.+)'}
          render={() => (
            <>
              <div style={{ marginTop: '7em' }}>
                <Switch>
                  <Route exact path='/create-account' component={RegistrationForm} />
                  <Route path='/login' component={LoginForm} />
                  <Route path='/logout' component={LogoutForm} />
                  <Route component={LoginForm} />
                </Switch>
              </div>
            </>
          )}
        />
        </div>
    </div>)

  return (
    <div className="App">
      <NavBar />

      <div className={`page-content content-ml pt-4`} id="page-content">
        <div>
          <div className="app-content">
            <Route exact path='/' component={HomePage} />
            <Route
              path={'/(.+)'}
              render={() => (
                <>
                  <div style={{ marginTop: '7em' }}>
                    <Switch>
                      <Route exact path='/admin' component={AddressDashboard} />
                      <Route exact path='/admin/addresses' component={AddressDashboard} />
                      <Route path='/admin/addresses/create' component={AddressOuterForm} />
                      <Route path='/admin/addresses/edit/:id' component={AddressOuterForm} />
                      <Route path='/admin/addresses/:id' component={AddressDetails} />
                      <Route exact path='/audits' component={Audits} />
                      <Route path='/audits/clients' component={ClientAuditUploadOuterForm} />
                      <Route path='/audits/latest-invoices' component={LatestInvoies} />
                      <Route exact path='/carers' component={CarerDashboard} />
                      <Route path='/carers/create' component={CarerFormOuter} />
                      <Route path='/carers/edit/:id' component={CarerFormOuter} />
                      <Route path='/carers/:id' component={CarerFormOuter} />
                      <Route exact path='/clients' component={ClientDashboard} />
                      <Route path='/clients/create' component={ClientForm} />
                      <Route path='/clients/edit/:id' component={ClientForm} />
                      <Route path='/clients/:id' component={ClientDetails} />
                      <Route exact path='/exports-chc' component={ExportsChc} />
                      <Route path='/exports-chc/invoices' component={ChcInvoicesDownloadOuterForm} />
                      <Route path='/exports-chc/updates' component={ChcUpdatesDownloadOuterForm} />
                      <Route exact path='/exports-hampshire' component={ExportsHampshire} />
                      <Route exact path='/exports-private-clients' component={ExportsPrivateClients} />
                      <Route path='/exports-private-clients/invoices' component={PrivateClientsInvoicesDownloadOuterForm} />
                      <Route exact path='/exports-surrey-chc' component={ExportsSurreyChc} />
                      <Route exact path='/exports-hampshire/pep-file' component={PepFileDownloadOuterForm} />
                      <Route path='/exports-surrey-chc/invoices' component={SurreyChcInvoicesDownloadOuterForm} />
                      <Route exact path='/invoices' component={InvoiceDashboard} />
                      <Route exact path='/remittances' component={RemittanceAdviceDashboard} />
                      <Route path='/remittances/create' component={RemittanceAdviceFormOuter} />
                      <Route exact path='/visits' component={Visits} />
                      <Route path='/visits/create' component={VisitUploadOuterForm} />
                      <Route path='/create-account' component={RegistrationForm} />
                      <Route path='/login' component={LoginForm} />
                      <Route path='/logout' component={LogoutForm} />
                      <Route path='/not-found' component={NotFound} />
                      {/* <PrivateRoute
                        key={location.key}
                        path={['/createActivity', '/manage/:id']}
                        component={ActivityForm}
                      />
                      <PrivateRoute path='/profile/:username' component={ProfilePage} />
                      <Route path='/user/registerSuccess' component={RegisterSuccess} />
                      <Route path='/user/verifyEmail' component={VerifyEmail} />
                      */}
                      <Redirect from="*" to="/not-found" />
                      <Route component={NotFound} />
                    </Switch>
                  </div>
                </>
              )}
            />

            <ToastContainer  />
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(App);
