import { withFormik } from 'formik';

import { IRemittanceDownloadProps } from '../../../app/models/exports/IRemittanceDownloadProps';

import history from '../../../app/common/history';
import { sendNotificationSuccess } from '../../../app/common/helpers/notificationsHelper';

import RemittanceAdviceFormInner from './RemittanceAdviceFormInner';


interface IFormProps extends IRemittanceDownloadProps {
    download(props: IRemittanceDownloadProps): Promise<void>;
}

const RemittanceAdviceFormSetup = withFormik<IFormProps, IRemittanceDownloadProps>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
        return {

            carerId: props?.carerId  || null,

            remittanceLines: props?.remittanceLines || null,

      } as IRemittanceDownloadProps; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
    
         try {

             props.download(updatedForm)
                .then(() => {
                    history.push("/remittances");
                    sendNotificationSuccess("Changes saved");
                });
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(RemittanceAdviceFormInner);


export default RemittanceAdviceFormSetup;