import VisitStore from "../../../app/stores/visitStore";


export const handleLoadPreviousPage = (
    visitStore: VisitStore,
    clientId: string | undefined,
    setPageNumber: (pageNumber: string) => void,
 ) => () => {
    if (!visitStore.currentPage) return;

    visitStore.setCurrentPage(visitStore.currentPage.currentPageNumber - 1);
    setPageNumber(visitStore.searchParams.pageNumber.toString());
    visitStore.loadVisits(clientId);
}



export const handleLoadNextPage = (
    visitStore: VisitStore,
    clientId: string | undefined,
    setPageNumber: (pageNumber: string) => void,
) => () => {
    if (!visitStore.currentPage) return;

    visitStore.setCurrentPage(visitStore.currentPage.currentPageNumber + 1);
    setPageNumber(visitStore.searchParams.pageNumber.toString());
    visitStore.loadVisits(clientId);
}



const gridFooterHandlers = {
    handleLoadPreviousPage,
    handleLoadNextPage,
};



export default gridFooterHandlers;