export const formatDate = (dateAsString: string) => {

    if (!dateAsString) return dateAsString;
    
    const date = new Date(dateAsString);
    
    return formatDateObject(date);
}

export const formatDateObject = (date: Date) => {

    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

    return formattedDate;
}

export const formatDateObjectEndOfDay = (date: Date) => {

    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T23:59:59`;

    return formattedDate;
}

const datesHelper = {
    formatDate,
    formatDateObject,
    formatDateObjectEndOfDay,
}

export default datesHelper;