import React from "react";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";

import Form from "react-bootstrap/esm/Form";

interface IProps {
  label: string;
  name: string;
  placeholder?: string;
  accept?: string;
}
const FileUploadField = (props: IProps) => {

  const { setFieldValue } = useFormikContext();

  return (
    <Form.Group controlId={props.name} className="mt-3 mb-3"> 
        {props.label && <Form.Label className="details-label">{props.label}</Form.Label>}
        <input
          type="file"
          {...props}
          onChange={(event) =>{
            setFieldValue(props.name, (event.target.files && event.target.files[0]));
          }}
          className="form-control"
            />
    </Form.Group>
  );
};

export default observer(FileUploadField);