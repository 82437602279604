import React from "react";
import { observer } from "mobx-react-lite";


interface IProps {
    heading: string;
}

const SectionHeadingFormGroup: React.FC<IProps> = ({ heading }) => {
    return (<>
        <h6 className="fw-bold mb-3">{heading}</h6>
    </>);
}

export default observer(SectionHeadingFormGroup);