import { withFormik } from "formik";
import * as Yup from "yup";
import PackageInnerForm from "./PackageInnerForm";

import { ICarePackage } from '../../../../../app/models/care-packages/ICarePackage';
import ClientStore from '../../../../../app/stores/clientStore';
import CarePackageStore from '../../../../../app/stores/carePackageStore';
import { formatters } from "../../../../../app/common/helpers/errorMessages";

interface IFormProps extends ICarePackage {
    update(carePackage: ICarePackage, clientStore: ClientStore): Promise<void>; 
    clientStore: ClientStore;
    carePackageStore: CarePackageStore;   
}

const PackageFormSetup = withFormik<IFormProps, ICarePackage>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
        
        if (!props) throw Error("Required values must be provided");

        return {
    
            id: props.id || null,
            clientId: props.clientId || null,

            chargeAs: props.chargeAs,
            usesBankHolidayRates: props.usesBankHolidayRates,
            overallDescription: props.overallDescription,
            expectedWeeklyCost: props.expectedWeeklyCost,
            numberOfCommissionedHours: props.numberOfCommissionedHours,
        
            isActive: props.isActive,
            startTime: props.startTime,
            startVisit: props.startVisit,
            endTime: props.endTime,
            endVisit: props.endVisit,

            configuredCommissionedHours: props.configuredCommissionedHours,
            configuredTotalCost: props.configuredTotalCost,
        
            includesLiveInCare: props.includesLiveInCare,
            liveInCarerWeeklyCost: props.liveInCarerWeeklyCost,
        
            followsAlternatingWeekPattern: props.followsAlternatingWeekPattern,
            alternatingWeekNumber: props.alternatingWeekNumber,
    
            morningDuration: props.morningDuration,
            lunchtimeDuration: props.lunchtimeDuration,
            teaDuration: props.teaDuration,
            bedDuration: props.bedDuration,
        
            weekday30minutes: props.weekday30minutes,
            weekday45minutes: props.weekday45minutes,
            weekday1hour: props.weekday1hour,
            weekday90minutes: props.weekday90minutes,

            weekend30minutes: props.weekend30minutes,
            weekend45minutes: props.weekend45minutes,
            weekend1hour: props.weekend1hour,
            weekend90minutes: props.weekend90minutes,

            bankHoliday30minutes: props.bankHoliday30minutes,
            bankHoliday45minutes: props.bankHoliday45minutes,
            bankHoliday1hour: props.bankHoliday1hour,
            bankHoliday90minutes: props.bankHoliday90minutes,
            
            sitInWeekday: props.sitInWeekday,
            nightWeekday: props.nightWeekday,
            
            sitInWeekend: props.sitInWeekend,
            nightWeekend: props.nightWeekend,
            
            sitInBankHoliday: props.sitInBankHoliday,
            nightBankHoliday: props.nightBankHoliday,

            mondayMorningCarers: props.mondayMorningCarers,
            mondayLunchCarers: props.mondayLunchCarers,
            mondayTeaCarers: props.mondayTeaCarers,
            mondayBedCarers: props.mondayBedCarers,
            
            tuesdayMorningCarers: props.tuesdayMorningCarers,
            tuesdayLunchCarers: props.tuesdayLunchCarers,
            tuesdayTeaCarers: props.tuesdayTeaCarers,
            tuesdayBedCarers: props.tuesdayBedCarers,
            
            wednesdayMorningCarers: props.wednesdayMorningCarers,
            wednesdayLunchCarers: props.wednesdayLunchCarers,
            wednesdayTeaCarers: props.wednesdayTeaCarers,
            wednesdayBedCarers: props.wednesdayBedCarers,
            
            thursdayMorningCarers: props.thursdayMorningCarers,
            thursdayLunchCarers: props.thursdayLunchCarers,
            thursdayTeaCarers: props.thursdayTeaCarers,
            thursdayBedCarers: props.thursdayBedCarers,
            
            fridayMorningCarers: props.fridayMorningCarers,
            fridayLunchCarers: props.fridayLunchCarers,
            fridayTeaCarers: props.fridayTeaCarers,
            fridayBedCarers: props.fridayBedCarers,
            
            saturdayMorningCarers: props.saturdayMorningCarers,
            saturdayLunchCarers: props.saturdayLunchCarers,
            saturdayTeaCarers: props.saturdayTeaCarers,
            saturdayBedCarers: props.saturdayBedCarers,
            
            sundayMorningCarers: props.sundayMorningCarers,
            sundayLunchCarers: props.sundayLunchCarers,
            sundayTeaCarers: props.sundayTeaCarers,
            sundayBedCarers: props.sundayBedCarers,
            
            mondayNightDuration: props.mondayNightDuration,
            mondaySitInDuration: props.mondaySitInDuration,
            
            tuesdayNightDuration: props.tuesdayNightDuration,
            tuesdaySitInDuration: props.tuesdaySitInDuration,
            
            wednesdayNightDuration: props.wednesdayNightDuration,
            wednesdaySitInDuration: props.wednesdaySitInDuration,
            
            thursdayNightDuration: props.thursdayNightDuration,
            thursdaySitInDuration: props.thursdaySitInDuration,
            
            fridayNightDuration: props.fridayNightDuration,
            fridaySitInDuration: props.fridaySitInDuration,
            
            saturdayNightDuration: props.saturdayNightDuration,
            saturdaySitInDuration: props.saturdaySitInDuration,
            
            sundayNightDuration: props.sundayNightDuration,
            sundaySitInDuration: props.sundaySitInDuration,
        } as ICarePackage; 
        },

        enableReinitialize: true,

        validationSchema: Yup.object().shape({
            chargeAs: Yup.string().required(formatters.required("Charge as")),
            overallDescription: Yup.string()
                .required(formatters.required("Description"))
                .max(256, formatters.tooLong("Description")),
            expectedWeeklyCost: Yup.number().min(0, formatters.nonNegative("Cost")),
            numberOfCommissionedHours: Yup.number().min(0, formatters.nonNegative("Commissioned hours")),
        }),
    
        handleSubmit: (updatedCarePackage, { setSubmitting, props }) => {
            
            try {    
                
                props.update(updatedCarePackage, props.clientStore);
                
            } catch (error) {
                
            } finally {
                setSubmitting(false);    
            }
        },
    
    })(PackageInnerForm);


export default PackageFormSetup;