import React from "react";
import { observer } from "mobx-react-lite";

import { Field } from 'formik';

import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'

import SectionHeadingFormGroup from "../../../../app/layout/common-components/shared/SectionHeadingFormGroup";

const PackageRates: React.FC = () => {

    return <>
        <h5 className="mt-5">Rates - short calls</h5>

        <div className="extra-wide border ms-3 me-3 mt-3 mb-5 p-5">

            <SectionHeadingFormGroup heading="Short calls - duration in minutes" />

            <Table bordered hover className="mb-5">
                <thead>
                    <tr>
                    <th>Morning duration</th>
                    <th>Lunch duration</th>
                    <th>Tea duration</th>
                    <th>Bed duration</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Form.Group controlId="morningDuration"> 
                                <Field name="morningDuration" component="select" className="form-control">
                                    <option value="">Select a group</option>
                                    <option value="1">30 minutes</option>
                                    <option value="2">45 minutes</option>
                                    <option value="3">1 hour</option>
                                    <option value="5">1 hour 30 minutes</option>
                                </Field>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="lunchtimeDuration"> 
                                <Field name="lunchtimeDuration" component="select" className="form-control">
                                    <option value="">Select a group</option>
                                    <option value="1">30 minutes</option>
                                    <option value="2">45 minutes</option>
                                    <option value="3">1 hour</option>
                                    <option value="5">1 hour 30 minutes</option>
                                </Field>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="teaDuration"> 
                                <Field name="teaDuration" component="select" className="form-control">
                                    <option value="">Select a group</option>
                                    <option value="1">30 minutes</option>
                                    <option value="2">45 minutes</option>
                                    <option value="3">1 hour</option>
                                    <option value="5">1 hour 30 minutes</option>
                                </Field>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="bedDuration">
                                <Field name="bedDuration" component="select" className="form-control">
                                    <option value="">Select a group</option>
                                    <option value="1">30 minutes</option>
                                    <option value="2">45 minutes</option>
                                    <option value="3">1 hour</option>
                                    <option value="5">1 hour 30 minutes</option>
                                </Field>
                            </Form.Group>
                        </td>
                    </tr>
                </tbody>
            </Table>

            <SectionHeadingFormGroup heading="Short calls - rates" />

            <Table bordered hover>
                <thead>
                    <tr>
                    <th>Type</th>
                    <th>30 min</th>
                    <th>45 min</th>
                    <th>1 hour</th>
                    <th>1 hour 30 min</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Weekday
                        </td>
                        <td>
                            <Form.Group controlId="weekday30minutes"> 
                                <Field type="number" name="weekday30minutes" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="weekday45minutes"> 
                                <Field type="number" name="weekday45minutes" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="weekday1hour"> 
                                <Field type="number" name="weekday1hour" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="weekday90minutes"> 
                                <Field type="number" name="weekday90minutes" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Weekend
                        </td>
                        <td>
                            <Form.Group controlId="weekend30minutes"> 
                                <Field type="number" name="weekend30minutes" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="weekend45minutes"> 
                                <Field type="number" name="weekend45minutes" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="weekend1hour"> 
                                <Field type="number" name="weekend1hour" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="weekend90minutes"> 
                                <Field type="number" name="weekend90minutes" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Bank holiday
                        </td>
                        <td>
                            <Form.Group controlId="bankHoliday30minutes"> 
                                <Field type="number" name="bankHoliday30minutes" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="bankHoliday45minutes"> 
                                <Field type="number" name="bankHoliday45minutes" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="bankHoliday1hour"> 
                                <Field type="number" name="bankHoliday1hour" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="bankHoliday90minutes"> 
                                <Field type="number" name="bankHoliday90minutes" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>

        <h5>Rates - hourly visits</h5>
        
        <div className="extra-wide border ms-3 me-3 mt-3 mb-5 p-5">
            <Table bordered hover>
                <thead>
                    <tr>
                    <th>Type</th>
                    <th>Night</th>
                    <th>Sit-in</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Weekday
                        </td>
                        <td>
                            <Form.Group controlId="nightWeekday"> 
                                <Field type="number" name="nightWeekday" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="sitInWeekday"> 
                                <Field type="number" name="sitInWeekday" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Weekend
                        </td>
                        <td>
                            <Form.Group controlId="nightWeekend"> 
                                <Field type="number" name="nightWeekend" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="sitInWeekend"> 
                                <Field type="number" name="sitInWeekend" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Bank holiday
                        </td>
                        <td>
                            <Form.Group controlId="nightBankHoliday"> 
                                <Field type="number" name="nightBankHoliday" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="sitInBankHoliday"> 
                                <Field type="number" name="sitInBankHoliday" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    </>
}

export default observer(PackageRates);