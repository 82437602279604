import { createContext, useContext } from "react";

import AdminAddressStore from "./adminAddressStore";
import AuditStore from "./auditStore";
import CarerStore from "./carerStore";
import CommonStore from "./commonStore";
import ClientStore from "./clientStore";
import UserStore from "./userStore";
import CarePackageStore from "./carePackageStore";
import VisitStore from "./visitStore";
import ExportStore from "./exportStore";
import InvoiceStore from "./invoiceStore";
import ClientBillingAddressStore from "./clientBillingAddressStore";
import RemittanceStore from "./remittanceStore";

interface Store {
    adminAddressStore: AdminAddressStore;
    auditStore: AuditStore,
    carePackageStore: CarePackageStore;
    carerStore: CarerStore;
    clientStore: ClientStore;
    clientBillingAddressStore: ClientBillingAddressStore;
    commonStore: CommonStore;
    exportStore: ExportStore;
    invoiceStore: InvoiceStore;
    remittanceStore: RemittanceStore;
    userStore: UserStore;
    visitStore: VisitStore;
}

export const store: Store = {
    adminAddressStore: new AdminAddressStore(),
    auditStore: new AuditStore(),
    carePackageStore: new CarePackageStore(),
    carerStore: new CarerStore(),
    clientStore: new ClientStore(),
    clientBillingAddressStore: new ClientBillingAddressStore(),
    commonStore: new CommonStore(),
    exportStore: new ExportStore(),
    invoiceStore: new InvoiceStore(),
    remittanceStore: new RemittanceStore(),
    userStore: new UserStore(),
    visitStore: new VisitStore(),
}

export const StoreContext = createContext(store);

export const useStore = () => {
    return useContext(StoreContext);   
}