import React from "react";
import { observer } from "mobx-react-lite";

import { ErrorMessage } from "formik";


interface IProps {
    fieldName: string;
    errorMessage: string | undefined;
}

const FieldValidationError: React.FC<IProps> = ({ fieldName, errorMessage }) => {
    return (<>
        <ErrorMessage
            name={fieldName}
            render={() => <div className="validation-error-label">{errorMessage || "Invalid"}</div>}
        />
    </>);
}

export default observer(FieldValidationError);