import React from "react";
import { observer } from "mobx-react-lite";

import Loading from "../../../../../app/layout/Loading";
import BreadCrumbs from "../../../../nav/BreadCrumbs";

import { useStore } from "../../../../../app/stores/store";
import PrivateClientsInvoicesDownloadFormSetup from "./PrivateClientsInvoicesDownloadFormSetup";

const PrivateClientsInvoicesDownloadOuterForm = () => {

    const { exportStore } = useStore();

    const breadCrumbs = [{ name:"Private clients spreadsheets", path: "/exports-private-clients"}];

    return <>
        {exportStore.loading && <Loading />}
        <div className="centered-section">
            
            <BreadCrumbs breadCrumbs={breadCrumbs} />
 
            <h1>Download private clients invoices</h1>

            <PrivateClientsInvoicesDownloadFormSetup
                    invoiceStore={undefined}
                    clientId={undefined}
                    weekCommencingDate={undefined}
                    numberOfWeeks={1}
                    download={exportStore.downloadPrivateClientInvoices}
            />
        </div>
    </>
}

export default observer(PrivateClientsInvoicesDownloadOuterForm);