import React from "react";
import { observer } from "mobx-react-lite";

import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";

interface IProps {
    title: string;
    mainMessage: string;
    confirmMessage?: string;
    cancelMessage?: string;
    showModal: boolean;
    handleCancel: () => void;
    handleConfirmed: () => void;
}

const GenericModal: React.FC<IProps> = ({ title, mainMessage, confirmMessage, cancelMessage, showModal, handleCancel, handleConfirmed }) => {

    return <>
        <Modal show={showModal} onHide={handleCancel}>
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{mainMessage}</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>
                {cancelMessage || "No, cancel"}
            </Button>
            <Button variant="primary" onClick={handleConfirmed}>
                {confirmMessage || "Yes, proceed"}
            </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default observer(GenericModal);