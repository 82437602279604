import React from "react";

import { FormikProps, Form as FForm } from 'formik';
import Button from "react-bootstrap/esm/Button";

import { IRemittanceDeleteForm } from "../../../../app/models/remittances/IRemittanceDeleteForm";

import TextInputField from "../../../../app/layout/form-fields/TextInputField";


const DeleteRemittanceInnerForm : React.FC<FormikProps<IRemittanceDeleteForm>> = (props) => {

    const { isSubmitting } = props;
 
    return (
         <FForm className="form-small">

            <TextInputField name="reason" label="Please provide a reason" />

             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Saving..." : "Save"}
             </Button>
      </FForm> 
    );
  };


export default DeleteRemittanceInnerForm;