import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { observer } from "mobx-react-lite";

import Form from "react-bootstrap/esm/Form";

interface IProps {
    label: string;
    placeholder?: string;
}

const DatePickerField = (props: Partial<IProps & ReactDatePickerProps>) => {

  const { setFieldValue } = useFormikContext();
  const [field] = useField(props.name!);

  return (
    <Form.Group controlId={props.name} className="mb-3"> 
        {props.label && <Form.Label className="details-label">{props.label}</Form.Label>}
        <DatePicker
          {...props}
          dateFormat="dd/MM/yyyy"
          locale="en-GB"
          selected={(field.value && new Date(field.value)) || null }
          onChange={val => setFieldValue(field.name, val)}
          placeholderText={props.placeholder}
          className="form-control"
        />
    </Form.Group> 
  );
};

export default observer(DatePickerField);