import { makeAutoObservable, runInAction } from "mobx";

import { IFileExport } from "../models/exports/IFileExport";
import { IPepFileDownloadProps } from "../models/exports/IPepFileDownloadProps";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import { IChcInvoicesDownloadProps } from "../models/exports/IChcInvoicesDownloadProps";
import { IChcUpdatesDownloadProps } from "../models/exports/IChcUpdatesDownloadProps";
import { IManualInvoiceDownloadProps } from "../models/exports/IManualInvoiceDownloadProps";
import { IRemittanceDownloadProps } from "../models/exports/IRemittanceDownloadProps";
import { ISurreyChcInvoicesDownloadProps } from "../models/exports/ISurreyChcInvoicesDownloadProps";
import { ISurreyCountyInvoicesDownloadProps } from "../models/exports/ISurreyCountyInvoicesDownloadProps";
import { IPrivateClientInvoicesDownloadProps } from "../models/exports/IPrivateClientInvoicesDownloadProps";
import { IInvoiceDownload } from "../models/invoices/IInvoiceDownload";

export default class ExportStore {

    constructor() {
        makeAutoObservable(this);
    }

    loading = false;
    latestInvoice: IInvoiceDownload | undefined = undefined;
    downloadedFile: IFileExport | undefined = undefined;

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    clearLatestInvoice = () => {
        this.latestInvoice = undefined;
    }

    downloadPepFile = async (props: IPepFileDownloadProps) => {

        this.setLoading(true);
        
        try {
            if (!(props && props.weekCommencingDate)) {
                throw Error("Week commencing date must be provided");
            }

            props.weekCommencingDate = this.formatDate(props.weekCommencingDate);

            const fileName = `${this.formatDate(props.weekCommencingDate)}_Provider Expected Payment PEP File week.xlsx`;
            return await ApiHelpers.Exports.downloadPepFile(props, fileName);

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    downloadChcInvoices = async (props: IChcInvoicesDownloadProps) => {

        this.setLoading(true);
        
        try {
            if (!(props && props.invoicePeriodStartDate)) {
                throw Error("Invoice period must be provided");
            }

            props.invoicePeriodStartDate = this.formatDate(props.invoicePeriodStartDate);

            this.latestInvoice = await ApiHelpers.Exports.downloadChcInvoices(props);

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            props.invoiceStore?.setInvoicesUpdated(true);
            this.setLoading(false);
        }
    }

    downloadChcUpdates = async (props: IChcUpdatesDownloadProps) => {

        this.setLoading(true);
        
        try {
            if (!(props && props.weekCommencingDate)) {
                throw Error("Week commencing date must be provided");
            }

            props.weekCommencingDate = this.formatDate(props.weekCommencingDate);

            const fileName = `${this.formatDate(props.weekCommencingDate)}_dom-spreadsheet.xlsx`;

            return await ApiHelpers.Exports.downloadChcUpdates(props, fileName);

        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    downloadManualInvoices = async (props: IManualInvoiceDownloadProps) => {

        this.setLoading(true);
        
        try {
            if (!(props && props.invoiceLines)) {
                throw Error("At least one invoice line is required");
            }

            props.invoiceLines.forEach(il => {
                il.startDate = !il.startDate ? il.startDate : this.formatDate(il.startDate);
                il.endDate = !il.endDate ? il.endDate : this.formatDate(il.endDate);
            })
            
            this.latestInvoice = await ApiHelpers.Exports.downloadManualInvoices(props);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            props.invoiceStore?.setInvoicesUpdated(true);
            this.setLoading(false);
        }
    }

    downloadGeneralInvoices = async (invoiceId: string, invoiceNumber: string) => {

        this.setLoading(true);
        
        try {
            return await ApiHelpers.Exports.downloadGeneralInvoices(invoiceId, `${invoiceNumber}.pdf`);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.latestInvoice = undefined;
            this.setLoading(false);
        }
    }

    downloadGeneratedRemittances = async (remittanceId: string, fileName: string) => {

        this.setLoading(true);
        
        try {
            return await ApiHelpers.Exports.downloadGeneratedRemittances(remittanceId, `${fileName}.pdf`);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    downloadSurreyChcInvoices = async (props: ISurreyChcInvoicesDownloadProps) => {

        this.setLoading(true);
        
        try {
            if (!(props && props.weekCommencingDate)) {
                throw Error("Week commencing date must be provided");
            }

            props.weekCommencingDate = this.formatDate(props.weekCommencingDate);

            this.latestInvoice = await ApiHelpers.Exports.downloadSurreyChcInvoices(props);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            props.invoiceStore?.setInvoicesUpdated(true);
            this.setLoading(false);
        }
    }

    downloadSurreyCountyInvoices = async (props: ISurreyCountyInvoicesDownloadProps) => {

        this.setLoading(true);
        
        try {
            if (!(props && props.invoicePeriodStartDate)) {
                throw Error("Invoice period start date must be provided");
            }
            
            if (!(props && props.invoicePeriodEndDate)) {
                throw Error("Invoice period end date must be provided");
            }
            
            if (!(props && props.invoiceTotal)) {
                throw Error("Invoice total must be provided");
            }

            props.invoicePeriodStartDate = this.formatDate(props.invoicePeriodStartDate);
            props.invoicePeriodEndDate = this.formatDate(props.invoicePeriodEndDate);

            this.latestInvoice = await ApiHelpers.Exports.downloadSurreyCountyInvoices(props);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            props.invoiceStore?.setInvoicesUpdated(true);
            this.setLoading(false);
        }
    }

    downloadPrivateClientInvoices = async (props: IPrivateClientInvoicesDownloadProps) => {

        this.setLoading(true);
        
        try {
            if (!(props && props.weekCommencingDate)) {
                throw Error("Week commencing date must be provided");
            }

            props.weekCommencingDate = this.formatDate(props.weekCommencingDate);

            this.latestInvoice = await ApiHelpers.Exports.downloadPrivateClientInvoices(props);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            props.invoiceStore?.setInvoicesUpdated(true);
            this.setLoading(false);
        }
    }

    downloadRemittances = async (props: IRemittanceDownloadProps) => {

        this.setLoading(true);
        
        try {
            if (!(props && props.remittanceLines)) {
                throw Error("At least one remittance line is required");
            }

            props.remittanceLines.forEach(rl => {
                rl.startDate = !rl.startDate ? rl.startDate : this.formatDate(rl.startDate);
                rl.endDate = !rl.endDate ? rl.endDate : this.formatDate(rl.endDate);
            })
            
            this.latestInvoice = await ApiHelpers.Exports.downloadRemittances(props);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    formatDate = (dateAsString: string) => {
        const date = new Date(dateAsString);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

        return formattedDate;
    }
}