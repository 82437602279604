import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import { ErrorMessage, Formik, Form as FForm, Field } from "formik";
import * as Yup from "yup";

import Alert from "react-bootstrap/esm/Alert";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";

import { useStore } from "../../app/stores/store";

import FieldValidationError from "../../app/layout/form-fields/errors/FieldValidationError";
import FormSubmissionError from "../../app/layout/form-fields/errors/FormSubmissionError";

import logo from "../../assets/logo.png";

const RegistrationForm = () => {

    const [didLoad, setDidLoad] = useState(false);
    
    const { userStore } = useStore();

    const location = useLocation();

    useEffect(() => {
        if (!didLoad) {
            userStore.clearErrorMessage();
            setDidLoad(true);
        }
    }, [
        userStore, 
        userStore.errorMessage,
        location,
        didLoad,
        setDidLoad,
    ]);

    return (
        <Formik
            initialValues={{ firstName: "", lastName: "", userName: "", email: "", password: "", error: null }}
            onSubmit={(values, { setErrors }) => userStore.register(values).catch(error => {
                setErrors({error: error.message})
            })}
            validationSchema={Yup.object({
                firstName: Yup.string().required("First name is a required field"),
                lastName: Yup.string().required("Last name is a required field"),
                email: Yup.string().required("Email is a required field").email("Email must be a valid email"),
                password: Yup.string().required("Password is a required field")
            })}
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
                <FForm onSubmit={handleSubmit} autoComplete="off">
                    <img src={logo} alt="Vine Social Care Agency" className="mb-5" />
                    <h1 className="mt-5">Register</h1>
                    <p>Create your account for Vine Social Care Invoices</p>
                    <Form.Group controlId="FirstName" className="mb-3"> 
                        <Form.Label className="details-label">First name</Form.Label> 
                        <Field type="text" name="firstName" className="form-control" />
                        <FieldValidationError fieldName="firstName" errorMessage={errors.firstName} />
                    </Form.Group>
                    <Form.Group controlId="LastName" className="mb-3"> 
                        <Form.Label className="details-label">Last name</Form.Label> 
                        <Field type="text" name="lastName" className="form-control" />
                        <FieldValidationError fieldName="lastName" errorMessage={errors.lastName} />
                    </Form.Group>
                    <Form.Group controlId="Email" className="mb-3"> 
                        <Form.Label className="details-label">Email</Form.Label> 
                        <Field type="email" name="email" className="form-control" />
                        <FieldValidationError fieldName="email" errorMessage={errors.email} />
                    </Form.Group> 
                    <Form.Group controlId="Password" className="mb-3"> 
                        <Form.Label className="details-label">Password</Form.Label> 
                        <Field type="password" name="password" className="form-control" />
                        <FieldValidationError fieldName="password" errorMessage={errors.password} />
                    </Form.Group> 
                    
                    <ErrorMessage
                        name="error"
                        render={() => <Alert variant="danger">{errors.error}</Alert>}
                    />
                    
                    <Button disabled={!isValid || !dirty || isSubmitting} variant="primary" type="submit">
                        {isSubmitting ? "Creating account..." : "Create account"}
                    </Button>

                    <Button disabled={isSubmitting} variant="link" as={Link} to="/login" >
                        Log in instead
                    </Button>

                    {userStore.errorMessage && <FormSubmissionError errorMessage={userStore.errorMessage} />}
                </FForm>
            )}
        </Formik>
    )
}

export default observer(RegistrationForm);