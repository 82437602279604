import React from "react";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";

import { ISelectOption } from "../../../../../../app/models/shared/ISelectOption";

import clientGroups from "../../../../../../app/common/constants/enums/clientGroups";

import CustomButton from "../../../../../../app/layout/common-components/buttons/CustomButton";
import DatePickerField from "../../../../../../app/layout/form-fields/DatePickerField";
import SelectField from "../../../../../../app/layout/form-fields/SelectField";


interface IProps {
    options: ISelectOption[];
    handleSelection: (chargeAs: number | undefined, periodStartDate: string | undefined) => void;
}

interface IFormProps {
    chargeAs: number | undefined;
    periodStartDate: string | undefined;
    error: any;
}

const ChargeAsFormBasic : React.FC<IProps> = ({ options, handleSelection }) => {
    return (
        <Formik
            initialValues={{ chargeAs: undefined, periodStartDate: undefined, error: null } as IFormProps}
            onSubmit={({ chargeAs, periodStartDate }) => handleSelection(chargeAs, periodStartDate) }
        >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty, validateForm, values }) => 
                (<Form onSubmit={handleSubmit} className="form-small">
                    <SelectField name="chargeAs" label="Charge as" options={options} required={false} />

                    {values.chargeAs && Number(values.chargeAs) === clientGroups.CHC.value
                        && <div className="mt-3">
                            <DatePickerField name="periodStartDate"  label="Invoice period start date" placeholder="dd/mm/yyyy" />
                        </div>}
                    
                    <br />

                    <CustomButton
                        text={isSubmitting ? "Please wait..." : "Next"}
                        type="submit"
                        disabled={!isValid || !dirty || isSubmitting}
                        classes="submit-button primary" 
                    />
                        
                </Form>)}
        </Formik>);
}

export default observer(ChargeAsFormBasic);