import React from "react";
import { observer } from "mobx-react-lite";

import ChcUpdatesDownloadFormSetup from "./ChcUpdatesDownloadFormSetup";
import Loading from "../../../../../app/layout/Loading";
import BreadCrumbs from "../../../../nav/BreadCrumbs";

import { useStore } from "../../../../../app/stores/store";

const ChcUpdatesDownloadOuterForm = () => {

    const { exportStore } = useStore();

    const breadCrumbs = [{ name:"CHC spreadsheets", path: "/exports-chc"}];

    return <>
        {exportStore.loading && <Loading />}
        <div className="centered-section">
            
            <BreadCrumbs breadCrumbs={breadCrumbs} />
 
            <h1>Download CHC updates</h1>

            <ChcUpdatesDownloadFormSetup
                    clientId={undefined}
                    clientIds={undefined}
                    weekCommencingDate={undefined}
                    weekNumber={undefined}
                    download={exportStore.downloadChcUpdates}
            />
        </div>
    </>
}

export default observer(ChcUpdatesDownloadOuterForm);