import { observer } from "mobx-react-lite";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

import { columnSorting } from "../../../common/constants/enums/columnSorting";


interface IProps {
    text: string;
    includeSorting?: boolean;
    sortDescending?: boolean;
    sortingApplied?: boolean;
    setColumnSorting?: (sortingOption: number) => void;
}

const ColumnHeading = ({ 
    text, 
    includeSorting = false, 
    sortDescending = false,
    sortingApplied = false,
    setColumnSorting,
 }: IProps) => {

    const sortingStyle = (active: boolean) => {
        return  { color: active ? "var(--primary-color)" : "var(--primary-color-very-light)" };
    };

    return (<div className="column-heading d-flex justify-content-between">
        <div className="text">{text}</div>
        {includeSorting && setColumnSorting 
            && <div className="d-flex flex-column">
                <FaCaretUp 
                    className="clickable" 
                    style={sortingStyle(!sortDescending && sortingApplied)} 
                    onClick={() => setColumnSorting(columnSorting.ASCENDING)}
                />
                
                <FaCaretDown 
                    className="clickable" 
                    style={sortingStyle(sortDescending && sortingApplied)}
                    onClick={() => setColumnSorting(columnSorting.DESCENDING)}
                /> 
            </div>}
    </div>);
}

export default observer(ColumnHeading);