import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loading = () => {
    return <div className="loading-screen">
        <div>
            <Spinner animation="grow" variant="primary" />
        </div>        
    </div>
}

export default Loading;