import React from "react";

import { FormikProps, Form as FForm, Field } from 'formik';
import Button from "react-bootstrap/esm/Button";

import DatePickerField from "../../../../../app/layout/form-fields/DatePickerField";

import { ISurreyChcInvoicesDownloadProps } from "../../../../../app/models/exports/ISurreyChcInvoicesDownloadProps";

const SurreyChcInvoicesDownloadInnerForm : React.FC<FormikProps<ISurreyChcInvoicesDownloadProps>> = (props) => {

    const { isSubmitting } = props;
 
    return (
         <FForm className="form-small">

            <DatePickerField name="weekCommencingDate" label="Week commencing date" placeholder="dd/mm/yyyy - should be a Monday" />

            <Field name="clientId" type="hidden" className="form-control" />

             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Downloading..." : "Download"}
             </Button>
      </FForm>
 
    );
  };


export default SurreyChcInvoicesDownloadInnerForm;