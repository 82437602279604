import React from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/esm/Button";

const ExportsHampshire = () => {
    return <div className="centered-section"> 
        <h1>Hampshire spreadsheets</h1>

        <Button variant="outline-primary" as={Link} to="/exports-hampshire/pep-file">Download PEP file</Button>
    </div>
}

export default ExportsHampshire;