import axios, { AxiosResponse } from 'axios';

import { IAddress } from '../models/addresses/IAddress';
import { IClient } from '../models/clients/IClient';
import { IClientBasic } from '../models/clients/IClientBasic';
import { IClientBillingAddress } from '../models/clients/IClientBillingAddress';
import { ICarePackage } from '../models/care-packages/ICarePackage';
import { ICarer } from '../models/carers/ICarer';
import { IChcInvoicesDownloadProps } from '../models/exports/IChcInvoicesDownloadProps';
import { IChcUpdatesDownloadProps } from '../models/exports/IChcUpdatesDownloadProps';
import { IBasicDetails } from '../models/shared/IBasicDetails';
import { IInvoiceBasic } from '../models/invoices/IInvoiceBasic';
import { IInvoiceDeleteForm } from '../models/invoices/IInvoiceDeleteForm';
import { IInvoiceStatusForm } from '../models/invoices/IInvoiceStatusForm';
import { IInvoiceLatestBasic } from '../models/invoices/IInvoiceLatestBasic';
import { IManualInvoiceDownloadProps } from '../models/exports/IManualInvoiceDownloadProps';
import { IPepFileDownloadProps } from '../models/exports/IPepFileDownloadProps';
import { IPrivateClientInvoicesDownloadProps } from '../models/exports/IPrivateClientInvoicesDownloadProps';
import { IRemittanceBasic } from '../models/remittances/IRemittanceBasic';
import { IRemittanceDownloadProps } from '../models/exports/IRemittanceDownloadProps';
import { IResult } from '../models/responses/IResult';
import { ISurreyChcInvoicesDownloadProps } from '../models/exports/ISurreyChcInvoicesDownloadProps';
import { ISurreyCountyInvoicesDownloadProps } from '../models/exports/ISurreyCountyInvoicesDownloadProps';
import { IUser } from '../models/users/IUser';
import { IUserLoginFormValues } from '../models/users/IUserLoginFormValues';
import { IUserRegistrationFormValues } from '../models/users/IUserRegistrationFormValues';
import { IVisitBasic } from '../models/visits/IVisitBasic';

import { store } from "../stores/store";


axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
  const token = store.commonStore.token;
  
  if (token) {
    config.headers.Authorization = `bearer ${token}`;
  }

  return config;
});

const responseBody = (response: AxiosResponse) => response.data;
const responseBodyValue = <T>(response: AxiosResponse): IResult<T> => {
  return { 
    value: response.data.value,
    pageDetails: response.data.pageDetails,
  }
};

const ACCOUNT_ENDPOINT = "account";
const ADMIN_ADDRESSES_ENDPOINT = "admin/addresses";
const AUDITS_CLIENTS_ENDPOINT = "audits/clients/upload";
const AUDITS_INVOICES_LATEST_ENDPOINT = "audits/invoices/invoiceslatest";
const CARERS_ENDPOINT = "carers";
const CLIENT_LIST_ENDPOINT = "clientlist";
const CLIENTS_ENDPOINT = "clients";
const CLIENTS_BILLING_ADDRESS_ENDPOINT = "clientbillingaddress";
const INVOICES_ENDPOINT = "invoices";
const PACKAGES_ENDPOINT = "packages";
const REMITTANCES_ENDPOINT = "remittances";
const VISITS_ENDPOINT = "visits";
const VISITS_UPLOAD_ENDPOINT = "visits/upload";
const DOWNLOAD_PEP_FILE_ENDPOINT = "exports/pepfile";
const DOWNLOAD_CHC_INVOICES_ENDPOINT = "exports/chc/downloadinvoices";
const DOWNLOAD_CHC_UPDATES_ENDPOINT = "exports/chc/downloadupdates";
const DOWNLOAD_GENERAL_INVOICES_ENDPOINT = "exports/general/downloadinvoices";
const DOWNLOAD_GENERATED_REMITTANCES_ENDPOINT = "exports/general/downloadremittances";
const DOWNLOAD_MANUAL_INVOICES_ENDPOINT = "exports/manual/downloadinvoices";
const DOWNLOAD_PRIVATE_CLIENT_INVOICES_ENDPOINT = "exports/privateclients/downloadinvoices";
const DOWNLOAD_REMITTANCES_ENDPOINT = "exports/downloadremittances";
const DOWNLOAD_SURREY_CHC_INVOICES_ENDPOINT = "exports/surreychc/downloadinvoices";
const DOWNLOAD_SURREY_COUNTY_INVOICES_ENDPOINT = "exports/surreycounty/downloadinvoices";

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append('File', file);
    return axios
      .post(url, formData, {
        headers: { 'Content-type': 'multipart/form-data' }
      })
      .then(responseBody);
  }
};

const requestsFiles = {
  post: <T>(url: string, body: {}, fileName: string) => axios.post<T>(url, body, { responseType: "blob"}).then(response => {
    
        let url = window.URL.createObjectURL(response.data);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    })
}

const Account = {
  currentUser: () => requests.get<IUser>(`/${ACCOUNT_ENDPOINT}`),
  login: (user: IUserLoginFormValues) => requests.post<IUser>(`/${ACCOUNT_ENDPOINT}/login`, user),
  register: (user: IUserRegistrationFormValues) => requests.post<IUser>(`/${ACCOUNT_ENDPOINT}/register`, user),
  refreshToken: () => requests.post<IUser>(`/${ACCOUNT_ENDPOINT}/refreshToken`, {}),
};

const AdminAddresses = {
    list: (): Promise<IAddress[]> =>
      axios.get(`/${ADMIN_ADDRESSES_ENDPOINT}/list`).then(responseBody),
    details: (id: string) => requests.get(`/${ADMIN_ADDRESSES_ENDPOINT}/${id}`),
    create: (address: IAddress) => requests.post(`/${ADMIN_ADDRESSES_ENDPOINT}`, address),
    update: (address: IAddress) =>
      requests.put(`/${ADMIN_ADDRESSES_ENDPOINT}/${address.id}`, address),
    delete: (id: string) => requests.del(`/${ADMIN_ADDRESSES_ENDPOINT}/${id}`),
};

const AuditsClients = {
    upload: (file: FormData): Promise<IClientBasic[]> => requests.post(`/${AUDITS_CLIENTS_ENDPOINT}`, file),
};

const Carers = {
    list: (): Promise<ICarer[]> =>
      axios.get(`/${CARERS_ENDPOINT}/list`).then(responseBody),
    details: (id: string) => requests.get(`/${CARERS_ENDPOINT}/${id}`),
    create: (carer: ICarer) => requests.post(`/${CARERS_ENDPOINT}`, carer),
    update: (carer: ICarer) =>
      requests.put(`/${CARERS_ENDPOINT}/${carer.id}`, carer),
    delete: (id: string) => requests.del(`/${CARERS_ENDPOINT}/${id}`),
};

const Clients = {
    list: (clientGroup?: string): Promise<IClientBasic[]> =>
      axios.post(`/${CLIENT_LIST_ENDPOINT}`, { clientGroup }).then(responseBody),
    details: (id: string) => requests.get(`/${CLIENTS_ENDPOINT}/${id}`),
    create: (client: IClient) => requests.post(`/${CLIENTS_ENDPOINT}`, client),
    update: (client: IClient) =>
      requests.put(`/${CLIENTS_ENDPOINT}/${client.id}`, client),
    delete: (id: string) => requests.del(`/${CLIENTS_ENDPOINT}/${id}`),
};

const ClientBillingAddresses = {
    details: (id: string) => requests.get(`/${CLIENTS_BILLING_ADDRESS_ENDPOINT}/${id}`),
    update: (billingAddress: IClientBillingAddress) => requests.put(`/${CLIENTS_BILLING_ADDRESS_ENDPOINT}/${billingAddress.id}`, billingAddress),
};

const CarePackages = {
    list: (clientId: string): Promise<IBasicDetails[]> =>
      axios.get(`/${PACKAGES_ENDPOINT}/${clientId}`).then(responseBody),
    details: (id: string) => requests.get(`/${PACKAGES_ENDPOINT}/${id}`),
    create: (clientId: string) => requests.post(`/${PACKAGES_ENDPOINT}/${clientId}`, {}),
    update: (carePackage: ICarePackage) =>
      requests.put(`/${PACKAGES_ENDPOINT}/${carePackage.id}`, carePackage),
    delete: (id: string) => requests.del(`/${PACKAGES_ENDPOINT}/${id}`)
};

const Invoices = {
    list: (clientId: string | undefined): Promise<IInvoiceBasic[]> =>
      axios.post(`/${INVOICES_ENDPOINT}`, { clientId }).then(responseBody),
    update: (invoice: IInvoiceStatusForm) =>
      requests.put(`/${INVOICES_ENDPOINT}/${invoice.id}`, invoice),
    delete: (invoice: IInvoiceDeleteForm) => requests.put(`/${INVOICES_ENDPOINT}/delete/${invoice.id}`, invoice),
};

const InvoicesLatest = {
    list: (): Promise<IInvoiceLatestBasic[]> =>
      axios.get(AUDITS_INVOICES_LATEST_ENDPOINT).then(responseBody),
};

const Remittances = {
    list: (): Promise<IRemittanceBasic[]> =>
      axios.get(`/${REMITTANCES_ENDPOINT}/list`).then(responseBody),
    delete: (id: string, reason: string | undefined) => requests.post(`/${REMITTANCES_ENDPOINT}/delete/${id}`, {reason }),
};

const Visits = {
    list: (clientId: string | undefined, params: URLSearchParams ): Promise<IResult<IVisitBasic[]>> =>
      axios.post(`/${VISITS_ENDPOINT}?${params.toString()}`, { clientId })
        .then(response => responseBodyValue<IVisitBasic[]>(response)),
    upload: (file: FormData) => requests.post(`/${VISITS_UPLOAD_ENDPOINT}`, file),
};

const Exports = {
    downloadPepFile: (props: IPepFileDownloadProps, fileName: string) => requestsFiles.post(`/${DOWNLOAD_PEP_FILE_ENDPOINT}`, props, fileName),
    downloadChcInvoices: (props: IChcInvoicesDownloadProps) => requests.post(`/${DOWNLOAD_CHC_INVOICES_ENDPOINT}`, props),
    downloadChcUpdates: (props: IChcUpdatesDownloadProps, fileName: string) => requestsFiles.post(`/${DOWNLOAD_CHC_UPDATES_ENDPOINT}`, props, fileName),
    downloadGeneralInvoices: (invoiceId: string, fileName: string) => requestsFiles.post(`/${DOWNLOAD_GENERAL_INVOICES_ENDPOINT}/${invoiceId}`, {}, fileName),
    downloadGeneratedRemittances: (remittanceId: string, fileName: string) => requestsFiles.post(`/${DOWNLOAD_GENERATED_REMITTANCES_ENDPOINT}/${remittanceId}`, {}, fileName),
    downloadManualInvoices: (props: IManualInvoiceDownloadProps) => requests.post(`/${DOWNLOAD_MANUAL_INVOICES_ENDPOINT}`, props),
    downloadRemittances: (props: IRemittanceDownloadProps) => requests.post(`/${DOWNLOAD_REMITTANCES_ENDPOINT}`, props),
    downloadSurreyChcInvoices: (props: ISurreyChcInvoicesDownloadProps) => requests.post(`/${DOWNLOAD_SURREY_CHC_INVOICES_ENDPOINT}`, props),
    downloadSurreyCountyInvoices: (props: ISurreyCountyInvoicesDownloadProps) => requests.post(`/${DOWNLOAD_SURREY_COUNTY_INVOICES_ENDPOINT}`, props),
    downloadPrivateClientInvoices: (props: IPrivateClientInvoicesDownloadProps) => requests.post(`/${DOWNLOAD_PRIVATE_CLIENT_INVOICES_ENDPOINT}`, props),
};

const ApiHelpers = {
  Account,
  AdminAddresses,
  AuditsClients,
  Carers,
  Clients,
  ClientBillingAddresses,
  CarePackages,
  Exports,
  Invoices,
  InvoicesLatest,
  Remittances,
  Visits,
};


export default ApiHelpers;