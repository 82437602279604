import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";

import Button from "react-bootstrap/esm/Button";
import Tab from "react-bootstrap/esm/Tab";
import Tabs from "react-bootstrap/esm/Tabs";

import BreadCrumbs from "../../nav/BreadCrumbs";
import Loading from "../../../app/layout/Loading";
import DeleteModal from "../../modals/DeleteModal";
import GenericModal from "../../modals/GenericModal";

import MainDetails from "./MainDetails";
import Packages from "./care-packages/Packages";
import InvoiceList from "../../invoices/general/InvoiceList";
import VisitList from "../../visits/VisitList";

import ChcInvoicesDownloadFormSetup from "../../invoices/chc/forms/invoices/ChcInvoicesDownloadFormSetup";
import ClientBillingAddressOuterForm from "./billing-address/forms/details/ClientBillingAddressOuterForm";
import ManualInvoicesDownloadOuterForm from "../../invoices/manual/forms/invoices/ManualInvoicesDownloadOuterForm";
import PrivateClientsInvoicesDownloadFormSetup from "../../invoices/private-clients/forms/invoices/PrivateClientsInvoicesDownloadFormSetup";
import SectionHeading from "../../../app/layout/common-components/shared/SectionHeading";
import SurreyChcInvoicesDownloadFormSetup from "../../invoices/surrey-chc/forms/invoices/SurreyChcInvoicesDownloadFormSetup";
import SurreyCountyInvoicesDownloadFormSetup from "../../invoices/surrey-county/forms/invoices/SurreyCountyInvoicesDownloadFormSetup";

import { IDetailsParams } from "../../../app/models/shared/IDetailsParams";

import { useStore } from "../../../app/stores/store";
import clientGroups from "../../../app/common/constants/enums/clientGroups";

const ClientDetails: React.FC<RouteComponentProps<IDetailsParams>> = ({
    match,
    history
  }) => {

    const { clientStore, carePackageStore, exportStore } = useStore();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setShowDeleteModal(true);
    }

    const handleCancelDelete= () => {
        setShowDeleteModal(false);
    }

    const handleConfirmedDelete = async () => {
        await clientStore.deleteClient(match.params.id!)
        
        history.push("/clients");
    }

    useEffect(() => {
        clientStore.loadClient(match.params.id!);

        carePackageStore.loadCarePackages(match.params.id!);
    }, [clientStore, match.params.id, carePackageStore, carePackageStore.carePackages.length]);

    return <>
        {clientStore.loading && <Loading />}
        <div className="centered-section">
            <BreadCrumbs 
                breadCrumbs={[{ name:"Clients", path: "/clients"}]}
            />
            
            <h1>{clientStore.client?.firstName} {clientStore.client?.lastName}</h1>

            <Button variant="outline-primary" as={Link} to={`/clients/edit/${match.params.id}`}>Edit</Button>
            <Button onClick={handleDelete} variant="outline-secondary">Delete</Button>

            <Tabs defaultActiveKey="details" id="client-details" className="mt-5">
                <Tab eventKey="details" title="Details">
                    <MainDetails
                        id={clientStore.client?.id}
                        title={clientStore.client?.title}
                        firstName={clientStore.client?.firstName}
                        lastName={clientStore.client?.lastName}
                        clientGroup={clientStore.client?.clientGroup}
                        numberOfWeeksPerInvoice={clientStore.client?.numberOfWeeksPerInvoice}
                        malinkoId={clientStore.client?.malinkoId}
                        swiftCode={clientStore.client?.swiftCode}
                        temporaryAccountNumber={clientStore.client?.temporaryAccountNumber}
                        westHampshireChcAccountNumber={clientStore.client?.westHampshireChcAccountNumber}
                        surreyChcAccountNumber={clientStore.client?.surreyChcAccountNumber}
                        surreyCountyAccountNumber={clientStore.client?.surreyCountyAccountNumber}
                        frimleyIcbAccountNumber={clientStore.client?.frimleyIcbAccountNumber}
                        ealingAccountNumber={clientStore.client?.ealingAccountNumber}
                        portsmouthCityCouncilAccountNumber={clientStore.client?.portsmouthCityCouncilAccountNumber}
                        privateAccountNumber={clientStore.client?.privateAccountNumber}
                        hasBeenArchived={clientStore.client?.hasBeenArchived}
                        additionalInformation={clientStore.client?.additionalInformation}
                        commissionedWeeks={clientStore.client?.commissionedWeeks}
                        hasAnAdditionalPrivatePackage={clientStore.client?.hasAnAdditionalPrivatePackage}
                        isSurreyCountyD2AClient={clientStore.client?.isSurreyCountyD2AClient}
                        shouldBeShownOnPepFile={clientStore.client?.shouldBeShownOnPepFile} 
                    />
                </Tab>
                <Tab eventKey="billing-address" title="Billing address">
                    <ClientBillingAddressOuterForm clientId={match.params.id!} />
                </Tab>
                <Tab eventKey="packages" title="Packages">
                    <Packages clientId={clientStore.client?.id} commissionedWeeks={clientStore.client?.commissionedWeeks} carePackageStore={carePackageStore} clientStore={clientStore} />
                </Tab>
                <Tab eventKey="invoices" title="Invoices">
                    <SectionHeading heading="Invoices" />
                    
                    <InvoiceList clientId={match.params.id} />
                </Tab>
                <Tab eventKey="new-invoices" title="New invoice">
                    <GenericModal
                        title="Download invoice"
                        mainMessage={exportStore.latestInvoice?.fileName || ""}
                        confirmMessage="Download"
                        cancelMessage="Cancel"
                        showModal={!!exportStore.latestInvoice}
                        handleConfirmed={() => exportStore.downloadGeneralInvoices(exportStore.latestInvoice!.id, exportStore.latestInvoice!.fileName)}
                        handleCancel={() => exportStore.clearLatestInvoice()}
                    />
                        
                    {clientStore.client?.hasAnAdditionalPrivatePackage && <div className="mt-5">
                            <p><strong className="text-danger">WARNING - </strong>This client pays privately for some calls. Use manual invoices section to generate invoices</p>
                        </div>}
                    {clientStore.client?.clientGroup === clientGroups.PRIVATE.name && <div className="mt-5">
                        <SectionHeading heading="Download invoices" />

                        <PrivateClientsInvoicesDownloadFormSetup
                            invoiceStore={undefined}
                            clientId={match.params.id}
                            weekCommencingDate={undefined}
                            numberOfWeeks={1}
                            download={exportStore.downloadPrivateClientInvoices} />
                    </div>}
                    {clientStore.client?.clientGroup === clientGroups.SURREY_COUNTY.name && <div className="mt-5">
                        <SectionHeading heading="Generate new invoice" />

                        <SurreyCountyInvoicesDownloadFormSetup
                            invoiceStore={undefined}
                            clientId={match.params.id}
                            invoicePeriodStartDate={undefined}
                            invoicePeriodEndDate={undefined}
                            invoiceTotal={undefined}
                            download={exportStore.downloadSurreyCountyInvoices} />
                    </div>}
                    {clientStore.client?.clientGroup === clientGroups.CHC.name && <div className="mt-5">
                        <SectionHeading heading="Generate new invoice" />

                        <ChcInvoicesDownloadFormSetup
                            invoiceStore={undefined}
                            clientId={match.params.id}
                            invoicePeriodStartDate={undefined}
                            download={exportStore.downloadChcInvoices} />
                    </div>}
                    {clientStore.client?.clientGroup === clientGroups.SURREY_CHC.name && <div className="mt-5">
                        
                        <SectionHeading heading="Generate new invoice" />

                        <SurreyChcInvoicesDownloadFormSetup
                            invoiceStore={undefined}
                            clientId={match.params.id}
                            weekCommencingDate={undefined}
                            download={exportStore.downloadSurreyChcInvoices} />
                    </div>}
                    <div className="mt-5">
                        <SectionHeading heading="Manual invoice" />

                        <ManualInvoicesDownloadOuterForm clientId={match.params.id} />
                    </div>
                </Tab>
                <Tab eventKey="visits" title="Visits">
                    <SectionHeading heading="Visits" />

                    <VisitList clientId={match.params.id} />
                </Tab>
            </Tabs>

            <DeleteModal
              showDeleteModal={showDeleteModal}
              handleCancelDelete={handleCancelDelete}
              handleConfirmedDelete={handleConfirmedDelete}></DeleteModal>

        </div>
    </>
}

export default observer(ClientDetails);