import React from "react";
import { observer } from "mobx-react-lite";

import InvoiceList from "../general/InvoiceList";

const InvoiceDashboard = () => {

    return <>
        <div className="centered-section">

            <h1>Invoices</h1>

            <InvoiceList clientId={undefined} />
            
        </div>  
    </>  
}

export default observer(InvoiceDashboard);