import React from "react";
import { Link } from "react-router-dom";

import logo from "../../app/layout/images/logo.png";

import NavBarAccount from "./NavBarAccount";
import { FaAngleRight } from "react-icons/fa";

const NavBar = () => {
    return <>
        <nav id="navbar" className={`navbar content-ml py-1 position-sticky navbar-expand-md bg-white navbar-light shadow`}>
            <NavBarAccount />

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav"></ul>
            </div>
        </nav>

        <div id="sidebar" className={`sidebar d-flex flex-column active bg-white shadow text-dark`}>
        <div className="d-flex align-items-center mb-3 px-3 py-2">
            
            <Link to="/" className="navbar-brand p-0"><img src={logo} alt="logo" /></Link>
            <Link to="/" className="p-0">Vine Social Care Invoices</Link>
        </div>

        <ul className="list-unstyled nav-list overflow-auto flex-grow-1">
          <li>
            <Link to={`/clients`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">Clients</span>
            </Link>
            <Link to={`/invoices`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">Invoices</span>
            </Link>
            <Link to={`/visits`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">Visits</span>
            </Link>
            <Link to={`/audits`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">Audits</span>
            </Link>
            <Link to={`/exports-chc`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">CHC spreadsheets</span>
            </Link>
            <Link to={`/exports-hampshire`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">Hampshire spreadsheets</span>
            </Link>
            <Link to={`/carers`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">Carers</span>
            </Link>
            <Link to={`/remittances`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">Remittances</span>
            </Link>
            <Link to={`/admin`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">Admin</span>
            </Link>
            {/* <Link to={`/exports-private-clients`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">Private clients spreadsheets</span>
            </Link> */}
            {/* <Link to={`/exports-surrey-chc`}>
                <span className="nav-icon"><FaAngleRight /></span>
                <span className="nav-text">Surrey CHC spreadsheets</span>
            </Link> */}
          </li>
        </ul>
      </div>
    </>
}

export default NavBar;