import { AxiosError } from "axios";
import { sendNotificationError } from "./helpers/notificationsHelper";
import history from "./history";

const handleError = (error: AxiosError) => {
    const { statusCode, message } = error.response?.data;

    switch(statusCode) {
        case 400: 
            sendNotificationError(message);
            break;
        case 401:
            history.push("/login");
            break;
        case 403:
            sendNotificationError("This operation is not allowed.");
            break;
        case 404:
            history.push("/not-found");
            break;
        case 500:
            sendNotificationError(message);
            break;
    }
}

const errorHandler = {
    handleError
}

export default errorHandler;