import { observer } from "mobx-react-lite";
import MainSectionHeading from "../../../app/layout/common-components/headings/MainSectionHeading";
import RemittanceAdviceList from "./RemittanceAdviceList";


const RemittanceAdviceDashboard = () => {

    return <div className="centered-section">

        <MainSectionHeading
            heading="Remittances"
            actionText="New"
            actionLink="/remittances/create"
        />

        <RemittanceAdviceList />
        
    </div>;
}

export default observer(RemittanceAdviceDashboard);