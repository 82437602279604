import { withFormik } from 'formik';

import { IInvoiceDeleteForm } from '../../../../app/models/invoices/IInvoiceDeleteForm';

import DeleteInvoiceInnerForm from './DeleteInvoiceInnerForm';

interface IFormProps extends IInvoiceDeleteForm {
    closeModal(): void;
    delete(props: IInvoiceDeleteForm): Promise<void>;
}

const DeleteInvoiceFormSetup = withFormik<IFormProps, IInvoiceDeleteForm>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
      return {

        id: props?.id || null,

        clientId: props?.clientId || null,

        reason: props?.reason || null,
        
      } as IInvoiceDeleteForm; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {

             props.delete(updatedForm).then(props.closeModal);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(DeleteInvoiceInnerForm);


export default DeleteInvoiceFormSetup;