import React from "react";
import { observer } from "mobx-react-lite";
import colorFormat from "../../../common/helpers/colorFormat";


interface IProps {
    clientGroup: string;
    suffix?: string;
}

const ClientGroupLabel: React.FC<IProps> = ({ clientGroup, suffix }) => {
    return (<>
        <span
            className={colorFormat.getClientGroupColor(clientGroup)}
        >
            {clientGroup} {suffix}
        </span>
    </>);
}

export default observer(ClientGroupLabel);