import { ISelectOption } from "../../../../models/shared/ISelectOption";


export const enumToOptionList = <T extends {}>(customEnum: T): ISelectOption[] =>
    Object.entries(customEnum).map((e: any) => ({ text: e[1].label, value: e[1].value }));

export const areEqual = (enumValue1?: number, enumValue2?: number) =>
    enumValue1?.toString() === enumValue2?.toString();

export const enumText = <T extends {}>(customEnum: T, value: string | undefined) =>
    enumToOptionList(customEnum).filter(e => e.value === value)[0]?.text; 

export const enumObjectToOptionList = (enumObject: any): ISelectOption[] => 
    Object.entries(enumObject).map((e: any) => ({ text: e[0], value: e[1] }));
    
const enumHelper = {
    areEqual,
    enumObjectToOptionList,
    enumToOptionList,
    enumText,
};


export default enumHelper;