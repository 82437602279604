import React from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/esm/Button";

const ExportsPrivateClients = () => {
    return <>
        <h1>Private clients spreadsheets</h1>

        <Button variant="outline-primary" as={Link} to="/exports-private-clients/invoices" className="m-3">Download invoices</Button>
    </>
}

export default ExportsPrivateClients;