import React from "react";

import { FormikProps, Form as FForm, Field } from 'formik';

import Button from "react-bootstrap/esm/Button";
import TextInputField from "../../../../../app/layout/form-fields/TextInputField";

import DatePickerField from "../../../../../app/layout/form-fields/DatePickerField";

import { IPrivateClientInvoicesDownloadProps } from "../../../../../app/models/exports/IPrivateClientInvoicesDownloadProps";

const PrivateClientsInvoicesDownloadInnerForm : React.FC<FormikProps<IPrivateClientInvoicesDownloadProps>> = (props) => {

    const { isSubmitting } = props;
 
    return (
         <FForm className="form-small">

            <DatePickerField name="weekCommencingDate" label="Week commencing date" placeholder="dd/mm/yyyy - should be a Monday" />

            <TextInputField name="numberOfWeeks" label="Number of weeks in invoice period" placeholder="Must be between 1 and 4, inclusive" />

            <Field name="clientId" type="hidden" className="form-control" />

             <Button disabled={isSubmitting} variant="primary" type="submit">
                 {isSubmitting ? "Downloading..." : "Download"}
             </Button>
      </FForm> 
    );
  };


export default PrivateClientsInvoicesDownloadInnerForm;