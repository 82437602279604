import React from "react";
import { observer } from "mobx-react-lite";

import Loading from "../../../../../app/layout/Loading";
import BreadCrumbs from "../../../../nav/BreadCrumbs";

import { useStore } from "../../../../../app/stores/store";
import SurreyChcInvoicesDownloadFormSetup from "./SurreyChcInvoicesDownloadFormSetup";

const SurreyChcInvoicesDownloadOuterForm = () => {

    const { exportStore } = useStore();

    const breadCrumbs = [{ name:"Surrey CHC spreadsheets", path: "/exports-surrey-chc"}];

    return <>
        {exportStore.loading && <Loading />}
        <div className="centered-section">
            
            <BreadCrumbs breadCrumbs={breadCrumbs} />
 
            <h1>Download Surrey CHC invoices</h1>

            <SurreyChcInvoicesDownloadFormSetup
                    invoiceStore={undefined}
                    clientId={undefined}
                    weekCommencingDate={undefined}
                    download={exportStore.downloadSurreyChcInvoices}
            />
        </div>
    </>
}

export default observer(SurreyChcInvoicesDownloadOuterForm);