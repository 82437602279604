interface IDefaultColors {
    UNSPECIFIED: string,
    BLUE: string,
    GREY: string,
    GREEN: string,
    RED: string,
    YELLOW: string,
    AQUAMARINE: string,
}

const defaultColors: IDefaultColors = {
    UNSPECIFIED: "",
    BLUE: "text-primary",
    GREY: "text-secondary",
    GREEN: "text-success",
    RED: "text-danger",
    YELLOW: "text-warning",
    AQUAMARINE: "text-info",
}

export default defaultColors;

