import React from "react";
import { observer } from "mobx-react-lite";

import { Field } from "formik";

import Form from "react-bootstrap/esm/Form";

interface IProps {
    label: string;
    name: string;
}

const CheckboxField = (props: IProps) => {

  return (
    <Form.Group controlId={props.name} className="mb-3"> 
        <Field 
            {...props}
            type="checkbox" />
            <Form.Label className="m-2 details-label">{props.label}</Form.Label> 
    </Form.Group> 
  );
};

export default observer(CheckboxField);