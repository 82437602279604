import { makeAutoObservable, runInAction } from "mobx";
import { AxiosError } from "axios";

import { IClient } from "../models/clients/IClient";
import { IClientBasic } from "../models/clients/IClientBasic";

import ApiHelpers from "../api/ApiHelpers";
import errorHandler from "../common/errorHandler";
import notificationMessages from "../common/constants/enums/notificationMessages";
import { sendNotificationSuccess } from "../common/helpers/notificationsHelper";

interface IClientFilters {
    clientName: string | null;
    accountNumber: string | null;
    clientGroup: string | null;
    includeArchived: boolean;
}

export default class ClientStore {

    constructor() {
        makeAutoObservable(this);
    }

    defaultFilters = () : IClientFilters => {
        return { 
            clientName: null, 
            accountNumber: null,
            clientGroup: null, 
            includeArchived: false 
        };
    }

    clientRegistry : Map<string, IClientBasic> = new Map();
    client : IClient | null = null;
    filters: IClientFilters = this.defaultFilters();
    loading = true;
    get clients () {
        return Array.from(this.clientRegistry.values())
            .filter(c => (!this.filters.clientName
                            || (c.firstName.toLowerCase().includes(this.filters.clientName.toLowerCase()) 
                                || c.lastName.toLowerCase().includes(this.filters.clientName.toLowerCase())))
                        && (!this.filters.accountNumber || 
                                c.accountNumbers.filter(
                                    n => n.accountNumber.includes(this.filters.accountNumber!)).length)
                        && (!this.filters.clientGroup || c.clientGroup === this.filters.clientGroup)
                        && (this.filters.includeArchived || !c.hasBeenArchived));
    }

    setLoading = (loading: boolean) => {
        runInAction(() => {
            this.loading = loading;
        })
    }

    setClientNameFilter = (clientName: string | null) => {
        runInAction(() => {
            this.filters.clientName = clientName;
        });
    }

    setClientAccountNumberFilter = (accountNumber: string | null) => {
        runInAction(() => {
            this.filters.accountNumber = accountNumber;
        });
    }

    setClientGroupFilter = (clientGroup: string | null) => {
        runInAction(() => {
            this.filters.clientGroup = clientGroup === "All" ? null : clientGroup;
        });
    }

    setClientArchivedFilter = (includeArchived: boolean) => {
        runInAction(() => {
            this.filters.includeArchived = includeArchived;
        });
    }

    clearClientFilters = () => {
        runInAction(() => {
            this.filters = this.defaultFilters();
        });
    }

    loadClients = async (clientGroup?: string) => {
        this.setLoading(true);

        try {
            this.clearClients();
            
            const clients = await ApiHelpers.Clients.list(clientGroup);
            
            this.clearSelectedClient();

            runInAction(() => {
                clients.forEach(m => this.clientRegistry.set(m.id, m));
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    loadClient = async (id: string) => {
        this.setLoading(true);

        try {
            const client = await ApiHelpers.Clients.details(id);

            runInAction(() => {
                this.client = client;
            });
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    createClient = async (
        client: IClient,
        callback: () => void
    ) => {
        this.setLoading(true);

        try {
            await ApiHelpers.Clients.create(client);
            await this.loadClients();
            callback();
        } catch (error) {
            errorHandler.handleError(error as AxiosError);
        } finally {
            this.setLoading(false);
        }
    }

    updateClient = async (
        client: IClient,
        callback: () => void
    ) => {
        this.setLoading(true);

        try {
            await ApiHelpers.Clients.update(client);
            await this.loadClients();
            callback();
        } catch (error) {
            errorHandler.handleError(error as AxiosError);
        } finally {
            this.setLoading(false);
        }
    }

    deleteClient = async (id: string) => {
        this.setLoading(true);

        try {
            await ApiHelpers.Clients.delete(id);
            await this.loadClients();
            this.clientRegistry.delete(id);
            sendNotificationSuccess(notificationMessages.CHANGES_SAVED);
        } catch (error) {
            errorHandler.handleError(error);
        } finally {
            this.setLoading(false);
        }
    }

    clearSelectedClient = () => {
        this.client = null;
    }

    clearClients = () => {
        this.clientRegistry.clear();
        this.clearClientFilters();
    }
}