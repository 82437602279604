import React from "react";
import { useField } from "formik";
import { observer } from "mobx-react-lite";

import { Field } from "formik";
import Form from "react-bootstrap/esm/Form";

import FieldValidationError from "./errors/FieldValidationError";

interface IProps {
    label?: string;
    name: string;
    type?: "text" | "number" | "email" | "password";
    placeholder?: string;
    required?: boolean;
    noSpace?: boolean;
    errors?: string | undefined;
}
const TextInputField = (props: IProps) => {

  const [field] = useField(props.name);

  return (

    <Form.Group controlId={props.name} className={((!props.noSpace) && "mb-3") || ""}> 
        {props.label && <Form.Label className="details-label">{props.label}</Form.Label>}
        <Field 
            {...field}
            {...props}
            type={props.type || "text"}
            className="form-control" />
        {props.errors && <FieldValidationError fieldName={props.name} errorMessage={props.errors} />}
    </Form.Group> 
  );
};

export default observer(TextInputField);