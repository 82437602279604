import { withFormik } from 'formik';
import history from '../../../../app/common/history';

import { IRemittanceDeleteForm } from '../../../../app/models/remittances/IRemittanceDeleteForm';

import DeleteRemittanceInnerForm from './DeleteRemittanceInnerForm';


interface IFormProps extends IRemittanceDeleteForm {
    closeModal(): void;
    delete(id: string, reason: string | undefined): Promise<void>;
}

const DeleteRemittanceFormSetup = withFormik<IFormProps, IRemittanceDeleteForm>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
      return {

        id: props?.id || null,

        reason: props?.reason || null,
        
      } as IRemittanceDeleteForm; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {

             props.delete(updatedForm.id, updatedForm.reason).then(() => {
                props.closeModal();
                history.push("/remittances");
             });
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(DeleteRemittanceInnerForm);


export default DeleteRemittanceFormSetup;