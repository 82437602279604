import React from "react";
import { observer } from "mobx-react-lite";

import { Field } from 'formik';

import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'

const PackageVisits: React.FC = () => {

    return <>
        <h5>Visits - short calls</h5>
        
        <div className="extra-wide border ms-3 me-3 mt-3 mb-5 p-5">
            <Table bordered hover>
                <thead>
                    <tr>
                    <th>Type</th>
                    <th>Morning</th>
                    <th>Lunch</th>
                    <th>Tea</th>
                    <th>Bed</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Monday
                        </td>
                        <td>
                            <Form.Group controlId="mondayMorningCarers"> 
                                <Field type="number" name="mondayMorningCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="mondayLunchCarers"> 
                                <Field type="number" name="mondayLunchCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="mondayTeaCarers"> 
                                <Field type="number" name="mondayTeaCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="mondayBedCarers"> 
                                <Field type="number" name="mondayBedCarers" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Tuesday
                        </td>
                        <td>
                            <Form.Group controlId="tuesdayMorningCarers"> 
                                <Field type="number" name="tuesdayMorningCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="tuesdayLunchCarers"> 
                                <Field type="number" name="tuesdayLunchCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="tuesdayTeaCarers"> 
                                <Field type="number" name="tuesdayTeaCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="tuesdayBedCarers"> 
                                <Field type="number" name="tuesdayBedCarers" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Wednesday
                        </td>
                        <td>
                            <Form.Group controlId="wednesdayMorningCarers"> 
                                <Field type="number" name="wednesdayMorningCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="wednesdayLunchCarers"> 
                                <Field type="number" name="wednesdayLunchCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="wednesdayTeaCarers"> 
                                <Field type="number" name="wednesdayTeaCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="wednesdayBedCarers"> 
                                <Field type="number" name="wednesdayBedCarers" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Thursday
                        </td>
                        <td>
                            <Form.Group controlId="thursdayMorningCarers"> 
                                <Field type="number" name="thursdayMorningCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="thursdayLunchCarers"> 
                                <Field type="number" name="thursdayLunchCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="thursdayTeaCarers"> 
                                <Field type="number" name="thursdayTeaCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="thursdayBedCarers"> 
                                <Field type="number" name="thursdayBedCarers" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Friday
                        </td>
                        <td>
                            <Form.Group controlId="fridayMorningCarers"> 
                                <Field type="number" name="fridayMorningCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="fridayLunchCarers"> 
                                <Field type="number" name="fridayLunchCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="fridayTeaCarers"> 
                                <Field type="number" name="fridayTeaCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="fridayBedCarers"> 
                                <Field type="number" name="fridayBedCarers" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Saturday
                        </td>
                        <td>
                            <Form.Group controlId="saturdayMorningCarers"> 
                                <Field type="number" name="saturdayMorningCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="saturdayLunchCarers"> 
                                <Field type="number" name="saturdayLunchCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="saturdayTeaCarers"> 
                                <Field type="number" name="saturdayTeaCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="saturdayBedCarers"> 
                                <Field type="number" name="saturdayBedCarers" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Sunday
                        </td>
                        <td>
                            <Form.Group controlId="sundayMorningCarers"> 
                                <Field type="number" name="sundayMorningCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="sundayLunchCarers"> 
                                <Field type="number" name="sundayLunchCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="sundayTeaCarers"> 
                                <Field type="number" name="sundayTeaCarers" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="sundayBedCarers"> 
                                <Field type="number" name="sundayBedCarers" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>

        
        <h5>Visits - long visits</h5>
        
        <div className="extra-wide border ms-3 me-3 mt-3 mb-5 p-5">
            <Table bordered hover>
                <thead>
                    <tr>
                    <th>Type</th>
                    <th>Night</th>
                    <th>Sit-in</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Monday
                        </td>
                        <td>
                            <Form.Group controlId="mondayNightDuration"> 
                                <Field type="number" name="mondayNightDuration" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="mondaySitInDuration"> 
                                <Field type="number" name="mondaySitInDuration" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Tuesday
                        </td>
                        <td>
                            <Form.Group controlId="tuesdayNightDuration"> 
                                <Field type="number" name="tuesdayNightDuration" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="tuesdaySitInDuration"> 
                                <Field type="number" name="tuesdaySitInDuration" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Wednesday
                        </td>
                        <td>
                            <Form.Group controlId="wednesdayNightDuration"> 
                                <Field type="number" name="wednesdayNightDuration" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="wednesdaySitInDuration"> 
                                <Field type="number" name="wednesdaySitInDuration" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Thursday
                        </td>
                        <td>
                            <Form.Group controlId="thursdayNightDuration"> 
                                <Field type="number" name="thursdayNightDuration" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="thursdaySitInDuration"> 
                                <Field type="number" name="thursdaySitInDuration" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Friday
                        </td>
                        <td>
                            <Form.Group controlId="fridayNightDuration"> 
                                <Field type="number" name="fridayNightDuration" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="fridaySitInDuration"> 
                                <Field type="number" name="fridaySitInDuration" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Saturday
                        </td>
                        <td>
                            <Form.Group controlId="saturdayNightDuration"> 
                                <Field type="number" name="saturdayNightDuration" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="saturdaySitInDuration"> 
                                <Field type="number" name="saturdaySitInDuration" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Sunday
                        </td>
                        <td>
                            <Form.Group controlId="sundayNightDuration"> 
                                <Field type="number" name="sundayNightDuration" className="form-control" />
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group controlId="sundaySitInDuration"> 
                                <Field type="number" name="sundaySitInDuration" className="form-control" />
                            </Form.Group>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    </>
}

export default observer(PackageVisits);