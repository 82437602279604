import { observer } from "mobx-react-lite";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";


interface IProps {
    pageNumber: string | undefined;
    pageSize: number;
    currentPageSize: number | undefined;
    totalPages: number | undefined;
    totalItems: number | undefined;
    handlePageSizeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    handleLoadPreviousPage: () => void;
    handlePageNumberChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handlePageNumberFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
    handlePageNumberBlur: () => void;
    handlePageNumberEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    handleLoadNextPage: () => void;
}

const GridFooter = ({
    pageNumber,
    pageSize,
    currentPageSize,
    totalPages,
    totalItems,
    handlePageSizeChange,
    handleLoadPreviousPage,
    handlePageNumberChange,
    handlePageNumberFocus,
    handlePageNumberBlur,
    handlePageNumberEnter,
    handleLoadNextPage,
}: IProps) => {
    return (<div className="grid-footer d-flex justify-content-center">
        <select
            className=""
            value={pageSize}
            onChange={handlePageSizeChange}
        >
            <option value={25}>25 per page</option>
            <option value={50}>50 per page</option>
            <option value={100}>100 per page</option>
        </select>

        <div className="page-number d-flex">
            <div 
                className="clickable"
                title="Previous"
                onClick={handleLoadPreviousPage}
            >
                <FaCaretLeft />
            </div>
            
            <div>
                page
                <input 
                    name="pageNumber" 
                    min="1"
                    max={`${totalPages}`}
                    placeholder="Page" 
                    className="ms-1 me-1"
                    onFocus={handlePageNumberFocus}
                    onChange={handlePageNumberChange}
                    onBlur={handlePageNumberBlur}
                    onKeyUp={handlePageNumberEnter}
                    value={pageNumber}
                />
                of {totalPages}
            </div>
            
            <div
                className="clickable" 
                title="Next"
                onClick={handleLoadNextPage}
            >
                <FaCaretRight />
            </div>
        </div>

        <div className="results-summary">
        Showing {currentPageSize} of {totalItems} results
        </div>
    </div>);
}

export default observer(GridFooter);