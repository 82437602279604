import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import { useStore } from "../../app/stores/store";

import Table from "react-bootstrap/esm/Table";

import BreadCrumbs from "../nav/BreadCrumbs";
import Loading from "../../app/layout/Loading";
import NoResultsFound from "../../app/layout/NoResultsFound";
import colorFormat from "../../app/common/helpers/colorFormat";

const LatestInvoies = () => {
    const breadCrumbs = [{ name:"Audits", path: "/audits"}];

    const { auditStore } = useStore();
        
    useEffect(() => {    
        auditStore.loadLatestInvoices();
    }, [auditStore, auditStore.latestInvoices.length]);

    return <> 
        {auditStore.loading && <Loading />}

        <div className="centered-section">
            <BreadCrumbs breadCrumbs={breadCrumbs} />

            <h1>Latest invoies</h1>
            {!(auditStore.latestInvoices.length)
            ? <NoResultsFound />
            : <div className="m-3">
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>Group</th>
                            <th>Client</th>
                            <th>Last invoiced visit</th>
                            <th>No. weeks per invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auditStore.latestInvoices.map(i => (<tr key={i.clientId}>
                            <td className={colorFormat.getClientGroupColor(i.clientGroup)}>{i.clientGroup}</td>
                            <td><Link to={`/clients/${i.clientId}`}>{i.clientName} {i.hasBeenArchived && <span className="text-danger">- Archived</span>}</Link></td>
                            <td>{i.lastInvoicedVisitDateText}</td>
                            <td className={colorFormat.getWeekNumberColor(i.numberOfWeeksPerInvoice)}>{i.numberOfWeeksPerInvoice} week(s)</td>
                        </tr>))}
                    </tbody>
                </Table>
            </div>}
        </div>
    </>
}

export default observer(LatestInvoies);