import { withFormik } from 'formik';

import ManualInvoicesDownloadInnerForm from "./ManualInvoicesDownloadInnerForm";

import { IManualInvoiceDownloadProps } from '../../../../../app/models/exports/IManualInvoiceDownloadProps';

interface IFormProps extends IManualInvoiceDownloadProps {
    download(props: IManualInvoiceDownloadProps): Promise<void>;
}

const ManualInvoicesDownloadFormSetup = withFormik<IFormProps, IManualInvoiceDownloadProps>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
        return {

            clientId: props?.clientId || null,
            chargeAs: props?.chargeAs || null,

            isACreditNote: props?.isACreditNote || false,
            additionalInformation: props?.additionalInformation || null,
            pONumber: props?.pONumber || null,

            invoiceLines: props?.invoiceLines || null,

            setSelectedChargeAs: props?.setSelectedChargeAs,
            
      } as IManualInvoiceDownloadProps; 
    },

    enableReinitialize: true,
 
    handleSubmit: async (updatedForm, { resetForm, setSubmitting, props }) => {
    
         try {

            await props.download(updatedForm);

            resetForm();
             
        } catch (error) {
             
        } finally {
            setSubmitting(false);
        }
    },
 
  })(ManualInvoicesDownloadInnerForm);


export default ManualInvoicesDownloadFormSetup;