import React from "react";
import { observer } from "mobx-react-lite";

import Accordion from "react-bootstrap/esm/Accordion";
import Button from "react-bootstrap/esm/Button";

import PackageForm from "./package-form/PackageOuterForm";

import { ICarePackage } from "../../../../app/models/care-packages/ICarePackage";

import ClientStore from "../../../../app/stores/clientStore";
import CarePackageStore from "../../../../app/stores/carePackageStore";
import SectionHeading from "../../../../app/layout/common-components/shared/SectionHeading";

interface IProps {
    clientId: string | undefined;
    commissionedWeeks: ICarePackage[] | undefined;
    clientStore: ClientStore;
    carePackageStore: CarePackageStore;
}

const Packages: React.FC<IProps> = ({ clientId, commissionedWeeks, carePackageStore, clientStore }) => {
    
    const handleAddCarePackage = async () => {
        
        if (!clientId) return;
        
        await carePackageStore.createCarePackage(clientId, clientStore);
    }

    return <>
        <SectionHeading heading="Packages" />
        
        <Accordion defaultActiveKey={commissionedWeeks?.length ? commissionedWeeks[0].id : ""}>
            {commissionedWeeks && commissionedWeeks.map(carePackage => (
                <PackageForm carePackage={carePackage} clientStore={clientStore} carePackageStore={carePackageStore} />))}    
        </Accordion>
        <Button onClick={handleAddCarePackage} variant="outline-secondary" className="mt-4">Add package</Button>
    </>
}

export default observer(Packages);