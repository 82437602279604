import { withFormik } from 'formik';

import ChcUpdatesDownloadInnerForm from "./ChcUpdatesDownloadInnerForm";

import { IChcUpdatesDownloadProps } from '../../../../../app/models/exports/IChcUpdatesDownloadProps';

interface IFormProps extends IChcUpdatesDownloadProps {
    download(props: IChcUpdatesDownloadProps): Promise<void>;
}

const ChcUpdatesDownloadFormSetup = withFormik<IFormProps, IChcUpdatesDownloadProps>({

    // Transform outer props into form values
 
    mapPropsToValues: props => {
      return {

        clientId: props?.clientId || null,

        clientIds: props?.clientIds || null,
 
         weekCommencingDate: props?.weekCommencingDate || null,

         weekNumber: props?.weekNumber,

      } as IChcUpdatesDownloadProps; 
    },
 
    handleSubmit: (updatedForm, { setSubmitting, props }) => {
        
         try {

             props.download(updatedForm);
             
         } catch (error) {
             
         } finally {
             setSubmitting(false);    
         }
    },
 
  })(ChcUpdatesDownloadInnerForm);


export default ChcUpdatesDownloadFormSetup;